import { DatePipe } from '@angular/common'
import { Directive, Input, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core'
import { BeginEndDate } from '@app/shared/directives/begin-end-date.model'

export interface TurismoEventDateTimeContext {
  label: string
}

@Directive({
  selector: '[turismoEventDateTime]',
})
export class EventDateTimeDirective {
  private context: TurismoEventDateTimeContext | null = null

  @Input()
  set turismoEventDateTime(value: BeginEndDate | undefined) {
    if (!!value) {
      if (!!value.begin && !!value.end) {
        this.viewContainer.clear()

        value.begin = new Date(value.begin)
        value.end = new Date(value.end)

        // Event on more dates
        if (
          value.begin.getDate() !== value.end.getDate() ||
          value.begin.getMonth() !== value.end.getMonth() ||
          value.begin.getFullYear() !== value.end.getFullYear()
        ) {
          this.context = {
            label: `dal <b>${this.datePipe.transform(value.begin, 'd/M')}</b> al <b>${this.datePipe.transform(
              value.end,
              'd/M',
            )}</b>`,
          }
        } else {
          // Event on single date

          if (value.begin.getHours() > 0 && value.end.getHours() > 0) {
            this.context = {
              label: `il <b>${this.datePipe.transform(value.begin, 'd/M')}</b> dalle <b>${this.datePipe.transform(
                value.begin,
                'HH:mm',
              )}</b> alle <b>${this.datePipe.transform(value.end, 'HH:mm')}</b>`,
            }
          } else if (value.begin.getHours() > 0) {
            this.context = {
              label: `il <b>${this.datePipe.transform(value.begin, 'd/M')}</b> dalle <b>${this.datePipe.transform(
                value.begin,
                'HH:mm',
              )}</b>`,
            }
          } else {
            this.context = {
              label: `il giorno <b>${this.datePipe.transform(value.begin, 'd/M')}</b>`,
            }
          }
        }

        this.viewContainer.createEmbeddedView(this.templateRef, this.context)
      }
    }
  }

  constructor(
    private templateRef: TemplateRef<TurismoEventDateTimeContext>,
    private viewContainer: ViewContainerRef,
    private renderer: Renderer2,
    private datePipe: DatePipe,
  ) {}
}
