export interface ContactInfo {
  telephone?: string
  mail?: string
  site?: string
  facebook?: string
}

export interface SocialItem {
  socialType: SocialType
  label: string
  url: string
}

export enum SocialType {
  facebook = 'Facebook',
  instagram = 'Instagram',
  twitter = 'Twitter',
  youtube = 'YouTube',
}
