<button tabindex="0" class="uk-button uk-button-default uk-box-shadow-small uk-text-bold uk-width-small" type="button">
  <img aria-hidden="true" class="lang-icon" [src]="language | languageIcon" [alt]="language" />
  <span class="lang-text">{{ language | language }}</span>
  <span uk-icon="icon: chevron-down"></span>
</button>
<div class="uk-width-small" style="min-width: unset" #languageDropdown uk-dropdown="mode:click">
  <ul class="uk-nav uk-dropdown-nav">
    <li *ngFor="let lang of availableLanguages; index as i">
      <a [tabIndex]="i" (click)="onLanguageSelected(lang)" class="lang_positioning">
        <img aria-hidden="true" class="lang-icon" [src]="lang | languageIcon" [alt]="lang" />
        <span class="lang-text">{{ lang }}</span>
      </a>
    </li>
  </ul>
</div>
