import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper/core'
// install Swiper modules
SwiperCore.use([Pagination, Navigation, Autoplay])

@Component({
  selector: 'slider-carousel',
  templateUrl: './slider-carousel.component.html',
  styleUrls: ['./slider-carousel.component.scss'],
})
export class SliderCarouselComponent implements OnInit {
  @Input() type: string
  @Input() data: []
  @Input() multipleImg: boolean = false

  @ViewChild('youtubeVideo') youtubeFrame: ElementRef

  constructor() {}

  ngOnInit() {}

  youtubeVideo(item: string) {
    const splitUrl: string[] = item.split('/')
    const videoId: string = splitUrl[splitUrl.length - 1]

    return `https://www.youtube.com/embed/${videoId}`
  }
}
