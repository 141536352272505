import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable()
export class GlobalLoadingService {
  private readonly loadingCounter$ = new Subject<number>()

  loading(): void {
    this.loadingCounter$.next(1)
  }
}
