import { createSelector } from '@ngrx/store'
import { LoadingState } from '@shared/store/common.model'
import { AppState } from '../models/app-state.model'
import { AppRemoteConfigStateReducer } from '../reducers'

export const selectAppRemoteConfigState = (state: AppState) => state[AppRemoteConfigStateReducer.featureKey]

export const selectAppRemoteConfigIsLoading = createSelector(selectAppRemoteConfigState, ({ status }) => {
  return status === LoadingState.LOADING
})

export const selectAppRemoteConfigPartnersImageUrl = createSelector(
  selectAppRemoteConfigState,
  ({ partnersImageUrl }) => partnersImageUrl,
)

export const getSelectedLanguage = createSelector(selectAppRemoteConfigState, (state) => state.language)
export const selectBreadcrumbs = createSelector(selectAppRemoteConfigState, (state) => state.breadcrumbs)
