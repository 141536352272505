import { DOCUMENT } from '@angular/common'
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core'
import { AppState } from '@app/core/store/models/app-state.model'
import { FairPlaceFilterCategory } from '@app/features/fair/models/fair-place.models'
import { FilterType } from '@app/shared/models/place-filters.models'
import { FairPlaceFiltersOrderClause } from '@app/shared/web-api/models/place.models'
import { Store } from '@ngrx/store'
import { cloneDeep } from 'lodash'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators'
import { ActiveFilters } from './order-by/place-filter-form'
import { PlaceFiltersTypesComponent } from './types/place-filters-types.component'

import { ActivatedRoute, Router } from '@angular/router'
import { appConfig } from 'src/brands/_current/config/app-config'

@Component({
  selector: 'turismo-place-filters',
  templateUrl: './place-filters.component.html',
  styleUrls: ['./place-filters.component.scss', './styles/place-filters-common.scss'],
})
export class PlaceFiltersComponent implements OnInit, OnDestroy, OnChanges {
  private readonly onDestroy$: EventEmitter<void> = new EventEmitter<void>()
  private readonly userQuery$ = new Subject<string>()

  @Input()
  areLoading: boolean = false

  @Input()
  query: string

  @Input()
  activeFilters: { [key: string]: boolean }

  @Input()
  categories: FairPlaceFilterCategory[]

  @Input()
  categoriesStatus: { [key: string]: boolean }

  @Input()
  activeFiltersCount: number

  @Input() placeholder: string

  @Input()
  activeDistance?: string

  @Input()
  isMobileView: boolean = false

  @Input()
  filterType: FilterType

  @Input()
  hasOrderFilters: boolean = true

  @Input()
  hasListModality: boolean = true

  @Input()
  activeOrderClause?: string

  @Input()
  searchInputText: string

  @Input() orderByStartDate: boolean

  @Input() enabledSection: any

  @Output()
  searchInputChanged: EventEmitter<string> = new EventEmitter<string>()

  @Output()
  orderByFiltersChanged: EventEmitter<ActiveFilters> = new EventEmitter<ActiveFilters>()

  @Output()
  categoryFiltersChanged: EventEmitter<{ [key: string]: boolean }> = new EventEmitter<{ [key: string]: boolean }>()

  @Output()
  activeFiltersChange: EventEmitter<{
    categories: { [key: string]: boolean }
    order: ActiveFilters
  }> = new EventEmitter<{ categories: { [key: string]: boolean }; order: ActiveFilters }>()

  @Output()
  closeSmallFilters: EventEmitter<void> = new EventEmitter<void>()

  activeOrderFilters: ActiveFilters
  activeCategoriesFilters: { [key: string]: boolean }
  activeCategoriesPreview: Array<{ category: string; subCategory: string }> = []
  isCatDropdownOpen: boolean = false

  @ViewChild(PlaceFiltersTypesComponent) filterTypesCmp: PlaceFiltersTypesComponent

  constructor(
    private store$: Store<AppState>,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.userQuery$.pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.onDestroy$)).subscribe((query) => {
      this.searchInputChanged.emit(query)
    })
  }

  ngOnDestroy(): void {
    this.onDestroy$.emit()
    this.onDestroy$.complete()
  }

  ngOnChanges() {
    this.generatePreviews(this.activeFilters)
  }

  generatePreviews(data) {
    this.activeCategoriesPreview = []
    Object.keys(data).forEach((category) => {
      Object.keys(data[category]).forEach((subCategory) => {
        if (data[category][subCategory]) {
          this.activeCategoriesPreview.push({ category, subCategory })
        }
      })
    })
  }

  onNgModelChange(event: string): void {
    this.userQuery$.next(event)
  }

  onCloseClick(category, subCategory) {
    let filters = cloneDeep(this.activeFilters)
    filters[category][subCategory] = false
    this.categoryFiltersChanged.emit(filters)
  }

  activeFiltersChanged() {
    this.activeFiltersChange.emit({ order: this.activeOrderFilters, categories: this.activeCategoriesFilters })
  }

  orderByFiltersChange(e) {
    if (!this.isMobileView) {
      this.orderByFiltersChanged.emit(e)
    } else {
      this.activeOrderFilters = e
    }
  }

  categoryFiltersChange(e) {
    if (!this.isMobileView) {
      this.categoryFiltersChanged.emit(e)
    } else {
      this.activeCategoriesFilters = e
    }
  }

  applyAllFilters() {
    this.closeSmallFilters.emit()
    this.activeFiltersChanged()
    this.renderer.removeClass(this.document.body, 'hide-overflow')
  }

  openCatDropdown() {
    this.filterTypesCmp.onDropdownToggleClick()
  }
}
