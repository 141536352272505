<div class="header__container uk-box-shadow-medium fixed-top">
  <div class="uk-container uk-container-large d-flex w-100 header__sub-container">
    <div class="header__icons">
      <div class="uk-flex uk-flex-center header__icons-title">
        <ng-container><span i18n>Filtra</span></ng-container>
        <!-- <ng-container ><span i18n>Torna all’elenco</span></ng-container> -->
      </div>
      <img class="uk-icon uk-icon-image" src="assets/images/icons/close.svg" (click)="onToggleMenu()" />
    </div>

    <div class="logo">
      <a class="uk-navbar-item header__logo"> </a>
    </div>

    <div class="header-nav active">
      <ng-content></ng-content>
    </div>
  </div>
</div>
