<ng-container *ngIf="!isMobileView; else mobileTemplate">
  <button (click)="onDropdownToggleClick()" class="place-filters__dropdown-button" type="button">
    <div class="place-filters__dropdown-button--container">
      <ng-container i18n>Tipologia</ng-container>
      <ng-container *ngIf="activeFiltersCount > 0"> ({{ activeFiltersCount }})</ng-container>

      <div style="width: 24px; height: auto; box-sizing: border-box; margin-left: 0.75rem">
        <img
          *ngIf="!isDropdownVisible && activeFiltersCount == 0; else closedDropdownArrowTemplateRef"
          src="assets/images/icons/chevron-down.svg"
          aria-hidden="true"
          class="place-filters-order-by__dropdown-icon"
        />

        <ng-template #closedDropdownArrowTemplateRef>
          <img
            src="assets/images/icons/chevron-up.svg"
            aria-hidden="true"
            class="place-filters-order-by__dropdown-icon"
          />
        </ng-template>
      </div>
      <span class="divider" *ngIf="!isDropdownVisible"></span>
    </div>
  </button>
</ng-container>

<ng-template #mobileTemplate>
  <div class="place-filters__dropdown-button" type="button">
    <div class="place-filters__dropdown-button--container">
      <ng-container i18n>Seleziona la tipologia:</ng-container>
      <ng-container *ngIf="activeFiltersCount > 0"> ({{ activeFiltersCount }})</ng-container>

      <div class="place-filters-types__footer-small" *ngIf="isMobileView">
        <a (click)="resetFilters()" i18n>Azzera filtri</a>
      </div>
      <span class="divider" *ngIf="!isDropdownVisible"></span>
    </div>
  </div>
</ng-template>

<div *ngIf="isDropdownVisible" class="place-filters__dropdown">
  <div>
    <form [formGroup]="formGroup" class="place-filters-types__categories-container">
      <div *ngFor="let category of categories" class="place-filters-types__category">
        <label class="place-filters-types__category-name">
          <input
            type="checkbox"
            (change)="categoryChange(category.name, $event)"
            [(ngModel)]="selectedCategories[category.name]"
            [ngModelOptions]="{ standalone: true }"
          />

          <span>{{ category.name }}</span>
        </label>
        <ul formGroupName="{{ category.name }}">
          <li *ngFor="let subCategory of category.subCategories">
            <label>
              <input
                type="checkbox"
                formControlName="{{ subCategory.name }}"
                (change)="subcategoryChange(category.name, $event)"
              />
              <span>
                {{ subCategory.name }}
              </span>
            </label>
          </li>
        </ul>
      </div>
    </form>
  </div>

  <!-- <div class="place-filters-types__footer-small" *ngIf="isMobileView">
    <a (click)="resetFilters()" i18n>Elimina filtri</a>
  </div> -->

  <div class="place-filters-types__footer" *ngIf="!isMobileView">
    <button
      (click)="resetFilters()"
      class="place-filters__button place-filters-types__footer-remove-filters-button"
      type="button"
      i18n
    >
      Rimuovi filtri
    </button>
    <button (click)="applyFilters()" class="place-filters__button place-filters__apply-button" type="button" i18n>
      Applica
    </button>
  </div>
</div>
