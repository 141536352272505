import { LocationStrategy } from '@angular/common'
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core'
import { AppState } from '@app/core/store/models/app-state.model'
import { Store } from '@ngrx/store'
import { EventListItemBase } from '@shared/components/event-list-item/event-list-item-base'
import { Event } from '@shared/models/event.model'
import * as moment from 'moment'
import { appConfig } from 'src/brands/_current/config/app-config'

@Component({
  selector: 'turismo-event-list-item-small',
  templateUrl: './event-list-item-small.component.html',
  styleUrls: ['./event-list-item-small.component.scss'],
})
export class EventListItemSmallComponent extends EventListItemBase {
  @Input()
  event: Event

  @Input()
  basePath: string = '../'

  @Input()
  eventsListPath: string = './'

  @Input()
  showCity: boolean = true

  @Input()
  showEndDate: boolean = true

  @Input()
  showTime: boolean = true

  @Input()
  isExperience: boolean = false

  // @Input() someHtml: string;

  brandName: string

  constructor(location: LocationStrategy, private store$: Store<AppState>) {
    super()
    this.brandName = appConfig.core.brandName
    location.onPopState(() => {
      window.location.reload()
    })
  }

  getTime() {
    let startTime = moment(this.event.begin).format('H:mm')
    let endTime = moment(this.event.end).format('H:mm')
    if (this.event.begin.getHours() > 0 && this.event.end.getHours() > 0) {
      return `dalle ${startTime} alle ${endTime}`
    } else if (startTime != '00:00' && !endTime) {
      return `Dalle ${startTime}`
    } else {
      return ''
    }
  }
}
