import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import {
  FairPlaceCollectionDto,
  FairPlaceFiltersDto,
  FairPlaceFiltersResponseDto,
} from '@shared/web-api/models/place.models'
import { Observable, of, Observer } from 'rxjs'
import { map } from 'rxjs/operators'
import { ConfigurationDto } from './models/configuration.models'
import {
  EventDetailsResponseDto,
  EventFiltersDto,
  GetEventsResponseDto,
  RelatedEventsResponseDto,
  TantoAltroDto,
} from './models/event.models'
import { ExhibitorDto, ExhibitorFiltersDto, ExhibitorResponseDto } from './models/exhibitor.models'
import { ProductFiltersDto, ProductResponseDto } from './models/product.models'
import { Lang } from './models/lang.type'
import { FairDto } from './models/fair.models'
import { FairEventsFiltersDto, FairFiltersDto } from './models/fair-filters.models'
import {
  GuestCategoryCollectionDto,
  GuestDetailsResponseDto,
  GuestFiltersDto,
  GuestResponseDto,
} from './models/guest.models'
import { HomepageDto } from '@app/features/home/shared/homepage.models'
import { WidgetDto, WidgetEntityType } from '@app/features/home/models/widget.model'
import { PartnersDto } from '@app/features/home/models/partners.model'
import { ExperienceDto, FairExperiencesDto } from '@app/features/fair/models/fair-experiences.models'
import { RelatedPois } from '@app/features/events/models/related-poi.model'
import { FairPoiDto } from '@app/features/fair/models/fair-poi.models'
import { SpecialitiesDto, TourDto, TourSpecialitiesRequest } from '@app/features/tour/models/tour.model'
import { TourFilters } from '@app/features/fair/pages/fair-tour-page/models/filters.model'
import { TourListDto, TourListRequest } from '@app/features/fair/pages/fair-tour-page/models/tour.model'
import { ProductDto } from '@app/features/product/models/product.model'
import { ExcellencesListResponse } from '@app/features/excellence/models/excellences.model'
import { Categories } from '@app/features/excellence/models/excellences-filter.model'
import { ItineraryParams, ItineraryResponseDto } from './models/itinerary.models'
import { ProductsListParams } from '@app/features/product/models/products-list.model'
import { GuestCategory } from '@app/features/fair/shared/guest.model'
import { HomepageGuestsDto } from '@app/features/home/models/guests.model'
import { EventsListResponseDto } from '@app/features/events/models/events-list.model'
import { ToursListResponseDto } from '@app/features/tour/models/tours-list.model'
import { DownloadCategoriesDto, DownloadDtoResponse } from './models/common.models'
import { FairMenuConfig } from '@app/features/fair/models/fair-details.models'
import { ActivatedRoute } from '@angular/router'
import { GoldEventDto } from '@app/features/home/models/goldevents.model'

@Injectable({ providedIn: 'root' })
export class TurismoWebApiService {
  constructor(private httpClient: HttpClient, private route: ActivatedRoute) {}

  getConfiguration(language: Lang): Observable<ConfigurationDto> {
    return this.httpClient.get<ConfigurationDto>(`/Configuration/${language}`)
  }

  getFair(fairId: number, language: Lang): Observable<FairDto> {
    return this.httpClient.get<FairDto>(`/Fairs/${fairId}/${language}`)
  }

  getGuestCategories(fairId: number, language: Lang): Observable<GuestCategoryCollectionDto> {
    return this.httpClient.get<GuestCategoryCollectionDto>(`/FairFilters/guests/${fairId}/${language}`)
  }

  getFairFilters(fairId: number, entityName: string, language: Lang): Observable<FairFiltersDto> {
    return this.httpClient.get<FairFiltersDto>(`/FairFilters/${entityName}/${fairId}/${language}`)
  }

  getPlacesFilters(language: Lang): Observable<FairFiltersDto> {
    return this.httpClient.get<FairFiltersDto>(`/Places/getcategoriesfilter/${language}`)
  }

  getExhibitors(params: ExhibitorFiltersDto): Observable<ExhibitorResponseDto> {
    return this.httpClient.post<ExhibitorResponseDto>(`/Exhibitors/list`, params)
  }

  getGuests(params: GuestFiltersDto): Observable<GuestResponseDto> {
    return this.httpClient.post<GuestResponseDto>(`/Guests/list`, params)
  }

  getGuestsFilters(language: Lang): Observable<GuestCategory[]> {
    return this.httpClient.get<GuestCategory[]>(`/Guests/getcategoriesfilter/${language}`)
  }

  getGuestById(guestId: number, language: Lang): Observable<GuestDetailsResponseDto> {
    return this.httpClient.get<GuestDetailsResponseDto>(`/Guests/${guestId}/${language}`)
  }

  getExhibitor(exhibitorId: number, language: Lang): Observable<ExhibitorDto> {
    return this.httpClient.get<ExhibitorDto>(`/Exhibitors/${exhibitorId}/${language}`)
  }

  getProducts(entityName, params: ProductFiltersDto): Observable<ProductResponseDto> {
    return this.httpClient.post<ProductResponseDto>(`/Products/${entityName}`, params)
  }

  getProductsList(params: ProductsListParams): Observable<ProductResponseDto> {
    return this.httpClient.post<ProductResponseDto>(`/Products/list`, params)
  }

  getProductsFilters(language: Lang): Observable<FairFiltersDto> {
    return this.httpClient.get<FairFiltersDto>(`/Products/getcategoriesfilter/${language}`)
  }

  getItineraries(params: ItineraryParams): Observable<ItineraryResponseDto> {
    return this.httpClient.post<ItineraryResponseDto>('/Itineraries/list', params)
  }

  getEventDetails(eventId: number, language: Lang): Observable<EventDetailsResponseDto> {
    return this.httpClient.get<EventDetailsResponseDto>(`/Events/${eventId}/${language}`)
  }

  getEvents(entityName: 'guest' | 'list', params: EventFiltersDto): Observable<GetEventsResponseDto> {
    return this.httpClient.post<GetEventsResponseDto>(`/Events/${entityName}`, params)
  }

  getRelatedEvents(params: EventFiltersDto): Observable<RelatedEventsResponseDto> {
    return this.httpClient.post<RelatedEventsResponseDto>('/Events/linkedEvents', params).pipe(
      map((res) => {
        if (!res) {
          return {
            events: [],
            totalCount: 0,
          }
        }
        return res
      }),
    )
  }

  getRelatedPois(id: number, language: Lang): Observable<RelatedPois> {
    return this.httpClient.get<RelatedPois>(`/Events/contributors/${id}/${language}`)
  }

  getFairEventsFilters(fairId: number, language: Lang): Observable<FairEventsFiltersDto> {
    return this.httpClient.get<FairEventsFiltersDto>(`/FairFilters/events/${fairId}/${language}`)
  }

  getFairPlaceFilters(fairId: number, language: Lang): Observable<FairPlaceFiltersResponseDto> {
    return this.httpClient.get<FairPlaceFiltersResponseDto>(`/FairFilters/places/${fairId}/${language}`)
  }

  getPlacesList(params: FairPlaceFiltersDto): Observable<FairPlaceCollectionDto> {
    return this.httpClient.post<FairPlaceCollectionDto>(`/Places/list`, params)
  }

  getHomepage(language: Lang): Observable<HomepageDto> {
    return this.httpClient.get<HomepageDto>(`/Home/gethomepage/${language}`)
  }

  getWidgetHomepage(type: WidgetEntityType, language: Lang): Observable<WidgetDto> {
    return this.httpClient.get<WidgetDto>(`/Home/getwidgethomepage/${language}/${type}`)
  }

  getPartners(language: Lang): Observable<PartnersDto> {
    return this.httpClient.get<PartnersDto>(`/Home/getpartners/${language}`)
  }

  getHomepageEvents(language: Lang, num: number): Observable<EventsListResponseDto> {
    return this.httpClient.get<EventsListResponseDto>(`/Home/getevents/${language}/${num}`)
  }

  getGoldEvents(language: Lang, onlyFair: boolean): Observable<GoldEventDto> {
    return this.httpClient.get<GoldEventDto>(`/Home/GetGoldEvents/${language}/${onlyFair}`)
  }

  getHomepageGuests(language: Lang, pageSize: number): Observable<HomepageGuestsDto> {
    return this.httpClient.get<HomepageGuestsDto>(`/Home/getguests/${language}/${pageSize}`)
  }

  getExperiences(params: ExperienceDto): Observable<FairExperiencesDto> {
    return this.httpClient.post<FairExperiencesDto>(`/Contributors/list`, params)
  }

  getPoiDetails(id: number, language: Lang): Observable<FairPoiDto> {
    return this.httpClient.get<FairPoiDto>(`/Poi/${id}/${language}`)
  }

  getTourDetails(id: number, language: Lang): Observable<TourDto> {
    return this.httpClient.get<TourDto>(`/Tours/${id}/${language}`)
  }

  getTourSpecialities(params: TourSpecialitiesRequest): Observable<SpecialitiesDto> {
    return this.httpClient.post<SpecialitiesDto>(`/Products/tour`, params)
  }

  getTourFilters(id: number, language: Lang): Observable<TourFilters> {
    return this.httpClient.get<TourFilters>(`/FairFilters/tours/${id}/${language}`)
  }

  getTourList(params: TourListRequest, entity: string): Observable<TourListDto> {
    if (entity == 'itineraries') {
      return this.httpClient.post<TourListDto>(`/Itineraries/list`, params)
    } else return this.httpClient.post<TourListDto>(`/Tours/list`, params)
  }

  getItin

  getSpecialitiesFilters(id: number, language: Lang): Observable<any> {
    return this.httpClient.get<TourFilters>(`/FairFilters/products/${id}/${language}`)
  }

  getSpecialitiesList(params: TourSpecialitiesRequest): Observable<SpecialitiesDto> {
    return this.httpClient.post<SpecialitiesDto>(`/Products/list`, params)
  }

  getProductDetails(id: number, language: Lang): Observable<ProductDto> {
    return this.httpClient.get<ProductDto>(`/Products/${id}/${language}`)
  }

  getExcellencesList(params: FairPlaceFiltersDto): Observable<ExcellencesListResponse> {
    return this.httpClient.post<ExcellencesListResponse>(`/Excellences/list`, params)
  }

  getExcellencesDetails(id: number, language: string): Observable<ExhibitorDto> {
    return this.httpClient.get<ExhibitorDto>(`/Excellences/${id}/${language}`)
  }

  getExcellencesFilter(language: string): Observable<any> {
    return this.httpClient.get<Categories>(`/Excellences/getcategoriesfilter/${language}`)
  }

  getMoreEvents(params: { masterId: number; language: string }): Observable<TantoAltroDto> {
    return this.httpClient.post<any>(`/Events/poi/`, params)
  }

  getToursList(params: {
    pageNumber: number
    language: string
    pageSize: number
    searchText?: string
    categories?: any
    orderClause?: any
    geoFilter?: any
  }): Observable<ToursListResponseDto> {
    return this.httpClient.post<ToursListResponseDto>(`/Tours/list`, params)
  }

  getToursFilter(language: string): Observable<any> {
    return this.httpClient.get<any>(`/Tours/getcategoriesfilter/${language}`)
  }

  getEventsList(params: {
    pageNumber: number
    language: string
    pageSize: number
    searchText?: string
    categories?: any
    orderClause?: any
    geoFilter?: any
  }): Observable<EventsListResponseDto> {
    return this.httpClient.post<EventsListResponseDto>(`/Events/list`, params)
  }

  getFairDownloads(params?: {
    searchText?: string
    catefory?: any
    language: string
    masterId: number
  }): Observable<any> {
    return this.httpClient.post<DownloadDtoResponse>('/Fairs/GetDownloads', params)
  }

  getDownloadsCategories(id: number, language: Lang): Observable<DownloadCategoriesDto> {
    return this.httpClient.get<DownloadCategoriesDto>(`/FairFilters/GetDownloadCategories/${id}/${language}`)
  }

  getFairMenu(params: { id: number; language: string; callerType: number }): Observable<FairMenuConfig> {
    return this.httpClient.get<FairMenuConfig>(`/Fairs/GetMenu/${params.id}/${params.language}/${params.callerType}`)
  }

  getEventsCategoriesFilter(language: string): Observable<FairPlaceFiltersResponseDto> {
    return this.httpClient.get<FairPlaceFiltersResponseDto>(`/Events/getcategoriesfilter/${language}`)
  }
}
