import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'orderByPlace',
})
export class OrderByPlacePipe implements PipeTransform {
  transform(fullAddress: string): string {
    const splitAddress: string[] = fullAddress.split(', ')

    const street: string = splitAddress[0]
    const city: string = splitAddress[2]

    return `${street}${!!city ? ' - ' + city : ''}`
  }
}
