import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core'

@Component({
  selector: 'turismo-small-card',
  templateUrl: './small-card.component.html',
  styleUrls: ['./small-card.component.scss'],
})
export class SmallCardComponent implements OnChanges {
  @Input()
  isDisabled: boolean = false

  @Input()
  title: string

  @Input()
  subtitle: string | undefined

  @Input()
  startDate?: string

  @Input()
  endDate?: string

  @Input()
  backgroundImage: string

  @Input()
  isFavouriteEnabled: boolean

  @Input()
  favourite: boolean

  @Input()
  nonClickable: boolean = false

  @Input()
  distance?: number

  @Input()
  type?: string

  @Output()
  favouriteChange = new EventEmitter<boolean>()

  @Output()
  cardClicked = new EventEmitter<number>()

  ngOnChanges(changes: SimpleChanges): void {
    const favouriteChanges = changes['favourite']

    if (favouriteChanges && favouriteChanges.currentValue) {
      this.favourite = favouriteChanges.currentValue
    }
  }

  onFavouriteClick(): void {
    this.favourite = !this.favourite

    this.favouriteChange.emit(this.favourite)
  }
}
