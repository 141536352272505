import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { AppState } from '@app/core/store/models/app-state.model'
import { PlaceFilterCategory } from '@app/shared/models/place-filters.models'
import { Store } from '@ngrx/store'
import * as _ from 'lodash'

@Component({
  selector: 'turismo-place-filters-types',
  templateUrl: './place-filters-types.component.html',
  styleUrls: ['../styles/place-filters-common.scss', './place-filters-types.component.scss'],
})
export class PlaceFiltersTypesComponent implements OnDestroy, OnInit {
  private readonly onDestroy$: EventEmitter<void> = new EventEmitter<void>()

  formGroup: FormGroup = new FormGroup({})

  @Input()
  isDropdownVisible: boolean

  @Input()
  categories: PlaceFilterCategory[]

  @Input()
  activeFilters: any

  @Input()
  activeFiltersCount: number

  @Input()
  categoriesStatus: { [key: string]: boolean }

  @Input()
  isMobileView: boolean

  @Output()
  categoryFiltersChanged: EventEmitter<{ [key: string]: boolean }> = new EventEmitter<{ [key: string]: boolean }>()

  selectedCategories: { [key: string]: boolean }
  innerWidth: number

  constructor(private store$: Store<AppState>, private fb: FormBuilder) {}

  ngOnInit() {
    this.toFormGroup(this.categories)
    this.formGroup.patchValue(this.activeFilters)
    if (this.isMobileView) {
      this.categoryFiltersChanged.emit(this.formGroup.value)
    }
    this.selectedCategories = _.cloneDeep(this.categoriesStatus)
    this.isDropdownVisible = this.isMobileView
  }

  toFormGroup(categories: PlaceFilterCategory[]) {
    let categoryControls: any = {}
    categories.forEach((category) => {
      let subCategoryControls = {}
      category.subCategories.forEach((subCategory) => {
        subCategoryControls[subCategory.name] = new FormControl(false)
      })
      categoryControls = new FormGroup(subCategoryControls)
      this.formGroup.addControl(category.name, categoryControls)
    })
  }

  ngOnDestroy(): void {
    this.onDestroy$.emit()
    this.onDestroy$.complete()
  }

  onDropdownToggleClick(): void {
    this.isDropdownVisible = !this.isDropdownVisible
    this.formGroup.patchValue(this.activeFilters)
    this.selectedCategories = _.cloneDeep(this.categoriesStatus)
  }

  applyFilters() {
    this.categoryFiltersChanged.emit(this.formGroup.value)
    this.onDropdownToggleClick()
  }

  resetFilters() {
    this.initializeFilters()
  }

  categoryChange(category: string, event) {
    Object.keys(this.formGroup.controls[category]['controls']).forEach((key) => {
      this.formGroup.controls[category]['controls'][key].setValue(event.target.checked)
    })
    if (this.isMobileView) {
      this.categoryFiltersChanged.emit(this.formGroup.value)
    }
  }

  subcategoryChange(category: string, event) {
    let checkedCat = true
    for (let subCatKey of Object.keys(this.formGroup.controls[category]['controls'])) {
      if (!this.formGroup.controls[category]['controls'][subCatKey].value) {
        checkedCat = false
        break
      }
    }
    this.selectedCategories[category] = checkedCat
    if (this.isMobileView) {
      this.categoryFiltersChanged.emit(this.formGroup.value)
    }
  }

  initializeFilters() {
    let filters = this.formGroup.value
    Object.keys(filters).forEach((category) => {
      Object.keys(filters[category]).forEach((subCategory) => {
        filters[category][subCategory] = false
      })
    })
    Object.keys(this.selectedCategories).forEach((key) => {
      this.selectedCategories[key] = false
    })
    this.formGroup.patchValue(filters)
    if (this.isMobileView) {
      this.categoryFiltersChanged.emit(this.formGroup.value)
    }
  }
}
