<div class="navbar">
    <div class="navbar__nav">
        <!-- <ng-container *ngFor="let menu of navbarItems">
            <div class="navbar__nav--item">
                <span>{{menu.name}}</span>
                <span class="sub-nav-arrow" *ngIf="menu.subNav" (click)="toggleSubMenu()"></span>
            </div>

            <ng-container *ngIf="menu.subNav">
                <div class="navbar__nav__submenu" [ngClass]="activeSubmenu ? 'active' : ''">
                    <div class="navbar__nav__submenu--item" *ngFor="let sub of menu.subNav">
                        <span>{{sub.name}}</span>
                    </div>
                </div>
            </ng-container>
        </ng-container> -->
        <ng-container>
            <div class="language">
                <h1 class="language--text">Seleziona lingua</h1>
                <turismo-language-dropdown
                                           [availableLanguages]="availableLanguages"
                                           [(language)]="lang"
                                           (languageChange)="onLanguageChange($event)"></turismo-language-dropdown>
            </div>
        </ng-container>
    </div>
</div>

<turismo-footer
                [isLoading]="appRemoteConfigIsLoading$ | async"
                [contacts]="appRemoteConfig.contacts"
                [appStores]="appRemoteConfig.marketplaces"
                [copyright]="appRemoteConfig.copyright"
                [OnHeader]="true"></turismo-footer>