import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import UIkit from 'uikit'

@Injectable()
export class HttpErrorInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          UIkit.notification({
            message: "Errore durante l'elaborazione dei  dati",
            pos: 'top-right',
            status: 'danger',
            timeout: 5000,
          })
        }

        return throwError(err)
      }),
    )
  }
}
