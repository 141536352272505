<div class="uk-section uk-padding-remove" class="color" *ngIf="breadcrumbs.length > 1">
  <div class="uk-container uk-container-large">
    <ol class="uk-breadcrumb">
      <li class="uk-text-bold" *ngFor="let breadcrumb of breadcrumbs; index as i">
        <ng-container *ngIf="breadcrumb.isClickable; else noUrlTemplateRef">
          <a
            style="color: #1c1c1c !important; text-decoration: none; font-weight: 700"
            [routerLink]="breadcrumb.url"
            (click)="breadcrumbClicked()"
            >{{ breadcrumb.label }}</a
          >
        </ng-container>
        <ng-template #noUrlTemplateRef>
          <span *ngIf="breadcrumb.label != 'Not found'" style="color: #1c1c1c !important; font-weight: bolder">{{
            breadcrumb.label
          }}</span>
        </ng-template>
      </li>
    </ol>
  </div>
</div>
