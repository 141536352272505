<h1 class="title text-center" i18n>Le fiere alle quali partecipiamo</h1>
<swiper
  [autoplay]="fairs.length > 4"
  [spaceBetween]="30"
  [loopFillGroupWithBlank]="false"
  [pagination]="{
    clickable: true,
    dynamicBullets: false,
    autoHide: true
  }"
  [class.hidden-slider]="fairs.length <= 4"
  [navigation]="fairs.length > 4"
  [breakpoints]="{
    '0': {
      slidesPerView: 1,
      slidesPerGroup: 1
    },
    '576': {
      slidesPerView: 2,
      slidesPerGroup: 2
    },
    '768': {
      slidesPerView: 2,
      slidesPerGroup: 2
    },
    '992': {
      slidesPerView: 3,
      slidesPerGroup: 3
    },
    '1200': {
      slidesPerView: 4,
      slidesPerGroup: 4
    }
  }"
  class="fairs-slide-show"
>
  <ng-template swiperSlide *ngFor="let fair of fairs">
    <div class="fairs-slider">
      <div class="fairs-slider__header" routerLink="../../../fairs/{{ fair.id }}/{{ fair.name }}">
        <img [src]="fair.image.url" [alt]="fair.name" />
      </div>
      <div class="fairs-slider__content">
        <div>
          <h1 class="fairs-slider__content--title" routerLink="../../../fairs/{{ fair.id }}/{{ fair.name }}">
            {{ fair.name }}
          </h1>
          <h4 class="label accent" *ngIf="fair.comigSoon" i18n>Fiera coming soon</h4>
        </div>
        <p
          *turismoEventDates="{ startDate: fair.startDate, endDate: fair.endDate }; label as date"
          [innerHTML]="date"
          class="fairs-slider__content-date"
        ></p>
        <p class="fairs-slider__content--city">{{ fair.city }}</p>

        <a target="_blank" *ngIf="!!fair.link" (click)="goToUrl('')" class="fairs-slider__content--btn">{{
          fair.labelLink ? fair.labelLink : "Scopri l'azienda"
        }}</a>
      </div>

      <div class="fairs-slider__exhibitor-content" *ngIf="fair.exhibitorInfo">
        <div class="fairs-slider__exhibitor-content--header">
          <span
            class="fairs-slider__exhibitor-content--name"
            routerLink="../../../exhibitors/{{ fair.exhibitorInfo.id }}/{{ fair.exhibitorInfo.name }}"
          >
            {{ fair.exhibitorInfo.name }}
          </span>
          <h4 class="label digital" *ngIf="fair.exhibitorInfo.isDigital" i18n>Digitale</h4>
        </div>
        <div *ngIf="fair.exhibitorInfo.zone" class="fairs-slider__exhibitor-content--zone">
          <div>
            <b>Zona: </b><span>{{ fair.exhibitorInfo.zone.area }}</span>
          </div>
          <span>({{ fair.exhibitorInfo.zone.pavilion }})</span>
        </div>
      </div>
    </div>
  </ng-template>
</swiper>
