import { Pipe, PipeTransform } from '@angular/core'
import { Lang } from '@shared/web-api/models/lang.type'

@Pipe({
  name: 'language',
})
export class LanguagePipe implements PipeTransform {
  transform(value: Lang): string {
    switch (value) {
      case 'en':
        return 'ENG'
      case 'it':
        return 'ITA'
      case 'fr':
        return 'FR'
      case 'de':
        return 'DE'
      case 'esp':
        return 'ESP'
    }
  }
}
