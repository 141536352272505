import { AppRemoteConfig, SocialAppRemoteConfig } from '@app/core/models/remote-app-config.model'
import { ConfigurationDto } from '@shared/web-api/models/configuration.models'

export function fromConfigurationDto(dto: ConfigurationDto): AppRemoteConfig {
  const remoteAppConfig: AppRemoteConfig = {
    b2bMenu: [],
    b2cMenu: [],
    multiColorLogoUrl: dto.headerLogo?.url ?? undefined,
    singleColorLogoUrl: dto.footerLogo?.url ?? undefined,
    copyright: dto.copyright ?? undefined,
    partnersImageUrl: dto.partners?.url ?? undefined,
    breadcrumbs: null,
  }

  // Marketplaces apps url
  if (!!dto.appStores && (!!dto.appStores.appleStoreLink || !!dto.appStores.playStoreLink)) {
    remoteAppConfig.marketplaces = {
      appleStoreAppUrl: dto.appStores.appleStoreLink ?? undefined,
      googlePlayStoreAppUrl: dto.appStores.playStoreLink ?? undefined,
    }
  }

  // Contacts
  if (!!dto.contacts && (!!dto.contacts.email || !!dto.contacts.phone)) {
    remoteAppConfig.contacts = {
      email: dto.contacts.email ?? undefined,
      phoneNumber: dto.contacts.phone ?? undefined,
    }
  }

  // Social
  if (
    !!dto.social &&
    (!!dto.social.facebookLink || !!dto.social.instagramLink || !!dto.social.twitterLink || !!dto.social.linkedinLink)
  ) {
    const socialRemoteAppConfig: SocialAppRemoteConfig = {
      facebookUrl: dto.social.facebookLink ?? undefined,
      instagramUrl: dto.social.instagramLink ?? undefined,
      twitterUrl: dto.social.twitterLink ?? undefined,
      linkedinUrl: dto.social.linkedinLink ?? undefined,
    }

    if (!remoteAppConfig.contacts) {
      remoteAppConfig.contacts = {
        social: socialRemoteAppConfig,
      }
    } else {
      remoteAppConfig.contacts.social = socialRemoteAppConfig
    }
  }

  return remoteAppConfig
}
