import { AppRemoteConfigActions } from '@app/core/store/actions'
import { AppRemoteConfigState } from '@app/core/store/models/remote-app-config-state.model'
import { Action, createReducer, on } from '@ngrx/store'
import { LoadingState } from '@shared/store/common.model'

export const featureKey = 'appRemoteConfigState'

const initialState: AppRemoteConfigState = {
  b2bMenu: [],
  b2cMenu: [],
  status: LoadingState.INIT,
  language: 'it',
  breadcrumbs: null,
}

const appRemoteConfigReducer = createReducer(
  initialState,
  on(AppRemoteConfigActions.appRemoteConfigLoadRequested, (state) => {
    return { ...state, status: LoadingState.LOADING }
  }),
  on(AppRemoteConfigActions.appRemoteConfigLoaded, (state, { config, breadcrumbs }) => {
    return { ...state, ...config, breadcrumbs, status: LoadingState.LOADED }
  }),
  on(AppRemoteConfigActions.appRemoteConfigLoadError, (state, { error }) => {
    return { ...state, status: error }
  }),
  on(AppRemoteConfigActions.setAppLanguage, (state, { language }) => {
    return { ...state, language }
  }),
  on(AppRemoteConfigActions.setBreadcrumbs, (state, { breadcrumbs }) => {
    return { ...state, breadcrumbs }
  }),
  on(AppRemoteConfigActions.removeBreadcrumbs, (state) => {
    return { ...state, breadcrumbs: null }
  }),
)
export function reducer(state: AppRemoteConfigState | undefined, action: Action): AppRemoteConfigState {
  return appRemoteConfigReducer(state, action)
}
