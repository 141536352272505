<turismo-header [isLoading]="appRemoteConfigIsLoading$ | async" [logoUrl]="appRemoteConfig.multiColorLogoUrl">
  <!-- <div class="uk-navbar-item"> -->
  <turismo-language-dropdown
    [availableLanguages]="availableLanguages"
    [language]="currentLang"
    (languageChange)="onLanguageChange($event)"
  ></turismo-language-dropdown>
  <!-- </div> -->
</turismo-header>
<turismo-breadcrumb></turismo-breadcrumb>

<div class="turismo__main-content">
  <router-outlet></router-outlet>
</div>

<turismo-footer
  [isLoading]="appRemoteConfigIsLoading$ | async"
  [logoUrl]="appRemoteConfig.singleColorLogoUrl"
  [contacts]="appRemoteConfig.contacts"
  [appStores]="appRemoteConfig.marketplaces"
  [copyright]="appRemoteConfig.copyright"
></turismo-footer>
