import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { Image } from '@shared/models/image.model'

@Component({
  selector: 'turismo-exhibitor-gallery',
  templateUrl: './exhibitor-gallery.component.html',
  styleUrls: [
    './exhibitor-gallery.component.scss',
    './exhibitor-gallery-medium.component.scss',
    './exhibitor-gallery-small.component.scss',
  ],
})
export class ExhibitorGalleryComponent implements OnChanges, AfterViewInit {
  @ViewChild('galleryElRef')
  private readonly galleryElement!: ElementRef

  @Input()
  items?: Image[] = []

  @Input()
  currentItemIndex?: number | null = null

  @Input() responsive: boolean

  @Output()
  currentItemIndexChange: EventEmitter<number | null> = new EventEmitter<number | null>()

  get totalItems(): number {
    return this.items.length
  }

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const itemsChanges = changes['items']

    this.currentItemIndex =
      !!itemsChanges && !!itemsChanges.currentValue && itemsChanges.currentValue.length > 0 ? 0 : null
  }

  ngAfterViewInit(): void {
    if (!!this.galleryElement.nativeElement) {
      const hammer: HammerManager = new Hammer(this.galleryElement.nativeElement, {})
      hammer.on('swipeleft', () => this.onNavigateForward())
      hammer.on('swiperight', () => this.onNavigateBackward())
    }
  }

  onNavigateForward(): void {
    this.safeIndexSet(this.currentItemIndex + 1)
  }

  onNavigateBackward(): void {
    this.safeIndexSet(this.currentItemIndex - 1)
  }

  onDotNavClick(index: number): void {
    this.safeIndexSet(index)
  }

  private safeIndexSet(index: number): void {
    const item: Image | undefined = this.items[index]

    if (!!item) {
      this.currentItemIndex = index

      this.cdr.markForCheck()

      this.currentItemIndexChange.emit(this.currentItemIndex)
    }
  }
}
