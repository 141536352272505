<ng-container *ngIf="type === 'video'">
  <div class="uk-position-relative photo-carousel" uk-slider="autoplay: false">
    <div class="uk-slider-container">
      <ul
        class="uk-slider-items uk-child-width-1-1@s uk-child-width-1-2@m uk-grid"
        [ngClass]="{ 'justify-content-center': data.length <= 1 }"
        [class.uk-child-width-1-3@l]="multipleImg"
        [class.uk-child-width-1-1@l]="!multipleImg"
      >
        <ng-container *ngFor="let d of data">
          <li>
            <iframe
              id="youtube-main-video"
              frameborder="0"
              class="youtube-video-frame"
              allow="autoplay; fullscreen"
              [src]="youtubeVideo(d.link) | sanitizeContent: 'resource'"
            ></iframe>
          </li>
        </ng-container>
      </ul>
    </div>
    <div class="photo-carousel__actions">
      <div class="photo-carousel__actions--buttons">
        <a
          class="uk-position-center-left uk-position-small uk-hidden-hover photo-carousel__actions--buttons--previews"
          href="#"
          uk-slider-item="previous"
        ></a>
        <a
          class="uk-position-center-right uk-position-small uk-hidden-hover photo-carousel__actions--buttons--next"
          href="#"
          uk-slider-item="next"
        ></a>
      </div>
      <ul class="uk-slider-nav uk-dotnav uk-flex-center photo-carousel__actions--dots uk-margin"></ul>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="type === 'photo'">
  <div class="uk-position-relative photo-carousel" uk-slider="autoplay: false">
    <div class="uk-slider-container">
      <ul
        class="uk-slider-items uk-child-width-1-1@s uk-child-width-1-1@m uk-grid"
        [class.uk-flex-center]="data.length < 3"
        [class.uk-child-width-1-3@l]="multipleImg"
        [class.uk-child-width-1-1@l]="!multipleImg"
      >
        <ng-container *ngFor="let img of data">
          <li class="uk-cover-container">
            <img [src]="img.url" alt="" />
          </li>
        </ng-container>
      </ul>
    </div>
    <div class="photo-carousel__actions">
      <div class="photo-carousel__actions--buttons">
        <a
          class="uk-position-center-left uk-position-small uk-hidden-hover photo-carousel__actions--buttons--previews"
          href="#"
          uk-slider-item="previous"
        ></a>
        <a
          class="uk-position-center-right uk-position-small uk-hidden-hover photo-carousel__actions--buttons--next"
          href="#"
          uk-slider-item="next"
        ></a>
      </div>
      <ul class="uk-slider-nav uk-dotnav uk-flex-center photo-carousel__actions--dots uk-margin"></ul>
    </div>
  </div>
</ng-container>
