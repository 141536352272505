import { APP_BASE_HREF, PlatformLocation } from '@angular/common'
import { NgModule } from '@angular/core'
import { BrowserModule, Title } from '@angular/platform-browser'
import { CoreModule } from '@app/core'

// swiper module
import { SwiperModule } from 'swiper/angular'

import { AppComponent } from './app.component'
import { SharedModule } from '@shared/shared.module'
import { AppRoutingModule } from './app-routing.module'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { TurismoWebApiInterceptor } from '@shared/web-api/turismo-web-api.interceptor'

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CoreModule.forRoot(),
    SharedModule,
    AppRoutingModule,
    SwiperModule,
  ],
  providers: [
    /* With the SSR enabled the APP_BASE_HREF will be assigned from server so this code will be obsolete */
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
    { provide: HTTP_INTERCEPTORS, useClass: TurismoWebApiInterceptor, multi: true },
    // set the title dynamic
    Title,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
