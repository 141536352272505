import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { PageNotFoundComponent } from '@shared/components/page-not-found/page-not-found.component'
import { appConfig } from '../brands/_current/config/app-config'
import { ExhibitorDetailPageComponent } from './features/exhibitor/pages/exhibitor-detail-page/exhibitor-detail-page.component'
import { RequireExhibitorGuard } from './features/exhibitor/shared/require-exhibitor.guard'
import { EXHIBITOR, EXHIBITOR_ID, EXHIBITOR_NAME, POI_ID, POI_NAME } from './shared/route-params-and-data'

// TODO: aggiungere guardia su canActivateChild che controlla se la configurazione è stata caricata
const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: appConfig.homepage.paths._root,
    loadChildren: () => import('@features/home/homepage.module').then((m) => m.HomepageModule),
  },
  {
    path: appConfig.tour.paths._root,
    loadChildren: () => import('@features/tour/tour.module').then((m) => m.TourModule),
    data: {
      breadcrumb: 'Tour',
      breadcrumbEn: 'Tours',
      breadcrumbIsClickable: true,
      enabled: appConfig.tour.enabled,
    },
  },
  {
    path: appConfig.place.paths._root,
    loadChildren: () => import('@features/place/place.module').then((m) => m.PlaceModule),
    data: {
      breadcrumb: 'Luoghi',
      breadcrumbEn: 'Places',
      breadcrumbIsClickable: true,
      enabled: appConfig.place.enabled,
    },
  },
  {
    path: appConfig.excellences.paths._root,
    loadChildren: () => import('@app/features/excellence/excellence.module').then((m) => m.ExcellenceModule),
    data: {
      breadcrumb: 'Eccellenze',
      breadcrumbEn: 'Excellences',
      breadcrumbIsClickable: true,
      enabled: appConfig.excellences.enabled,
    },
  },
  {
    path: appConfig.event.paths._root,
    loadChildren: () => import('@features/events/event.module').then((m) => m.EventModule),
    data: {
      breadcrumb: 'Eventi',
      breadcrumbEn: 'Events',
      breadcrumbIsClickable: true,
      enabled: appConfig.events.enabled,
    },
  },
  {
    path: 'pois',
    children: [
      {
        path: `:${POI_ID}`,
        resolve: {
          ['poi']: RequireExhibitorGuard,
        },
        children: [
          {
            path: `:${POI_NAME}`,
            component: ExhibitorDetailPageComponent,
          },
          {
            path: `${appConfig.product.paths._root}`,
            loadChildren: () => import('@features/product/product.module').then((m) => m.ProductModule),
          },
        ],
      },
    ],
    data: {
      enabled: appConfig.poi.enabled,
    },
  },
  {
    path: 'exhibitors',
    children: [
      {
        path: `:${EXHIBITOR_ID}`,
        resolve: {
          [EXHIBITOR]: RequireExhibitorGuard,
        },
        children: [
          {
            path: `:${EXHIBITOR_NAME}`,
            component: ExhibitorDetailPageComponent,
          },
          {
            path: `${appConfig.product.paths._root}`,
            loadChildren: () => import('@features/product/product.module').then((m) => m.ProductModule),
          },
        ],
      },
    ],
    data: {
      enabled: appConfig.exhibitor.enabled,
    },
  },
  {
    path: 'products',
    loadChildren: () => import('@features/product/product.module').then((m) => m.ProductModule),
    data: {
      breadcrumb: 'Prodotti',
      breadcrumbEn: 'Products',
      breadcrumbIsClickable: true,
      enabled: appConfig.product.enabled,
    },
  },
  {
    path: 'guests',
    loadChildren: () => import('@features/guest/guest.module').then((m) => m.GuestModule),
    data: {
      breadcrumb: 'Ospiti',
      breadcrumbEn: 'Guests',
      breadcrumbIsClickable: true,
      enabled: appConfig.guest.enabled,
    },
  },
  {
    path: appConfig.fair.paths._root,
    loadChildren: () => import('@features/fair/fair.module').then((m) => m.FairModule),
    data: {
      enabled: appConfig.fair.enabled,
    },
    // TODO: aggiungere guardia su canActivateChild che controlla se la configurazione è stata caricata
  },
  {
    path: appConfig.events.paths._root,
    loadChildren: () => import('@features/events/event.module').then((m) => m.EventModule),
    data: {
      enabled: appConfig.events.enabled,
    },
  },
  {
    path: '**',
    data: {
      breadcrumb: 'Not found',
    },
    component: PageNotFoundComponent,
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'disabled',
    }),
  ],
  exports: [RouterModule],
  providers: [
    // TODO: Must be removed when the development is completed
    { provide: 'externalRedirect', useValue: () => (window.location.href = 'https://www.tipicitaexperience.it/') },
  ],
})
export class AppRoutingModule {}
