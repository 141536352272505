import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core'
import { Lang } from '@shared/web-api/models/lang.type'
import UIkit from 'uikit'

@Component({
  selector: 'turismo-language-dropdown',
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.scss'],
})
export class LanguageDropdownComponent implements OnInit {
  @Input()
  availableLanguages: Lang[]

  @Input()
  language: Lang

  @Output()
  languageChange = new EventEmitter<Lang>()

  @ViewChild('languageDropdown')
  private readonly languageDropdownRef!: ElementRef

  constructor(private cdr: ChangeDetectorRef) {
    if (this.cdr.detach && this.cdr.detectChanges) {
      // console.log('dropdown lang', this.language)
      this.ngOnInit()
    }
  }

  ngOnInit(): void {}

  onLanguageSelected(lang: Lang): void {
    // @ts-ignore
    UIkit.dropdown(this.languageDropdownRef.nativeElement).hide(false)

    this.language = lang

    this.languageChange.emit(this.language)

    this.ngOnInit()
  }
}
