import { Component, Input } from '@angular/core'
import { ContactsAppRemoteConfig, MarketplaceAppRemoteConfig } from '../../models/remote-app-config.model'

@Component({
  selector: 'turismo-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input()
  logoUrl?: string | null

  @Input()
  OnHeader: boolean = false;

  @Input()
  copyright?: string

  @Input()
  appStores?: MarketplaceAppRemoteConfig

  @Input()
  contacts?: ContactsAppRemoteConfig

  @Input()
  isLoading: boolean = false
}
