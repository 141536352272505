<div class="social-media-share">
  <button class="btn btn-default">
    CONDIVIDI
    <img [src]="shareIcon" />
  </button>
  <div uk-dropdown="pos: left-center" class="social-media-share__pop-up">
    <h4 class="social-media-share__pop-up--title"></h4>
    <div class="social-media-share__pop-up--item" *ngFor="let item of socialItems">
      <ng-container [ngSwitch]="item.socialType">
        <img [src]="'assets/images/icons/Facebook.svg'" *ngSwitchCase="socialTypes.facebook" />
        <img [src]="'assets/images/icons/instagram.svg'" *ngSwitchCase="socialTypes.instagram" />
        <img [src]="'assets/images/icons/Twitter.svg'" *ngSwitchCase="socialTypes.twitter" />
        <img [src]="'assets/images/icons/youtube-dark-icon.svg'" *ngSwitchCase="socialTypes.youtube" />
      </ng-container>
      <span>{{ item.socialType }}</span>
    </div>
  </div>
</div>
