import { Component, Input, OnInit } from '@angular/core'
import { SocialItem, SocialType } from '@app/shared/models/contact-info.model'

@Component({
  selector: 'turismo-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss'],
})
export class SocialShareComponent implements OnInit {
  @Input()
  socialItems: Array<SocialItem>

  @Input()
  shareIcon: string

  socialTypes = SocialType

  constructor() {}

  ngOnInit(): void {
    console.log('Socials we get:', this.socialItems)
  }
}
