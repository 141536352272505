import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import {
  ExhibitorActiveFilters,
  ExhibitorFilters,
  ExhibitorFiltersCategory,
  ExhibitorFiltersZone,
} from './exhibitor-filters.model'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators'
import UIkit from 'uikit'
import { Router } from '@angular/router'
import { appConfig } from 'src/brands/_current/config/app-config'

@Component({
  selector: 'turismo-exhibitor-filters',
  templateUrl: './exhibitor-filters.component.html',
  styleUrls: ['./exhibitor-filters.component.scss'],
})
export class ExhibitorFiltersComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  isLoading = false

  @Input()
  categories: ExhibitorFiltersCategory[]

  @Input()
  zones: ExhibitorFiltersZone[]

  @Input()
  filters: ExhibitorActiveFilters

  @Input()
  placeholder: string

  @Input()
  columns: number

  @Input() type: string

  @Output()
  filtersChange = new EventEmitter<ExhibitorActiveFilters>()

  subCategories: { id: number; name: string }[]
  searchInputLabel: string

  selectedCategoryId = new Subject<number>()
  specialitaCategoryId: number
  inSpecialityPage: boolean = false
  dropdownIcon = {
    arrowDown: 'assets/images/icons/bold-chevron-down.svg',
  }

  enabledFilters = appConfig.exhibitor.enabledSections.filters

  readonly dropdownOptions = 'delay-hide: 0; pos: bottom-center; mode: click'

  private readonly userQuery$ = new Subject<string>()
  private readonly onDestroy$ = new EventEmitter<void>()

  @ViewChild('subcategoryDropdownRef')
  private readonly subcategoryDropdown!: ElementRef

  @ViewChild('areaDropdownRef')
  private readonly areaDropdown!: ElementRef

  constructor(private router: Router) {}

  ngOnInit(): void {
    // Not initialized from outside
    if (!this.filters) {
      this.filters = {}
    }

    this.selectedCategoryId.next(this.categories[0]['categoryId'])

    this.userQuery$.pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.onDestroy$)).subscribe((query) => {
      this.filters.query = !!query ? query : undefined
      this.filtersChange.emit(this.filters)
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    const categoriesChanges = changes['categories']
    this.selectedCategoryId.next(this.categories[0]['categoryId'])

    this.selectedCategoryId.subscribe((val) => {
      this.specialitaCategoryId = val
    })

    if (this.router.url.match('specialities')) {
      this.inSpecialityPage = true
    } else {
      this.inSpecialityPage = false
    }

    if (categoriesChanges && categoriesChanges.firstChange) {
      this.subCategories = categoriesChanges.currentValue[0]?.subCategories ?? undefined

      this.filters = {
        ...this.filters,
        categoryId: categoriesChanges.currentValue[0]?.id ?? undefined,
      }
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.emit()
  }

  onNgModelChange(event: string): void {
    this.userQuery$.next(event)
    // DO NOT EVENT EMMIT HERE!!!
  }

  onSubCategoryClick(id: number, name: string): void {
    // @ts-ignore
    UIkit.dropdown(this.subcategoryDropdown.nativeElement).hide(false)

    if (this.filters.subCategoryId !== id) {
      this.filters.subCategoryId = id
      this.filters.subCategoryName = name
      this.filtersChange.emit(this.filters)
    }
  }

  getClickedVal(ev) {
    this.selectedCategoryId.next(ev)
  }

  onCategoryClick(id: number, subCategories: { id: number; name: string }[]): void {
    if (this.filters.categoryId !== id) {
      this.subCategories = subCategories

      this.filters = {
        ...this.filters,
        categoryId: id,
        subCategoryId: undefined,
        subCategoryName: undefined,
        areaId: undefined,
        areaName: undefined,
        zoneId: undefined,
        zoneName: undefined,
        query: undefined,
      }

      this.filtersChange.emit(this.filters)
    }
  }

  onAllSubCategoryClick(): void {
    // @ts-ignore
    UIkit.dropdown(this.subcategoryDropdown.nativeElement).hide(false)

    if (this.filters.subCategoryId) {
      this.filters.subCategoryId = undefined
      this.filters.subCategoryName = undefined

      this.filtersChange.emit(this.filters)
    }
  }

  onAllAreaClick(): void {
    // @ts-ignore
    UIkit.dropdown(this.areaDropdown.nativeElement).hide(false)

    if (this.filters.zoneId || this.filters.areaId) {
      this.filters.zoneId = undefined
      this.filters.zoneName = undefined
      this.filters.areaId = undefined
      this.filters.areaName = undefined

      this.filtersChange.emit(this.filters)
    }
  }

  onZoneClick(id: number, name: string): void {
    // @ts-ignore
    UIkit.dropdown(this.areaDropdown.nativeElement).hide(false)

    if (this.filters.zoneId !== id || this.filters.areaId) {
      this.filters.zoneId = id
      this.filters.zoneName = name

      this.filters.areaId = undefined
      this.filters.areaName = undefined

      this.filtersChange.emit(this.filters)
    }
  }

  onAreaClick(zoneId: number, areaId: number, areaName: string): void {
    // @ts-ignore
    UIkit.dropdown(this.areaDropdown.nativeElement).hide(false)

    if (this.filters.zoneId !== zoneId || this.filters.areaId !== areaId) {
      this.filters.areaId = areaId
      this.filters.zoneId = zoneId
      this.filters.areaName = areaName
      this.filters.zoneName = undefined

      this.filtersChange.emit(this.filters)
    }
  }
}
