import { Event } from '@shared/models/event.model'

export abstract class EventListItemBase {
  abstract event: Event
  readonly currentDate: Date = new Date()

  abstract basePath: string = '../'
  abstract eventsListPath: string = './'

  abstract showCity: boolean = true
  abstract showEndDate: boolean = true
  abstract showTime: boolean = true
}
