import { appConfig } from 'src/brands/_current/config/app-config'
import { APP_BASE_HREF, Location, LocationStrategy, PathLocationStrategy } from '@angular/common'
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { NgGoogleAnalyticsService } from '@mycicero/ng-google-analytics'
import { Store } from '@ngrx/store'
import { TurismoWebApiService } from '@shared/web-api/turismo-web-api.service'
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst'
import { map, shareReplay, takeUntil, tap } from 'rxjs/operators'
import { Observable } from 'rxjs'

import { AppRemoteConfig } from '@app/core/models/remote-app-config.model'
import { AppState } from '@app/core/store/models/app-state.model'
import { AppRemoteConfigState } from '@app/core/store/models/remote-app-config-state.model'
import { AppRemoteConfigSelectors } from '@app/core/store/selectors'
import { Lang } from '@app/shared/web-api/models/lang.type'
import { defaultAppConfig } from '@app/core/app-default-config'

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
})
export class NavigationComponent implements OnInit, OnDestroy {
  private readonly onDestroy$: EventEmitter<void> = new EventEmitter<void>()

  lang: Lang
  availableLanguages: Lang[]

  appRemoteConfig: AppRemoteConfig
  appRemoteConfigIsLoading: boolean

  activeSubmenu: boolean = false

  navbarItems = [
    { name: 'Percorsi' },
    { name: 'Pillole di Tipicita' },
    { name: 'Mondo Tipicita' },
    { name: 'Collaborazioni' },
    {
      name: 'Collaborazioni',
      subNav: [
        {
          name: 'Test sub nav 1',
        },
        {
          name: 'Test sub nav 2',
        },
      ],
    },
  ]

  readonly appRemoteConfigIsLoading$: Observable<boolean> = this.store$
    .select(AppRemoteConfigSelectors.selectAppRemoteConfigIsLoading)
    .pipe(takeUntil(this.onDestroy$), shareReplay({ refCount: true, bufferSize: 1 }))

  constructor(
    private turismoWebApiService: TurismoWebApiService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(APP_BASE_HREF) public baseHref: string,
    private location: Location,
    private ngGoogleAnalyticsService: NgGoogleAnalyticsService,
    private angulartics: Angulartics2GoogleGlobalSiteTag,
    private store$: Store<AppState>,
    private cdr: ChangeDetectorRef,
  ) {
    this.lang = this.locale.split('-')[0].toLowerCase() as Lang
  }

  ngOnInit(): void {
    if (!!appConfig.analytics.google?.trackingList?.length) {
      this.ngGoogleAnalyticsService.loadGtagScript().subscribe({
        complete: () => {
          this.angulartics.startTracking()
        },
      })
    }

    this.availableLanguages = appConfig.language.availableLanguages.filter((lang) => lang !== this.lang)

    this.store$
      .select(AppRemoteConfigSelectors.selectAppRemoteConfigState)
      .pipe(
        map<AppRemoteConfigState, AppRemoteConfig>((config) => ({ ...config })),
        takeUntil(this.onDestroy$),
      )
      .subscribe((config) => {
        this.appRemoteConfig = config
        this.cdr.markForCheck()
      })
  }

  ngOnDestroy(): void {
    this.onDestroy$.emit()
    this.onDestroy$.complete()
  }

  toggleSubMenu() {
    this.activeSubmenu = !this.activeSubmenu
  }

  onLanguageChange(selectedLanguage: Lang): void {
    this.availableLanguages = defaultAppConfig.language.availableLanguages.filter((lang) => lang !== selectedLanguage)

    let baseUrl = this.baseHref.split('/').filter((element) => !!element)
    baseUrl.pop() // Remove old language
    baseUrl.push(selectedLanguage) // Add new language

    const fullPath = [
      ...baseUrl,
      ...this.location
        .path()
        .split('/')
        .filter((element) => !!element),
    ].join('/')
    window.location.href = `/${fullPath}`
  }
}
