import { Breadcrumb } from '@app/core/components/breadcrumb/breadcrumb.model'
import { Lang } from '@app/shared/web-api/models/lang.type'
import { createAction, props } from '@ngrx/store'
import { ErrorState } from '@shared/store/common.model'
import { AppRemoteConfig } from '../../models/remote-app-config.model'

export const appRemoteConfigLoadRequested = createAction(
  '[CoreModule][AppRemoteConfigEffects] Application remote configuration load requested',
)
export const appRemoteConfigLoaded = createAction(
  '[CoreModule][AppRemoteConfigEffects] Application remote configuration loaded successfully',
  props<{ config: AppRemoteConfig; breadcrumbs: Breadcrumb[] }>(),
)
export const appRemoteConfigLoadError = createAction(
  '[CoreModule][AppRemoteConfigEffects] Application remote configuration cannot be loaded',
  props<{ error: ErrorState }>(),
)
export const appLanguageChanged = createAction(
  '[CoreModule][AppRemoteConfigEffects] Application langugage changed',
  props<{ fullPath: string; language: Lang }>(),
)
export const getAppLanguage = createAction('[CoreModule][AppRemoteConfigEffects] Get application language')
export const setAppLanguage = createAction(
  '[CoreModule][AppRemoteConfigEffects] Set application language',
  props<{ language: Lang }>(),
)
export const setBreadcrumbs = createAction(
  '[CoreModule] Set static breadcrumbs',
  props<{ breadcrumbs: Breadcrumb[] }>(),
)
export const removeBreadcrumbs = createAction('[CoreModule] Remove static breadcrumbs')
