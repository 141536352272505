import { EventEmitter, Inject, Injectable, LOCALE_ID, OnDestroy } from '@angular/core'
import { AppState } from '@app/core/store/models/app-state.model'
import { getSelectedLanguage } from '@app/core/store/selectors/app-remote-config.selectors'
import { FairPoi } from '@app/features/fair/models/fair-poi.models'
import { Lang } from '@app/shared/web-api/models/lang.type'
import { TurismoWebApiService } from '@app/shared/web-api/turismo-web-api.service'
import { Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map, takeUntil } from 'rxjs/operators'
import { appConfig } from 'src/brands/_current/config/app-config'

@Injectable({
  providedIn: 'root',
})
export class PoiService implements OnDestroy {
  private lang: Lang
  private readonly onDestroy$: EventEmitter<void> = new EventEmitter<void>()

  constructor(
    private webApi: TurismoWebApiService,
    @Inject(LOCALE_ID) public locale: string,
    private store$: Store<AppState>,
  ) {
    // this.lang = this.locale.split('-')[0].toLowerCase() as Lang
    this.store$
      .select(getSelectedLanguage)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((val) => {
        this.lang = val
      })
  }
  //Exhibitor mapping
  public getPoiDetails(id: string): Observable<FairPoi> {
    return this.webApi.getPoiDetails(+id, this.lang).pipe(
      map((poi) => ({
        id: poi.id,
        contactInfo: {
          facebook: poi.contact?.socialSiteUrl,
          mail: poi.contact?.email,
          site: poi.contact?.siteUrl,
          telephone: poi.contact?.phone,
        },
        description: poi.descriptionHtml ? poi.descriptionHtml : poi.description,
        name: poi.name,
        travelPlannerUrl: !poi.placeInfo?.address
          ? null
          : appConfig.core.travelPlannerUrl
          ? appConfig.core.travelPlannerUrl
              .replace(
                '{{desc}}',
                `${poi.placeInfo?.address?.street}${
                  poi.placeInfo?.address?.streetNumber ? `, ${poi.placeInfo?.address?.streetNumber}` : ''
                } ${poi.placeInfo?.address?.city}`,
              )
              .replace('{{lat}}', `${poi.placeInfo?.address?.lat}`)
              .replace('{{lng}}', `${poi.placeInfo?.address?.lon}`)
          : '',
        mapUrl: poi.mapUrl,
        shortDescription: poi.subCategory?.subCategoryName,
        images: [
          {
            url: poi.gallery?.foreground?.url,
            description: poi.gallery?.foreground?.description,
          },
          ...poi.gallery?.images?.map((image) => ({
            url: image.url,
            description: image.description,
          })),
        ],
        reviews: [],
        products: [],
        partners: [],
        videoGallery: poi.videoGallery,
        gallery: poi.gallery,
        placeInfo: poi.placeInfo,
        services: poi.additionalInfos
          // Exclude icons that dont have the url
          .filter((addInfos) => !!addInfos.iconUrl)
          .map((addInfos) => ({
            icon: addInfos.iconUrl,
            description: addInfos.name,
            id: addInfos.fieldId,
          })),
        abstract: poi.abstract,
        abstractHtml: poi.abstractHtml,
        shopUrl: poi.onlineShop ?? undefined,
        shopProducts:
          poi.commercialProducts?.totalCount > 0
            ? poi.commercialProducts.products.map(({ price, description, image, name }) => ({
                exhibitorName: poi.name,
                price,
                description,
                name,
                imageUrl: image,
              }))
            : [],
        archiveDescription: poi.archiveDescriptionHtml ? poi.archiveDescriptionHtml : poi.archiveDescription,
        downloads: poi.downloads,
        fairs: poi.fairs,
      })),
      catchError((error) => {
        // TODO: implementare gestione errori
        if (error instanceof Error) {
          console.error(error.message)
        }
        return of(null)
      }),
    )
  }

  ngOnDestroy() {
    this.onDestroy$.emit()
  }
}
