export const EXHIBITOR_ID = 'idExhibitor'
export const EXHIBITOR_NAME = 'exhibitorName'
export const EXHIBITOR = 'exhibitor'
export const POI = 'poi'
export const POI_ID = 'poiId'
export const POI_NAME = 'poiName'
export const EXCELLENCE = 'excellence'
export const EXCELLENCE_ID = 'excellenceId'
export const EXCELLENCE_NAME = 'excellenceName'
export const PRODUCT_ID = 'productId'
export const PRODUCT_NAME = 'productName'
export const TOUR_ID = 'tourId'
export const TOUR_NAME = 'tourName'
export const EVENT_ID = 'eventId'
export const EVENT_NAME = 'eventName'
export const GUEST_ID = 'guestId'
export const GUEST_NAME = 'guestName'
