import { DOCUMENT } from '@angular/common'
import { Component, Inject, Input, OnInit, Renderer2 } from '@angular/core'
// import { appConfig } from 'src/brands/_current/config/app-config'

@Component({
  selector: 'turismo-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input()
  logoUrl: string

  @Input()
  menuItems: any

  @Input()
  isLoading: boolean = false

  isLogoLoading: boolean = true

  activeMenu: boolean = false

  readonly skeletonImageTheme = {
    'height.rem': 3.125,
    'width.rem': 6,
    margin: 'unset',
  }

  constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2) {}

  ngOnInit(): void {}

  onLogoLoaded(): void {
    this.isLogoLoading = false
  }

  onLogoLoadError(): void {
    this.isLogoLoading = false
    console.error('Cannot load the logo.')
  }

  onToggleMenu() {
    this.activeMenu = !this.activeMenu
    if (this.activeMenu === true) {
      this.renderer.addClass(this.document.body, 'hide-overflow')
    } else this.renderer.removeClass(this.document.body, 'hide-overflow')
  }
}
