import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'turismo-entities-header',
  templateUrl: './entities-header.component.html',
  styleUrls: ['./entities-header.component.scss'],
})
export class EntitiesHeaderComponent implements OnInit {
  @Input()
  imageUrl: string

  @Input()
  title: string

  @Input()
  subTitle: string

  constructor() {}

  ngOnInit(): void {}
}
