import { Inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { Observable, of, throwError } from 'rxjs'
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'

import { AppRemoteConfigActions } from '@app/core/store/actions'
import { TurismoWebApiService } from '@shared/web-api/turismo-web-api.service'
import { fromConfigurationDto } from './mappers/app-config.mapper'
import { APP_BASE_HREF } from '@angular/common'
import { getSelectedLanguage, selectBreadcrumbs } from '../selectors/app-remote-config.selectors'
import { AppState } from '../models/app-state.model'

import { Lang } from '@app/shared/web-api/models/lang.type'
import { setBreadcrumbs } from '../actions/app-remote-config-effect.actions'
import { defaultAppConfig } from '@app/core/app-default-config'

@Injectable()
export class AppRemoteConfigEffects implements OnInitEffects {
  constructor(
    private actions$: Actions,
    private turismoWebApiService: TurismoWebApiService,
    private store$: Store<AppState>,
    @Inject(APP_BASE_HREF) public baseHref: string,
  ) {}

  rehydrateSession$ = createEffect(() => {
    return this.actions$.pipe(
      ofType('@ngrx/effects/init'),
      switchMap(() => AppRemoteConfigEffects.rehydrateFunction()),
    )
  })

  ngrxOnInitEffects(): Action {
    return AppRemoteConfigActions.getAppLanguage()
  }

  getLanguageEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppRemoteConfigActions.getAppLanguage),
      map((action) => {
        let urlLang = this.getLanguageFromUrl(window.location.pathname)
        return AppRemoteConfigActions.setAppLanguage({
          language: !!this.getFormattedLang(urlLang) ? this.getFormattedLang(urlLang) : 'it',
        })
      }),
    )
  })

  initConfigAfterLang$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppRemoteConfigActions.setAppLanguage),
      map((_) => {
        return AppRemoteConfigActions.appRemoteConfigLoadRequested()
      }),
    )
  })

  loadRemoteAppConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppRemoteConfigActions.appRemoteConfigLoadRequested),
      withLatestFrom(this.store$.select(getSelectedLanguage)),
      switchMap(([action, lang]) => {
        const breadcrumbs = JSON.parse(localStorage.getItem('breadcrumbs'))
        return this.turismoWebApiService.getConfiguration(lang).pipe(
          map(fromConfigurationDto),
          map((config) => AppRemoteConfigActions.appRemoteConfigLoaded({ config, breadcrumbs })),
          catchError((err) =>
            throwError(AppRemoteConfigActions.appRemoteConfigLoadError({ error: { errorMsg: err } })),
          ),
        )
      }),
    )
  })

  langugageChanged$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AppRemoteConfigActions.appLanguageChanged),
        tap((action) => {
          window.location.href = `/${action.fullPath}`
        }),
      )
    },
    { dispatch: false },
  )

  storeBreadcrumbs$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AppRemoteConfigActions.setBreadcrumbs),
        tap((action) => {
          localStorage.setItem('breadcrumbs', JSON.stringify(action.breadcrumbs))
        }),
      )
    },
    { dispatch: false },
  )

  removeBreadcrumbs$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AppRemoteConfigActions.removeBreadcrumbs),
        tap((action) => {
          localStorage.setItem('breadcrumbs', JSON.stringify(null))
        }),
      )
    },
    { dispatch: false },
  )

  getLanguageFromUrl(url: string) {
    //let parts = url.split('/').filter((element) => !!element)
    let parts = url.split('/')
    let lang
    parts.forEach((part) => {
      let checked = defaultAppConfig.language.availableLanguages.filter((l) => l == part)
      if (checked.length > 0) {
        lang = part
      }
    })
    return lang
  }

  getFormattedLang(lang: string) {
    return defaultAppConfig.language.availableLanguages.filter((l) => l == lang)[0]
  }

  static rehydrateFunction(): Observable<any> {
    const breadcrumbs = JSON.parse(localStorage.getItem('breadcrumbs'))
    if (breadcrumbs) {
      return of(setBreadcrumbs({ breadcrumbs }))
    } else {
      return of()
    }
  }
}
