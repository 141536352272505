<div class="uk-flex uk-flex-wrap uk-flex-wrap-between uk-flex-between@m">
  <div>
    <header>
      <h1 class="place-header__heading place-header__title">{{ title }}</h1>
      <p [innerHtml]="subTitle | sanitizeContent" class="place-header__subtitle"></p>
    </header>
    <img class="uk-animation-fade place-header__image" [src]="imageUrl" />
  </div>
  <div>
    <ng-content></ng-content>
  </div>
</div>
