import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'turismo-place-header',
  templateUrl: './place-header.component.html',
  styleUrls: ['./place-header.component.scss'],
})
export class PlaceHeaderComponent implements OnInit {
  @Input()
  imageUrl: string

  @Input()
  title: string

  @Input()
  subTitle: string

  constructor() {}

  ngOnInit(): void {}
}
