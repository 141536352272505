import { APP_BASE_HREF, Location, LocationStrategy, PathLocationStrategy } from '@angular/common'
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { NgGoogleAnalyticsService } from '@mycicero/ng-google-analytics'
import { Store } from '@ngrx/store'
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst'
import { map, shareReplay, takeUntil, tap } from 'rxjs/operators'
import { Observable } from 'rxjs'
// import { appConfig } from '../brands/_current/config/app-config'
import { Lang } from '@shared/web-api/models/lang.type'

import { AppRemoteConfig } from './core/models/remote-app-config.model'
import { AppState } from './core/store/models/app-state.model'
import { AppRemoteConfigState } from './core/store/models/remote-app-config-state.model'
import { AppRemoteConfigSelectors } from './core/store/selectors'
import { appLanguageChanged } from './core/store/actions/app-remote-config-effect.actions'
import { getSelectedLanguage } from './core/store/selectors/app-remote-config.selectors'
import { Breadcrumb } from './core/components/breadcrumb/breadcrumb.model'
import { appConfig } from 'src/brands/_current/config/app-config'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'turismo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss', './app.component.small.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly onDestroy$: EventEmitter<void> = new EventEmitter<void>()

  lang: Lang
  availableLanguages: Lang[]
  currentLang: string
  breadcrumbs$: Observable<Breadcrumb[]>

  appRemoteConfig: AppRemoteConfig
  appRemoteConfigIsLoading: boolean

  readonly appRemoteConfigIsLoading$: Observable<boolean> = this.store$
    .select(AppRemoteConfigSelectors.selectAppRemoteConfigIsLoading)
    .pipe(takeUntil(this.onDestroy$), shareReplay({ refCount: true, bufferSize: 1 }))

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    @Inject(APP_BASE_HREF) public baseHref: string,
    private location: Location,
    private ngGoogleAnalyticsService: NgGoogleAnalyticsService,
    private angulartics: Angulartics2GoogleGlobalSiteTag,
    private store$: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
  ) {
    this.lang = this.locale.split('-')[0].toLowerCase() as Lang
    // this.store$
    //   .select(getSelectedLanguage)
    //   .pipe(takeUntil(this.onDestroy$))
    //   .subscribe((val) => {
    //     this.currentLang = val
    //     console.log("currentLang", this.currentLang)

    //   })

    if (this.cdr.detach && this.cdr.detectChanges) {
      this.currentLang
      this.ngOnInit()
      // console.log('currentLangDetectChanges', this.currentLang)
    }
  }

  ngOnInit(): void {
    this.titleService.setTitle(appConfig.core.clientName)

    let favicon: HTMLLinkElement = document.querySelector('#appFavicon')
    favicon.href = appConfig.core.brandLogo

    if (!!appConfig.analytics.google?.trackingList?.length) {
      this.ngGoogleAnalyticsService.loadGtagScript().subscribe({
        complete: () => {
          this.angulartics.startTracking()
        },
      })
    }

    this.store$
      .select(getSelectedLanguage)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((val) => {
        this.currentLang = val
        // console.log('currentLangInit1', this.currentLang)
      })

    // console.log('currentLangInit2', this.currentLang, appConfig.core.brandName)

    this.availableLanguages = appConfig.language.availableLanguages.filter((l) => l !== this.currentLang)
    this.store$
      .select(AppRemoteConfigSelectors.selectAppRemoteConfigState)
      .pipe(
        map<AppRemoteConfigState, AppRemoteConfig>((config) => ({ ...config })),
        takeUntil(this.onDestroy$),
      )
      .subscribe((config) => {
        this.appRemoteConfig = config
        this.cdr.markForCheck()
      })
  }

  ngOnDestroy(): void {
    this.onDestroy$.emit()
    this.onDestroy$.complete()
  }

  onLanguageChange(selectedLanguage: Lang): void {
    this.availableLanguages = appConfig.language.availableLanguages.filter((lang) => lang !== selectedLanguage)

    let baseUrl = this.baseHref.split('/').filter((element) => !!element)
    baseUrl.pop() // Remove old language
    baseUrl.push(selectedLanguage) // Add new language

    const fullPath = [
      ...baseUrl,
      ...this.location
        .path()
        .split('/')
        .filter((element) => !!element),
    ].join('/')
    this.store$.dispatch(appLanguageChanged({ fullPath, language: selectedLanguage }))
  }
}
