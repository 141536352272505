import { PlaceFilterCategories } from './models/place-filters.models'
import { FairPlaceFiltersCategory } from './web-api/models/place.models'

export function getFilteredCategories(
  categories: PlaceFilterCategories,
  activeCategories: { [key: string]: boolean },
): FairPlaceFiltersCategory[] {
  let filteredCategories = []
  categories.forEach((category) => {
    let activeSubCat = []
    Object.keys(activeCategories[category.name]).forEach((subCategory) => {
      if (activeCategories[category.name][subCategory]) {
        activeSubCat.push(category.subCategories.filter((el) => el.name == subCategory)[0].id)
      }
    })
    if (activeSubCat.length > 0) {
      filteredCategories.push({ categoryId: category.id, subCategoryIds: activeSubCat })
    }
  })
  return filteredCategories
}

export function initializeFilters(categories: PlaceFilterCategories) {
  let filters = {}
  categories.forEach((category) => {
    let subCategories = {}
    category.subCategories.forEach((subCategory) => {
      subCategories[subCategory.name] = false
    })
    filters[category.name] = subCategories
  })
  return filters
}

export function initializeCats(filters: { [key: number]: any }, categories: PlaceFilterCategories) {
  let filter = {}
  categories.forEach((category) => {
    let subCategories = {}
    category.subCategories.forEach((subCategory) => {
      let values = Array.isArray(filters[category.id]) ? filters[category.id] : [filters[category.id]]
      if (!!filters[category.id] && Object.values(values).includes(subCategory.id.toString())) {
        subCategories[subCategory.name] = true
      } else {
        subCategories[subCategory.name] = false
      }
    })
    filter[category.name] = subCategories
  })
  return filter
}

export function formatHtml(text: string) {
  return text ? text.replace(/(<([^>]+)>)/gi, '') : ''
}
