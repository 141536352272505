<div class="downloads-container">
  <div *ngIf="downloads?.length > 0">
    <div class="downloads" *ngFor="let download of downloads">
      <div>
        <a class="downloads__image">
          <img class="document-icon" src="assets/images/icons/pdf.svg" alt="" *ngIf="download.url.includes('pdf')" />
          <img class="document-icon" src="assets/images/icons/word.svg" alt="" *ngIf="download.url.includes('docx')" />
          <img class="document-icon" src="assets/images/icons/excel.svg" alt="" *ngIf="download.url.includes('xlsx')" />
        </a>
      </div>
      <div class="downloads-info">
        <h5 class="downloads-info--title">{{ download.title }}</h5>
        <p class="downloads-info--date">
          <span i18n>Ultima modifica:</span>
          <span class="downloads-info--date-text">{{ download.uploadDateTime | date: 'dd/MM/YYYY' }}</span>
        </p>
      </div>
      <div class="actions">
        <a target="_blank" class="download-btn" [href]="download.url">
          <span i18n>Scarica</span>
          <img src="assets/images/icons/download.svg" aria-hidden="true" loading="lazy" />
        </a>
        <h5 class="file-size">{{ download.size }} MB</h5>
      </div>
    </div>
  </div>
</div>
