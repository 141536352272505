<div class="event-list-item-small" uk-width-auto>
  <div class="event-list-item-small__header" uk-width-auto [routerLink]="[eventsListPath, event.id, event.name]">
    <img class="event-list-item-small__header--image" uk-img [src]="event.image.url" [alt]="event.name" />
    <div class="event-list-item-small__header__content">
      <div class="event-list-item-small__header__content--row mt-auto">
        <h1 class="event-list-item-small__header__content--title">{{ event.name }}</h1>
      </div>
      <div class="event-list-item-small__header__content--row">
        <ng-container *ngIf="event.streamingUrl">
          <a target="_blank" [href]="event.streamingUrl" class="event-list-item-small__header__content--live">
            <i aria-hidden="true" class="icon" uk-icon="rss"></i>
            <span i18n>
              {{ event.end > currentDate ? 'LIVE' : "RIGUARDA L'EVENTO" }}
            </span>
          </a>
        </ng-container>

        <span class="event-list-item-small__header__content--live-text">{{ event.type }}</span>
      </div>
    </div>
  </div>
  <div class="event-list-item-small__body" uk-width-auto>
    <div class="event-list-item-small__body--row">
      <time class="event-list-item-small__body__date-time">
        <div *ngIf="event.begin.getDate() != event.end.getDate()" class="same-day">
          <!-- <span [routerLink]="[eventsListPath, event.id, event.name]"> -->
          <span>
            <!-- {{ event.begin | date: 'H:mm EEE d LLLL' }} -->
            {{ 'Dal ' + (event.begin | date: 'd LLLL') + ' al ' + (event.end | date: 'd LLLL') }}
          </span>
        </div>
        <div *ngIf="event.begin.getDate() == event.end.getDate()" class="same-day">
          <span> {{ event.begin | date: 'EEE d LLLL' }}</span
          ><br />
          <span>
            {{ getTime() }}
          </span>
        </div>
      </time>
    </div>

    <div>
      <ng-container *ngIf="event.end.getTime() < currentDate.getTime() && brandName === 'Tipicità experience'">
        <button class="event-list-item-small__body__event-state__item--reserve-btn disabled" i18n>Concluso</button>
      </ng-container>
    </div>

    <div class="event-list-item-small__body--row">
      <p *ngIf="event.address" class="event-list-item-small__body__place">
        <span *ngIf="event.isPoi" [style.font-weight]="700">{{ event.address.street?.name }}</span>
        <span *ngIf="!event.isPoi">{{ event.address.street?.name }}</span>
        <ng-container *ngIf="event.address.street && event.address.city"> - </ng-container>
        <span>{{ event.address.city?.name }}</span>
      </p>
    </div>

    <div class="event-list-item-small__body--row">
      <div *ngIf="event.guests?.length > 0" class="event-list-item-small__body__guests" i18n>
        <div>
          <span>con</span>
          <ng-container *ngFor="let participant of event.guests; last as isLast">
            <a routerLink="{{ basePath }}{{ participant.id }}" class="event-list-item-small__body__guests--author">
              <span>{{ participant.fullName }}</span>
              <span *ngIf="!isLast || participant.employment">,</span>
            </a>
            <span
              *ngIf="participant.employment"
              class="event-list-item-small__body__guests--employment"
              [innerHtml]="'&nbsp;' + participant.employment | sanitizeContent"
            ></span>
            <!-- <ng-container *ngIf="!isLast"> - </ng-container> -->
          </ng-container>
        </div>
      </div>
    </div>

    <div class="event-list-item-small__body--row">
      <div class="event-list-item-small__body__fee">
        <div class="event-list-item-small__body__fee--item">
          <div *ngIf="event.cost">
            <i
              class="uk-icon uk-icon-image"
              aria-hidden="true"
              style="background-image: url('assets/images/icons/tickets.svg'); width: 30px; margin-right: 5px"
            ></i>
            <span>
              {{ event.cost }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="event-list-item-small__body--row">
      <p class="event-list-item-small__body__description" [innerHtml]="event.description | sanitizeContent"></p>
    </div> -->

    <div class="event-list-item-small__body--row">
      <div class="event-list-item-small__body__event-state">
        <div class="event-list-item-small__body__event-state__item">
          <ng-container *ngIf="event.end.getTime() < currentDate.getTime(); else bookingTemplateRef">
            <button
              *ngIf="brandName !== 'Tipicità experience'"
              class="event-list-item-small__body__event-state__item--reserve-btn disabled"
              i18n
            >
              Concluso
            </button>
          </ng-container>

          <ng-template #bookingTemplateRef>
            <ng-container *ngIf="event.bookingUrl">
              <a
                target="_blank"
                [href]="event.bookingUrl"
                rel="external"
                class="uk-button uk-button-secondary uk-box-shadow-large uk-width-1-1 event-list-item-small__reservation-button"
                i18n
                >Prenota</a
              >
            </ng-container>

            <ng-container *ngIf="event.ticketingUrl">
              <a
                target="_blank"
                [href]="event.ticketingUrl"
                rel="external"
                class="uk-button uk-button-secondary uk-box-shadow-large uk-width-1-1 event-list-item-small__reservation-button"
                i18n
                >Biglietti</a
              >
            </ng-container>
          </ng-template>
        </div>
        <button
          class="event-list-item-small__body__fee--item--go-to-btn"
          [routerLink]="[eventsListPath, event.id, event.name]"
        >
          <span i18n *ngIf="brandName !== 'BIM' || (brandName === 'BIM' && !isExperience)">Vai al dettaglio</span>
          <span *ngIf="isExperience && brandName === 'BIM'" i18n>Scopri</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- <div class="event-list-item-small">
  <h1 class="event-list-item-small--title">
    <a class="uk-link-reset" [routerLink]="[eventsListPath, event.id]">{{ event.name }}</a>
  </h1>
  <time class="event-list-item-small__date-time">
    <a class="uk-link-reset" [routerLink]="[eventsListPath, event.id]">
      {{ event.begin | date: 'H:mm EEE d LLLL' }}
    </a>
  </time>
  <p *ngIf="event.address" class="uk-margin-remove-top event-list-item-small__place">
    <span>{{ event.address.street?.name }}</span>
    <ng-container *ngIf="event.address.street?.name && event.address.city?.name"> - </ng-container>
    <span>{{ event.address.city.name }}</span>
  </p>

 
  <div class="event-list-item-small__image" [routerLink]="[eventsListPath, event.id]">
    <span class="sm-title" style="display: none">{{ event.name }}</span>
    <img [src]="event.image.url" [alt]="event.name" />
  </div>

  <div class="uk-margin-small-top uk-margin-small-bottom event-list-item-small__badge-list">
    <ng-container *ngIf="event.streamingUrl">
      <a target="_blank" [href]="event.streamingUrl" class="uk-link-reset event-list-item-small__badge-active">
        <span i18n><i aria-hidden="true" uk-icon="rss"></i>
          {{ event.end > currentDate ? 'LIVE' : "RIGUARDA L'EVENTO" }}
        </span>
      </a>
    </ng-container>

    <span class="event-list-item-small__badge-list-event">{{ event.type }}</span>
  </div>

  <p class="event-list-item__description" [innerHtml]="event.description | sanitizeContent"></p>

  <p *ngIf="event.guests?.length > 0" class="uk-margin-remove event-list-item-small__footer" i18n>
    con
    <ng-container *ngFor="let participant of event.guests; last as isLast">
      <a routerLink="{{ basePath }}{{ participant.id }}"><span class="event-list-item-small__author">{{ participant.fullName }},</span></a>
      <span class="event-list-item-small__employment" [innerHtml]="participant.employment | sanitizeContent"></span>
      <ng-container *ngIf="!isLast"> - </ng-container>
    </ng-container>
  </p>

  <div class="uk-flex uk-flex-between uk-flex-right uk-margin-small-top uk-margin-small-bottom">
    <span *ngIf="event.cost" class="event-list-item-small__fee"><i
         class="uk-icon uk-icon-image"
         aria-hidden="true"
         style="background-image: url('assets/images/icons/tickets.svg'); width: 26px; margin-right: 5px"></i>
      {{ event.cost }}</span>
    <a [routerLink]="[eventsListPath, event.id]"><i uk-icon="arrow-right"></i></a>
  </div>

  <ng-container *ngIf="event.end.getTime() < currentDate.getTime(); else bookingTemplateRef">
    <button class="uk-button uk-box-shadow-large uk-width-1-1 event-list-item-small__reservation-button" disabled i18n>
      CONCLUSO
    </button>
  </ng-container>

  <ng-template #bookingTemplateRef>
    <ng-container *ngIf="event.bookingUrl">
      <a
         target="_blank"
         [href]="event.bookingUrl"
         rel="external"
         class="uk-button uk-button-secondary uk-box-shadow-large uk-width-1-1 event-list-item-small__reservation-button"
         i18n>PRENOTA</a>
    </ng-container>

    <ng-container *ngIf="event.ticketingUrl">
      <a
         target="_blank"
         [href]="event.ticketingUrl"
         rel="external"
         class="uk-button uk-button-secondary uk-box-shadow-large uk-width-1-1 event-list-item-small__reservation-button"
         i18n>BIGLIETTI</a>
    </ng-container>
  </ng-template>
</div> -->
