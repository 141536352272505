<div class="filters-section">
  <ng-container *ngIf="enabledSections.filters.searchField">
    <turismo-input-search
      i18n-placeholder
      [(text)]="filters.query"
      (textChange)="onNgModelChange($event)"
      [active]="isLoading"
      placeholder="Cerca ospite"
    ></turismo-input-search>
  </ng-container>
  <ng-container *ngIf="enabledSections.filters.byTypologies">
    <div [style.position]="'relative'">
      <button class="filters-section__btn" tabindex="0" type="button">
        <span class="filters-section__btn--label" *ngIf="filters?.category">{{ filters?.category.name }}</span>
        <span class="filters-section__btn--label" *ngIf="!filters?.category" i18n>Tutte le tipologie</span>
        <div class="dropdown-btn--icon">
          <img [src]="'assets/images/icons/bold-chevron-down.svg'" alt="" uk-icon="ratio: 2" />
        </div>
      </button>
      <div #categoriesDropdownRef class="dropdown-menu" [attr.uk-dropdown]="dropdownOptions" [style.margin-top.px]="0">
        <ul class="uk-nav uk-dropdown-nav dropdown-menu__list">
          <li (click)="onAllCategoriesClick()" class="dropdown-menu__list--item-header" i18n>Tutte le tipologie</li>
          <ng-container *ngIf="isLoading; else guestCategoriesTemplateRef">
            <li>
              <ngx-skeleton-loader></ngx-skeleton-loader>
            </li>
          </ng-container>

          <ng-template #guestCategoriesTemplateRef>
            <ng-container *ngIf="guestCategories?.length > 0">
              <li
                (click)="onCategoryClick(guestCategory)"
                *ngFor="let guestCategory of guestCategories"
                class="dropdown-menu__list--item"
                tabindex="0"
              >
                {{ guestCategory.name }}
              </li>
            </ng-container>
          </ng-template>
        </ul>
      </div>
    </div>
  </ng-container>
</div>
