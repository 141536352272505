import { Pipe, PipeTransform } from '@angular/core'
import { Lang } from '@shared/web-api/models/lang.type'

@Pipe({
  name: 'languageIcon',
})
export class LanguageIconPipe implements PipeTransform {
  transform(value: Lang): string {
    switch (value) {
      case 'en':
        return 'assets/images/languages/united-kingdom.svg'
      case 'it':
        return 'assets/images/languages/italy.svg'
      case 'de':
        return 'assets/images/languages/de.svg'
      case 'esp':
        return 'assets/images/languages/es.svg'
      case 'fr':
        return 'assets/images/languages/fr.svg'
    }
  }
}
