<div class="event-default-list">
  <div class="event-default-list__item">
    <div class="block">
      <div class="block__row">
        <ng-container *ngIf="!isExperience">
          <time *ngIf="showTime" class="block__row__time">{{ event.begin | date: 'H:mm' }}</time>
          <time *ngIf="showEndDate" class="block__row__date">{{ event.end | date: 'EEE d LLLL' }}</time>

          <time *ngIf="!showTime" class="block__row__date2">{{ event.begin | date: 'EEE d' }}</time>

          <span *ngIf="event.multiDates" class="block__row__other-dates" i18n>Altre date disponibili</span>
        </ng-container>
      </div>

      <div class="block__row">
        <ng-container *ngIf="isExperience">
          <div *ngIf="event.begin.getDate() == event.end.getDate()" class="datetime-item">
            <time class="block__row__experience-date">
              {{ event.begin | date: 'EEE d LLLL' }}
            </time>
            <time class="block__row__experience-time">
              {{ getTime() }}
            </time>
          </div>
          <div *ngIf="event.begin.getDate() != event.end.getDate()">
            <time class="block__row__experience__date">
              {{ 'Dal ' + (event.begin | date: 'd LLLL') + ' Al ' + (event.end | date: 'd LLLL') }}
            </time>
          </div>
        </ng-container>
      </div>

      <div class="block__row">
        <ng-container *ngIf="isExperience">
          <div class="block__row__place">
            <ng-container *ngIf="event.address">
              <span class="block__row__place--time" *ngIf="!showTime">Ore {{ event.begin | date: 'H:mm' }}</span>
              <ng-container *ngIf="!event.isPoi"
                ><span>{{ event.address.street?.name }}</span></ng-container
              >
              <ng-container *ngIf="event.isPoi">
                <span class="block__row__place__isBold">{{ event.address.street?.name }}</span>
                <!-- <a routerLink="../../../pois/{{ event.poiId }}" class="block__row__place">{{ event.address.street?.name }}</a> -->
              </ng-container>
              <span *ngIf="showCity">{{ event.address.city?.name }} </span>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <div class="block__row">
        <ng-container *ngIf="!isExperience">
          <div *ngIf="event.address" class="block__row__place">
            <span class="block__row__place--time" *ngIf="!showTime">Ore {{ event.begin | date: 'H:mm' }}</span>
            <span *ngIf="!event.isPoi">{{ event.address.street?.name }}</span>
            <span *ngIf="event.isPoi">
              <a routerLink="../../../pois/{{ event.poiId }}">{{ event.address.street?.name }}</a>
            </span>
            <span *ngIf="showCity">{{ event.address.city?.name }}</span>
          </div>
        </ng-container>
      </div>

      <div class="block__row position-bottom">
        <span *ngIf="event.cost" class="block__row__fee"
          ><i
            class="uk-icon uk-icon-image"
            aria-hidden="true"
            style="background-image: url('assets/images/icons/tickets.svg'); width: 26px; margin-right: 5px"
          ></i>
          {{ event.cost }}</span
        >

        <ng-container *ngIf="event.end.getTime() < currentDate.getTime(); else bookingTemplateRef">
          <button class="block__row__fee__reservation-btn disabled" i18n>Concluso</button>
        </ng-container>

        <ng-template #bookingTemplateRef>
          <ng-container *ngIf="event.bookingUrl">
            <a target="_blank" [href]="event.bookingUrl" rel="external" class="block__row__fee__reservation-btn" i18n
              >Prenota</a
            >
          </ng-container>

          <ng-container *ngIf="event.ticketingUrl">
            <a target="_blank" [href]="event.ticketingUrl" rel="external" class="block__row__fee__reservation-btn" i18n
              >Biglietti</a
            >
          </ng-container>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="event-default-list__item" [style.padding-left.rem]="'1.875'" [style.padding-right.rem]="'1.875'">
    <div class="block">
      <div class="block__row">
        <h1 class="block__row__title">
          <a class="uk-link-reset" [routerLink]="[eventsListPath, event.id, event.name]">{{ event.name }}</a>
        </h1>
      </div>

      <div class="block__row">
        <div class="block__row__badge-list">
          <ng-container *ngIf="event.streamingUrl">
            <a target="_blank" [href]="event.streamingUrl" class="block__row__badge-list--active">
              <i aria-hidden="true" uk-icon="rss"></i>
              <span i18n>
                {{ event.end > currentDate ? 'LIVE' : "RIGUARDA L'EVENTO" }}
              </span>
            </a>
          </ng-container>
          <span class="block__row__badge-list--type">{{ event.type }}</span>
        </div>
      </div>

      <div class="block__row">
        <a class="block__row__description">
          <p [innerHtml]="event.description | sanitizeContent"></p>
        </a>
      </div>

      <div class="block__row" [style.margin-top]="'auto'">
        <div class="block__row__footer">
          <p class="block__row__footer--author">
            <ng-container *ngIf="event.guests?.length > 0">
              <span class="with-text">
                <ng-container i18n>con </ng-container>
              </span>
              <ng-container *ngFor="let participant of event.guests; last as isLast">
                <a class="block__row__footer--author--name" routerLink="{{ basePath }}{{ participant.id }}">
                  <span>{{ participant.fullName }}</span>
                </a>
                <span
                  class="event-list-item__employment"
                  *ngIf="!!participant.employment"
                  [innerHtml]="', ' + participant.employment | sanitizeContent"
                ></span>
                <ng-container *ngIf="!isLast"> - </ng-container>
              </ng-container>
            </ng-container>
          </p>
          <a
            [routerLink]="[eventsListPath, event.id, event.name]"
            (click)="linkClicked(event.id, event.name)"
            class="block__row__footer--action-btn"
          >
            <span i18n *ngIf="brandName !== 'BIM' || (brandName === 'BIM' && !isExperience)">Vai al dettaglio</span>
            <span *ngIf="isExperience && brandName === 'BIM'" i18n>Scopri</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="event-default-list__item">
    <a
      [routerLink]="[eventsListPath, event.id, event.name]"
      (click)="linkClicked(event.id, event.name)"
      class="image uk-height-medium uk-flex uk-flex-center uk-flex-middle uk-background-cover uk-light"
    >
      <img [src]="event.image.url" [alt]="event.name" data-sizes="(min-width: 650px) 650px, 100vw" uk-img />
    </a>
  </div>
</div>
