import { ErrorHandler, Injectable } from '@angular/core'
import UIkit from 'uikit'

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: Error): void {
    console.log('From GlobalErrorHandler:', error)

    UIkit.notification({
      message: "Errore durante l'elaborazione dei  dati",
      pos: 'top-right',
      status: 'danger',
    })
  }
}
