import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { BehaviorSubject, Observable, throwError } from 'rxjs'
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators'
import {
  ActiveFilters,
  PlaceFilterForm,
  ORDER_BY_DISTANCE,
  ORDER_BY_NAME,
  ORDER_BY_RELEVANCE,
  OrderBy,
  ORDER_BY_START_DATE,
} from './place-filter-form'
import { FormControl, FormGroup } from '@ng-stack/forms'
import { AppState } from '@app/core/store/models/app-state.model'
import { Store } from '@ngrx/store'
import { GeocodeService, Suggestion } from './geocode.service'

@Component({
  selector: 'turismo-place-filters-order-by',
  templateUrl: './place-filters-order-by.component.html',
  styleUrls: ['../styles/place-filters-common.scss', './place-filters-order-by.component.scss'],
  providers: [GeocodeService],
})
export class PlaceFiltersOrderByComponent implements OnInit, OnDestroy {
  private readonly onDestroy$: EventEmitter<void> = new EventEmitter<void>()

  readonly form: FormGroup<PlaceFilterForm> = new FormGroup<PlaceFilterForm>({
    orderBy: new FormControl(ORDER_BY_RELEVANCE),
    query: new FormControl(),
    coordinates: new FormControl(),
  })

  readonly suggestions$: Observable<Suggestion[]>

  @Input() OrderByStartDate: boolean

  isDropdownVisible: boolean
  displayFooter: boolean = false

  private _showSuggestionsList$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  private _suggestionsAreLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  startingPoint: string
  showStartingPoint: boolean = false

  get showSuggestionsList$(): Observable<boolean> {
    return this._showSuggestionsList$.asObservable()
  }

  get suggestionsAreLoading$(): Observable<boolean> {
    return this._suggestionsAreLoading$.asObservable()
  }

  get orderByName(): string {
    return ORDER_BY_NAME
  }

  get orderByRelevance(): string {
    return ORDER_BY_RELEVANCE
  }

  get orderByDistance(): string {
    return ORDER_BY_DISTANCE
  }

  get orderByStartDate(): string {
    return ORDER_BY_START_DATE
  }

  get orderBy(): string {
    return this.form.controls.orderBy.value
  }

  @Input()
  get activeFilters(): ActiveFilters {
    const { orderBy, coordinates } = this.form.value
    return {
      orderBy,
      coordinates,
    }
  }

  @Input()
  activeOrder: string

  @Input()
  activeDistance: string

  @Input()
  isMobileView: boolean

  set activeFilters(value: ActiveFilters | null | undefined) {
    if (!!value && value.orderBy !== this.form.value.orderBy) {
      this.form.patchValue(value)
    }
  }

  @Output()
  orderByFiltersChanged: EventEmitter<ActiveFilters> = new EventEmitter<ActiveFilters>()

  constructor(private cdr: ChangeDetectorRef, private geocodeService: GeocodeService, private store$: Store<AppState>) {
    this.suggestions$ = this.form.controls.query.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      filter((query) => !!query),
      tap((query) => {
        this._showSuggestionsList$.next(true)
        this._suggestionsAreLoading$.next(true)
      }),
      switchMap((query) =>
        this.geocodeService.geocode(query).pipe(
          tap(() => this._suggestionsAreLoading$.next(false)),
          catchError((err) => {
            this._suggestionsAreLoading$.next(false)
            return throwError(err)
          }),
        ),
      ),
      takeUntil(this.onDestroy$),
    )
  }

  setActiveFilters(value: string) {
    if (!!value && value !== this.form.value.orderBy) {
      this.form.patchValue({ orderBy: value })
    }
  }

  ngOnInit(): void {
    this.setActiveFilters(this.activeOrder)

    if (this.activeDistance) {
      this.form.patchValue({
        query: this.activeDistance,
      })
    }

    this.isDropdownVisible = this.isMobileView
    this.form.controls.orderBy.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
      this.activeOrder = value
      this.showStartingPoint = false
      if (value === this.orderByDistance && !this.isMobileView) {
        this.displayFooter = true
      } else {
        // At this point the form is not updated yes so will give the old value
        this.orderByFiltersChanged.emit({ orderBy: value, coordinates: null, distance: this.startingPoint })
      }
      if (value !== this.orderByDistance && !this.isMobileView) {
        this.displayFooter = false
        this.toggleDropdown()
      }
    })
  }

  ngOnDestroy(): void {
    this.onDestroy$.emit()
    this.onDestroy$.complete()
  }

  toggleDropdown(): void {
    this.isDropdownVisible = !this.isDropdownVisible
  }

  applyFiltersClick(): void {
    this.isDropdownVisible = false
    this.showStartingPoint = true
    this.orderByFiltersChanged.emit({ ...this.activeFilters, distance: this.startingPoint })
  }

  onAddressClick(addressId: string, addressName: string): void {
    this.startingPoint = addressName
    this.form.controls.query.patchValue(addressName, { emitEvent: false, onlySelf: true })

    this._suggestionsAreLoading$.next(true)

    this.geocodeService
      .getAddressCoordinates(addressId, addressName)
      .pipe(map((candidates) => candidates[0]?.location))
      .subscribe((locationCandidate) => {
        if (!!locationCandidate) {
          this.form.controls.coordinates.patchValue({
            lng: locationCandidate.x,
            lat: locationCandidate.y,
          })
          if (this.isMobileView) {
            this.orderByFiltersChanged.emit({
              orderBy: ORDER_BY_DISTANCE,
              coordinates: this.form.controls.coordinates.value,
              distance: this.startingPoint,
            })
          }
        }
        this._suggestionsAreLoading$.next(false)
        this._showSuggestionsList$.next(false)
      })
  }
}
