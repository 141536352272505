import { Injectable } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Observable } from 'rxjs'
import { appConfig } from '../../../brands/_current/config/app-config'

@Injectable()
export class TurismoWebApiInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clonedReq = req.clone({
      url:
        req.url.startsWith('https://') || req.url.startsWith('http://')
          ? req.url
          : `${appConfig.api.baseUrl}${req.url}`,
    })

    return next.handle(clonedReq)
  }
}
