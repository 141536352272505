<div class="place-filters">
  <div class="place-filters__align-left">
    <div class="place-filters__align-left__block d-grid">
      <div class="grid-item">
        <turismo-input-search
          *ngIf="!isMobileView"
          [text]="query"
          (textChange)="onNgModelChange($event)"
          i18n-placeholder
          [placeholder]="searchInputText"
        ></turismo-input-search>
      </div>

      <div id="categoryFilters" class="grid-item" *ngIf="!!categories && categories.length > 0">
        <turismo-place-filters-types
          #filterTypesCmp
          [categories]="categories"
          [categoriesStatus]="categoriesStatus"
          [activeFilters]="activeFilters"
          [activeFiltersCount]="activeFiltersCount"
          [isMobileView]="isMobileView"
          [isDropdownVisible]="isCatDropdownOpen"
          (categoryFiltersChanged)="categoryFiltersChange($event)"
        >
        </turismo-place-filters-types>
      </div>

      <ng-container>
        <div id="orderFilters" class="grid-item" *ngIf="hasOrderFilters">
          <turismo-place-filters-order-by
            (orderByFiltersChanged)="orderByFiltersChange($event)"
            [activeOrder]="activeOrderClause"
            [activeDistance]="activeDistance"
            [isMobileView]="isMobileView"
            [OrderByStartDate]="orderByStartDate"
          >
          </turismo-place-filters-order-by>
        </div>
      </ng-container>
    </div>

    <div *ngIf="isMobileView" class="place-filters__apply">
      <div class="place-filters__apply-float">
        <button (click)="applyAllFilters()" class="place-filters__apply-float-btn" type="button" i18n>
          Applica filtri
          <ng-container *ngIf="activeFiltersCount > 0"> ({{ activeFiltersCount }})</ng-container>
        </button>
      </div>
    </div>

    <!-- <button class="place-filters__dropdown-button disabled-btn" type="button" *ngIf="!isMobileView && hasListModality">
      <div class="place-filters__dropdown-button--container">
        <img
             src="assets/images/icons/disabled-filters-list.svg"
             aria-hidden="true"
             class="place-filters-order-by__dropdown-icon list-icon" />
        <ng-container i18n>Visualizza lista</ng-container>
        <div style="width: 14px; height: auto; box-sizing: border-box; margin-left: 0.75rem">
          !-- <img
            src="assets/images/icons/chevron-down.svg"
            aria-hidden="true"
            class="place-filters-order-by__dropdown-icon"
          /> --
        </div>
      </div>
    </button> -->
  </div>
</div>

<div *ngIf="activeFilters && !isMobileView" class="place-active-filters">
  <div *ngFor="let previewItem of activeCategoriesPreview | slice: 0:10" class="place-active-filters__chip visible-cat">
    {{ previewItem.subCategory }}
    <img
      (click)="onCloseClick(previewItem.category, previewItem.subCategory)"
      src="assets/images/icons/close.svg"
      class="place-active-filters__chip-icon"
      role="button"
      tabindex="0"
      aria-hidden="true"
      aria-label="close"
    />
  </div>
  <div
    class="place-active-filters__chip show-more"
    (click)="openCatDropdown()"
    [class.visible-cat]="activeFiltersCount > 10"
    i18n
  >
    Altro...
  </div>
</div>
