import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Breadcrumb } from '@app/core/components/breadcrumb/breadcrumb.model'
import { setBreadcrumbs } from '@app/core/store/actions/app-remote-config-effect.actions'
import { AppState } from '@app/core/store/models/app-state.model'
import { Store } from '@ngrx/store'
import { EventListItemBase } from '@shared/components/event-list-item/event-list-item-base'
import * as moment from 'moment'
import { appConfig } from 'src/brands/_current/config/app-config'
import { Event } from '../../models/event.model'

@Component({
  selector: 'turismo-event-list-item',
  templateUrl: './event-list-item.component.html',
  styleUrls: ['./event-list-item.component.scss'],
})
export class EventListItemComponent extends EventListItemBase {
  @Input()
  event: Event

  @Input()
  basePath: string = '../'

  @Input()
  eventsListPath: string = './'

  @Input()
  showCity: boolean = true

  @Input()
  showEndDate: boolean = true

  @Input()
  showTime: boolean = true

  @Input()
  isExperience: boolean = false

  @Output()
  onLinkClick: EventEmitter<{ id: number; name: string }> = new EventEmitter<{ id: number; name: string }>()

  brandName: string
  addressName: string
  street: string

  readonly currentDate = new Date()

  constructor(private store$: Store<AppState>) {
    super()
    this.brandName = appConfig.core.brandName
  }
  ngOnInit(): void {
    // console.log("list data", this.event.address,this.isExperience)
  }

  getTime() {
    let startTime = moment(this.event.begin).format('H:mm')
    let endTime = moment(this.event.end).format('H:mm')

    if (this.event.begin.getHours() > 0 && this.event.end.getHours() > 0) {
      return `Dalle ${startTime} alle ${endTime}`
    } else if (startTime != '00:00' && !endTime) {
      return `Dalle ${startTime}`
    } else {
      return ''
    }
  }

  linkClicked(id: number, name: string) {
    this.onLinkClick.emit({ id, name })
  }
}
