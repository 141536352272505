<div class="footer">
  <div class="footer__container uk-container">
    <div class="footer__container--column">
      <img *ngIf="!!logoUrl" class="footer__logo" [src]="logoUrl" alt="Logo monocolore Tipicità" />

      <ng-container *ngIf="!!contacts?.email || !!contacts?.phoneNumber">
        <div class="footer__contact">
          <h6 class="footer__contact-title" i18n>Contatti</h6>
          <ul class="footer__list">
            <li *ngIf="!!contacts?.email">
              <a target="_blank" href="mailto:{{ contacts.email }}">{{ contacts.email }}</a>
            </li>
            <li *ngIf="!!contacts?.phoneNumber">
              <a target="_blank" href="tel:{{ contacts.phoneNumber }}">{{ contacts.phoneNumber }}</a>
            </li>
          </ul>
        </div>
      </ng-container>
    </div>

    <div class="footer__container--column">
      <!--        <h6 class="uk-text-bold">Per il consumatore</h6>-->
      <!--        <ul class="uk-list">-->
      <!--          <li><a class="footer__menu-item-link" href="">Esperienze</a></li>-->
      <!--          <li><a class="footer__menu-item-link" href="">Passioni</a></li>-->
      <!--          <li><a class="footer__menu-item-link" href="">Protagonisti</a></li>-->
      <!--          <li><a class="footer__menu-item-link" href="">Mondo Tipicità</a></li>-->
      <!--          <li><a class="footer__menu-item-link" href="">Area personale</a></li>-->
      <!--        </ul>-->
    </div>
    <div class="footer__container--column">
      <!--        <h6 class="uk-text-bold">Per le aziende</h6>-->
      <!--        <ul class="uk-list">-->
      <!--          <li><a class="footer__menu-item-link" href="">Crea vetrina</a></li>-->
      <!--          <li><a class="footer__menu-item-link" href="">Spesa a domicilio</a></li>-->
      <!--          <li><a class="footer__menu-item-link" href="">Area privata</a></li>-->
      <!--        </ul>-->
    </div>

    <div class="footer__container--column footer__container--social-column">
      <ng-container *ngIf="!!appStores?.googlePlayStoreAppUrl || !!appStores?.appleStoreAppUrl">
        <h6 class="footer__contact-title text-sm-center" i18n>Scarica l'app</h6>
        <div class="uk-child-width-1-2 uk-grid-small uk-margin-medium-bottom footer-images app-store-container" uk-grid>
          <div *ngIf="!!appStores?.appleStoreAppUrl">
            <a target="_blank" [href]="appStores.appleStoreAppUrl"><img src="assets/images/appstore.png" alt="Apple App Store" /></a>
          </div>
          <div *ngIf="!!appStores?.googlePlayStoreAppUrl">
            <a target="_blank" [href]="appStores.googlePlayStoreAppUrl"><img src="assets/images/googleplay.png" alt="Google Play Store" /></a>
          </div>
        </div>
      </ng-container>
      <div class="folow-us-section">
        <h4 class="share-title" i18n>Seguici sui social</h4>
        <ul class="uk-subnav">
          <li *ngIf="!!contacts?.social?.facebookUrl">
            <a [href]="contacts.social.facebookUrl" target="_blank">
              <i class="uk-icon-button footer__social-icon" aria-hidden="true" uk-icon="facebook"></i>
            </a>
          </li>
          <li *ngIf="!!contacts?.social?.instagramUrl">
            <a [href]="contacts.social.instagramUrl" target="_blank">
              <i class="uk-icon-button footer__social-icon" aria-hidden="true" uk-icon="instagram"></i>
            </a>
          </li>
          <li *ngIf="!!contacts?.social?.twitterUrl">
            <a [href]="contacts.social.twitterUrl" target="_blank">
              <i class="uk-icon-button footer__social-icon" aria-hidden="true" uk-icon="twitter"></i>
              <span style="display: none">Email</span>
            </a>
          </li>
          <li *ngIf="!!contacts?.social?.linkedinUrl">
            <a [href]="contacts.social.linkedinUrl" target="_blank">
              <i class="uk-icon-button footer__social-icon" aria-hidden="true" uk-icon="linkedin"></i>

              <span style="display: none">Email</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="footer__copyright">
    <ng-container *ngIf="!!copyright">
      <p class="footer__copyright--text">{{ copyright }}</p>
    </ng-container>
  </div>
</div>