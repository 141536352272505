<div class="uk-position-relative uk-container uk-container-xsmall slideshow-container" uk-slideshow="min-height: 230">
  <ul class="uk-slideshow-items">
    <li *ngFor="let galleryItem of images">
      <a
        style="
          color: transparent;
          display: block;
          min-height: 200px;
          background-size: cover;
          background-repeat: no-repeat;
          height: 100%;
        "
        style="background-image: url({{ galleryItem.url }})"
      ></a>
    </li>
  </ul>

  <div class="uk-flex uk-flex-between uk-flex-center slideshow-nav actions">
    <a href="#" uk-slideshow-item="previous" class="sm-arrow">
      <img src="assets/images/icons/slider-right-arrow.svg" style="transform: rotate(180deg)" />
    </a>

    <ul class="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul>

    <a href="#" uk-slideshow-item="next" class="sm-arrow">
      <img src="assets/images/icons/slider-right-arrow.svg" />
    </a>
  </div>
</div>
