import { DOCUMENT, ViewportScroller } from '@angular/common'
import { Component, EventEmitter, Inject, Input, OnInit, Output, Renderer2 } from '@angular/core'

@Component({
  selector: 'turismo-small-place-filters',
  templateUrl: './small-place-filters.component.html',
  styleUrls: ['./small-place-filters.component.scss'],
})
export class SmallPlaceFiltersComponent implements OnInit {
  @Input()
  isLoading: boolean = false

  @Input()
  activeMenu: boolean = false

  @Output()
  filtersClosed: EventEmitter<void> = new EventEmitter<void>()

  constructor(
    private renderer: Renderer2,
    private viewportScroller: ViewportScroller,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'hide-overflow')
  }

  onToggleMenu() {
    this.activeMenu = !this.activeMenu
    this.renderer.removeClass(this.document.body, 'hide-overflow')
    this.filtersClosed.emit()
    this.viewportScroller.scrollToPosition([0, 0])
  }
}
