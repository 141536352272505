import { NgModule } from '@angular/core'
import { CommonModule, DatePipe } from '@angular/common'
import { RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

//Pages import
import {
  CollaboratorsImageComponent,
  EventListItemComponent,
  EventListItemSmallComponent,
  FilterDropdownSelectionComponent,
  InputSearchComponent,
  PageNotFoundComponent,
  PaginationComponent,
  DownloadsListComponent,
} from './components'

import { EventDateTimeDirective } from './directives/event-date-time.directive'
import { SliderCarouselComponent } from '@app/features/fair/components/slider-carousel/slider-carousel.component'
import { SanitizeContentModule } from './pipes/sanitize-content'
import { EventDatesDirective } from './directives/event-dates.directive'
import { PlaceFiltersComponent } from './components/place-filters/place-filters.component'
import { PlaceFiltersOrderByComponent } from './components/place-filters/order-by/place-filters-order-by.component'
import { PlaceFiltersTypesComponent } from './components/place-filters/types/place-filters-types.component'
import { ExhibitorFiltersComponent } from '@app/features/fair/components/exhibitor-filters/exhibitor-filters.component'
import { FairGuestsFiltersComponent } from '@app/features/fair/components/fair-guests-filters/fair-guests-filters.component'
import { EntitiesHeaderComponent } from './components/entities-header/entities-header.component'
import { SmallPlaceFiltersComponent } from './components/small-place-filters/small-place-filters.component'
import { OrderByPlacePipe } from '@app/features/fair/components/filters/order-by/order-by-place.pipe'
import { PlaceHeaderComponent } from '@app/features/place/components/place-header/place-header.component'
import { NoResultsFoundComponent } from './components/no-results-found/no-results-found.component'
import { SinglePhotoGalleryComponent } from './components/single-photo-gallery/single-photo-gallery.component'
import { DownloadsFiltersComponent } from './components/downloads-filters/downloads-filters.component'
import { SocialShareComponent } from './components/social-share/social-share.component'

const sharedComponents = [
  PageNotFoundComponent,
  InputSearchComponent,
  EventListItemSmallComponent,
  EventListItemComponent,
  PaginationComponent,
  CollaboratorsImageComponent,
  EventDateTimeDirective,
  EventDatesDirective,
  DownloadsListComponent,
  FairGuestsFiltersComponent,
  ExhibitorFiltersComponent,
  PlaceFiltersComponent,
  PlaceFiltersTypesComponent,
  PlaceFiltersOrderByComponent,
  EntitiesHeaderComponent,
  SmallPlaceFiltersComponent,
  PlaceHeaderComponent,
  NoResultsFoundComponent,
  SinglePhotoGalleryComponent,
  DownloadsFiltersComponent,
  SocialShareComponent,
]

const pipes = [OrderByPlacePipe]

@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, SanitizeContentModule],
  declarations: [
    ...sharedComponents,
    FilterDropdownSelectionComponent,
    SliderCarouselComponent,
    DownloadsListComponent,
    EntitiesHeaderComponent,
    SmallPlaceFiltersComponent,
    ...pipes,
    NoResultsFoundComponent,
    SinglePhotoGalleryComponent,
    DownloadsFiltersComponent,
    SocialShareComponent,
  ],
  exports: [
    ...sharedComponents,
    ...pipes,
    PaginationComponent,
    FilterDropdownSelectionComponent,
    SliderCarouselComponent,
  ],
  providers: [DatePipe],
})
export class SharedModule {
  // Rimosso perché creava problemi con il routing dell'intera applicazione
  // static forRoot(): ModuleWithProviders<SharedModule> {
  //   return {
  //     ngModule: SharedModule,
  //   }
  // }
}
