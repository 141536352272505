import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import {
  BreadcrumbComponent,
  FooterComponent,
  HeaderComponent,
  LanguageDropdownComponent,
  NavigationComponent,
} from '@app/core/components'
import { customAppInit } from '@app/core/custom-app-initializer'
import { GlobalErrorHandler } from '@app/core/errors/global-error-handler'
import { HttpErrorInterceptor } from '@app/core/errors/http-error.interceptor'
import { LanguageIconPipe, LanguagePipe } from '@app/core/pipes'
import { GlobalLoadingService } from '@app/core/services/global-loading.service'
import { NgGoogleAnalyticsModule } from '@mycicero/ng-google-analytics'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { Angulartics2Module } from 'angulartics2'
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'
import { appConfig } from '../../brands/_current/config/app-config'
import { AppRemoteConfigEffects } from './store/effects'
import { AppStateReducer } from './store/reducers'

// Core pipes
const pipes = [LanguagePipe, LanguageIconPipe]

// Core components
const components = [
  HeaderComponent,
  LanguageDropdownComponent,
  BreadcrumbComponent,
  FooterComponent,
  NavigationComponent,
]

// Effects providers
const ngRxAppEffects = [AppRemoteConfigEffects]

// NgRx
const ngRxImports = [
  StoreModule.forRoot(AppStateReducer.reducer),
  StoreDevtoolsModule.instrument({
    name: 'Tipicità NgRx',
  }),
  EffectsModule.forRoot(ngRxAppEffects),
]

const analytics = [
  NgGoogleAnalyticsModule.forRoot(
    appConfig.analytics?.google?.trackingList.map((tracking) => ({ trackingId: tracking.id })) || [],
  ),
  Angulartics2Module.forRoot({
    developerMode: false,
    gst: {
      trackingIds: appConfig.analytics?.google?.trackingList.map((tracking) => tracking.id) || [],
      anonymizeIp: appConfig.analytics?.google?.anonymizeIp ?? true,
    },
  }),
]

const errorProviders = [
  { provide: ErrorHandler, userClass: GlobalErrorHandler },
  { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
]

@NgModule({
  imports: [CommonModule, HttpClientModule, RouterModule, ...ngRxImports, ...analytics, NgxSkeletonLoaderModule],
  declarations: [...pipes, ...components],
  exports: [...components],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: APP_INITIALIZER, useFactory: customAppInit, multi: true },
        ...errorProviders,
        GlobalLoadingService,
        ...ngRxAppEffects,
      ],
    }
  }
}
