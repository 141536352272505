<ng-container *ngIf="!isMobile; else mobile">
  <div class="dropdown">
    <button class="dropdown-btn" type="button">
      <span class="dropdown-btn--label">
        {{ selectedItem ? selectedItem.label : selectAllLabel }}
      </span>
      <div class="dropdown-btn--icon">
        <img [src]="dropdownIcon.arrowDown" alt="" uk-icon="ratio: 2" />
      </div>
    </button>

    <div #dropdownListRef class="dropdown-menu uk-overflow-hidden" [attr.uk-dropdown]="dropdownOptions">
      <ul class="dropdown-menu__list">
        <li tabindex="0" class="dropdown-menu__list--item-header">
          <a role="button" (click)="onItemSelected()" class="uk-link-reset">{{ selectAllLabel }}</a>
        </li>
        <li
          *ngFor="let item of items"
          class="dropdown-menu__list--item"
          tabindex="0"
          (keyup.enter)="onItemSelected(item)"
          (click)="onItemSelected(item)"
          role="option"
        >
          <span>{{ item.label }}</span>
        </li>
      </ul>
    </div>
  </div>
</ng-container>

<ng-template #mobile>
  <div class="mobile-filter">
    <div class="mobile-filter__header">
      <span class="mobile-filter__header--label">
        {{ selectedItem ? selectedItem.label : selectAllLabel }}
      </span>
      <div class="mobile-filter__header--icon" (click)="toggleElement(toggleOption)">
        <img [src]="isShowDiv ? dropdownIcon.arrowUp : dropdownIcon.arrowDown" alt="" uk-icon="ratio: 2" />
      </div>
    </div>
    <div class="mobile-filter__body" [class.hidden]="isShowDiv">
      <div class="filter__list">
        <div class="filter__list--item" (click)="onMobileItemSelected()">{{ selectAllLabel }}</div>
        <div class="filter__list--item" *ngFor="let item of items" (click)="onMobileItemSelected(item)">
          <span>{{ item.label }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
