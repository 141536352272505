<div class="uk-position-relative uk-visible-toggle" uk-slider>
  <ul class="uk-slider-items tab-slider" *ngIf="categories?.length > 0">
    <li
      *ngFor="let category of categories"
      [class.speciality-nav-item]="inSpecialityPage"
      [class.speciality-nav-item__active]="inSpecialityPage && category.categoryId === specialitaCategoryId"
      [class.exhibitor-filters__category-active]="!inSpecialityPage && category.id === filters.categoryId"
      (click)="
        [
          onCategoryClick(category.id || category.categoryId, category.subCategories),
          getClickedVal(category.categoryId)
        ]
      "
    >
      <a class="uk-text-bold uk-text-capitalize uk-link-reset exhibitor-filters__category">{{
        category.name || category.categoryName
      }}</a>
    </li>
  </ul>
</div>

<div class="filters-section">
  <div class="filters-section__row">
    <div class="filter-grid" [class.single-grid]="!enabledFilters.byRilevance || !enabledFilters.byTypology">
      <div class="filter-grid--item" *ngIf="enabledFilters.searchField">
        <turismo-input-search
          class="input-search-element"
          i18n-placeholder
          [(text)]="filters.query"
          (textChange)="onNgModelChange($event)"
          [active]="isLoading"
          [placeholder]="placeholder"
        ></turismo-input-search>
      </div>

      <div class="filter-grid--item">
        <ng-container *ngIf="subCategories?.length > 0 && enabledFilters.byTypology">
          <div [style.position]="'relative'">
            <button class="filters-section__btn" type="button">
              <div>
                <span class="filters-section__btn--label" *ngIf="filters.subCategoryName">{{
                  filters.subCategoryName
                }}</span>
                <span class="filters-section__btn--label" *ngIf="!filters.subCategoryName" i18n
                  >Tutte le categorie</span
                >
              </div>
              <div class="dropdown-btn--icon">
                <img [src]="dropdownIcon.arrowDown" alt="" uk-icon="ratio: 2" />
              </div>
            </button>
            <div
              #subcategoryDropdownRef
              class="dropdown-menu"
              [attr.uk-dropdown]="dropdownOptions"
              [style.margin-top.px]="0"
            >
              <ul class="uk-nav uk-dropdown-nav dropdown-menu__list">
                <li (click)="onAllSubCategoryClick()" class="dropdown-menu__list--item-header" i18n>
                  Tutte le categorie
                </li>
                <li
                  *ngFor="let subCat of subCategories"
                  (click)="onSubCategoryClick(subCat.id || subCat.subCategoryId, subCat.name || subCat.subCategoryName)"
                  class="dropdown-menu__list--item"
                >
                  {{ subCat.name || subCat.subCategoryName }}
                </li>
              </ul>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="filter-grid--item">
        <ng-container *ngIf="zones?.length > 0 && enabledFilters.byRilevance">
          <div [style.position]="'relative'">
            <button class="filters-section__btn" type="button">
              <span class="filters-section__btn--label" *ngIf="filters.areaName">{{ filters.areaName }}</span>
              <span class="filters-section__btn--label" *ngIf="!filters.areaName && filters.zoneName">{{
                filters.zoneName
              }}</span>
              <span class="filters-section__btn--label" *ngIf="!filters.areaName && !filters.zoneName" i18n
                >Tutti i padiglioni e aree</span
              >
              <div class="dropdown-btn--icon">
                <img [src]="dropdownIcon.arrowDown" alt="" uk-icon="ratio: 2" />
              </div>
            </button>
            <div #areaDropdownRef class="dropdown-menu" [attr.uk-dropdown]="dropdownOptions" [style.margin-top.px]="0">
              <ul class="uk-nav uk-dropdown-nav dropdown-menu__list">
                <li (click)="onAllAreaClick()" class="dropdown-menu__list--item-header" style="cursor: pointer" i18n>
                  Tutti i padiglioni e aree
                </li>
                <ng-container *ngFor="let zone of zones">
                  <li (click)="onZoneClick(zone.id, zone.name)" class="filters-section__dropdown-item__block-header">
                    {{ zone.name }}
                  </li>
                  <span class="divider"></span>
                  <li
                    *ngFor="let area of zone.areas"
                    (click)="onAreaClick(zone.id, area.id, area.name)"
                    class="filters-section__dropdown-item"
                    style="cursor: pointer"
                  >
                    {{ area.name }}
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
