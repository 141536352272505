import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { filter, map, takeUntil } from 'rxjs/operators'

@Component({
  selector: 'turismo-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  totalItems: number

  @Input()
  pageSize: number = 4

  @Input()
  pageGroupSize: number = 7

  @Output()
  pageChanged: EventEmitter<void> = new EventEmitter()

  get isFirstPage(): boolean {
    return this.currentPage === 1
  }

  get isLastPage(): boolean {
    return this.currentPage === this.lastPage
  }

  get hasMoreThenOneGroup(): boolean {
    return this.pages[1]?.length
  }

  get currentPage(): number {
    const paramPage = this.route.snapshot.queryParamMap.get('page')
    return paramPage ? parseInt(paramPage, 10) : 1
  }

  get currentPageGroup(): number[] {
    return this.pages[this.currentPagesGroupIndex]
  }

  get nextPageGroup(): number[] {
    return this.pages[this.currentPagesGroupIndex + 1]
  }

  pages = []
  lastPage: number
  initialRedirect: boolean = true

  private currentPagesGroupIndex = 0
  private readonly onDestroy$: EventEmitter<void> = new EventEmitter<void>()

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    if (!this.totalItems || this.totalItems <= 0) {
      throw new Error(`totalItems invalid value: ${this.totalItems}`)
    }

    this.route.queryParamMap
      .pipe(
        map((qpm) => qpm.get('page')),
        filter((page) => !!page),
        takeUntil(this.onDestroy$),
      )
      .subscribe((page) => {
        if (page) {
          this.currentPagesGroupIndex = Math.ceil(parseInt(page, 10) / this.pageGroupSize) - 1
        }

        if (page != '1') {
          this.initialRedirect = false
        }

        if (!this.initialRedirect) {
          this.pageChanged.emit()
        }
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    const totalItemsChanges = changes['totalItems']

    if (totalItemsChanges) {
      if (!totalItemsChanges.currentValue || totalItemsChanges.currentValue <= 0) {
        throw new Error(`totalItems invalid value: ${totalItemsChanges.currentValue}`)
      }

      this.lastPage = Math.ceil(totalItemsChanges.currentValue / this.pageSize)

      // TODO: This method must be called also on other parameters change. For the moment works fine :D
      this.generatePagination()
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.emit()
  }

  generatePagination(): void {
    this.pages = []
    this.currentPagesGroupIndex = 0

    for (let index = 1; index <= this.lastPage; ) {
      const group: number[] = []
      for (
        let pageGroupIndex = 0;
        pageGroupIndex < this.pageGroupSize && index <= this.lastPage;
        pageGroupIndex++, index++
      ) {
        group.push(index)
      }
      this.pages.push(group)
    }
  }

  onLoadNextGroupPage(): void {
    this.currentPagesGroupIndex = this.currentPagesGroupIndex + 1
  }
}
