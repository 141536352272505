<div class="uk-section uk-section-default not-found__page">
  <div class="uk-container uk-container-large not-found__container">
    <div class="not-found__header">
      <div class="not-found__header-title">404</div>
      <span class="not-found__header-subtitle" i18n>Non siamo riusciti a trovare la pagina che stai cercando...</span>
    </div>
    <div class="not-found__image">
      <img [src]="'assets/images/icons/not-found.svg'" />
    </div>
  </div>
</div>
