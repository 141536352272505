import { defaultAppConfig } from '@app/core/app-default-config'
import { AppConfig } from '@app/core/models/app-config.model'

export const appConfig: AppConfig = {
  ...defaultAppConfig,
  api: {
    ...defaultAppConfig.api,
    baseUrl: 'https://back.metepicene.it/turismowebapi/api/v1', //endpoint api
  },
  core: {
    travelPlannerUrl:
      'https://www.mycicero.it/orari-trasporto/?crosspage=true&dDesc={{desc}}&dLat={{lat}}&dLng={{lng}}&dEpsg=4326',
    documentTitle: 'BIM',
    brandName: 'BIM',
    clientName: 'Mete Picene Festival',
    brandLogo: 'assets/images/favicon.bim.png',
  },
  fair: {
    paths: {
      _root: 'fairs',
      details: 'details',
      evidence: 'evidence',
      guests: 'guests',
      events: 'events',
      places: 'places',
      specialities: 'specialities',
      experiences: 'experiences',
    },
    guests: {
      listPageSize: 9,
      eventsPageSize: 3,
    },
    workWithUsEnabled: false,
    enabledSections: {
      socialShare: {
        facebook: true,
        twitter: true,
        mail: true,
        instagram: true,
        youtube: true,
        enabled: true,
      },
      menu: [
        {
          theFair: {
            inEvidence: true,
            details: true,
          },
          program: true,
          explore: {
            expositors: true,
            itinerary: true,
            specialities: true,
          },
          guest: true,
          experience: {
            restaurant: true,
            menu: true,
          },
          extra: {
            media: true,
            download: true,
          },
          outOfEvent: {
            places: true,
            relatetEvents: true,
            excellence: true,
            tour: true,
          },
          download: false,
        },
      ],
      fairEvidencePage: {
        onEvidenceSection: true,
        onVetrineSection: {
          eventsTab: true,
          espositorsTab: true,
          iteneraryTab: true,
          specialitiesTab: true,
          enabled: true,
        },
        completeExperienceSection: true,
        evidenceEntities: true,
        onCollaborationWithSection: true,
      },
      fairDetailsPage: {
        detailInfoSection: false,
        mapBannerSection: true,
        onCollaborationWithSection: true,
      },
      programPage: {
        filters: {
          searchField: true,
          byHouer: true,
          byRoom: true,
          byTypology: true,
          enabled: true,
        },
        cards: true,
      },
      expositorsPage: {
        filters: {
          searchField: true,
          byCategory: true,
          byAreas: true,
          enabled: true,
        },
        cards: true,
        mapBannerSection: true,
        onCollaborationWithSection: true,
      },
      itinerariesPage: {
        filters: {
          searchField: true,
          byCategory: true,
          byAreas: true,
          enabled: true,
        },
        cards: true,
      },
      specialitiesPage: {
        filters: {
          searchField: true,
          byCategory: true,
          byAreas: true,
          enabled: true,
        },
        cards: true,
        onCollaborationWithSection: true,
      },
      guestPage: {
        filters: {
          searchField: true,
          byCategory: true,
          byAreas: true,
          byTypology: true,
          enabled: true,
        },
        cards: true,
        onCollaborationWithSection: true,
      },
      restaurantPage: {
        filters: {
          searchField: true,
          byCategory: true,
          byTypology: true,
          byAreas: true,
          enabled: true,
        },
        cards: true,
        onCollaborationWithSection: true,
      },
      menuPage: {
        filters: {
          searchField: true,
          byCategory: true,
          byTypology: true,
          byAreas: true,
          enabled: true,
        },
        cards: true,
        onCollaborationWithSection: true,
      },
      downloadPage: {
        filters: {
          searchField: true,
          byCategory: true,
          byTypology: true,
          byAreas: true,
          enabled: true,
        },
        pageSize: 5,
        cards: true,
        enabled: true,
      },
      placesPage: {
        filters: {
          searchField: true,
          byCategory: true,
          byTypology: true,
          byAreas: true,
          enabled: true,
        },
        cards: true,
        eventsSection: true,
        onCollaborationWithSection: true,
      },
      tourPage: {
        filters: {
          searchField: true,
          byCategory: true,
          byTypology: true,
          byAreas: true,
          enabled: true,
        },
        cards: true,
        onCollaborationWithSection: true,
      },
      outOfEvents: {
        placesPage: {
          filters: {
            searchField: true,
            byTypology: true,
            byRelevance: true,
            byAreas: true,
          },
        },
        relatedEventsPage: {
          filters: {
            searchField: true,
            byTypology: true,
            byRelevance: true,
            byAreas: true,
          },
        },
        tourPage: {
          filters: {
            searchField: true,
            byTypology: true,
            byRelevance: true,
            byAreas: true,
          },
        },
        excellencePage: {
          filters: {
            searchField: true,
            byTypology: true,
            byRelevance: true,
            byAreas: true,
          },
        },
      },
    },
    enabled: true,
  },
  tour: {
    paths: {
      _root: 'tours',
    },
    enabledSections: {
      filters: {
        searchField: true,
        byRilevance: true,
        byTypology: true,
      },
      cards: true,
    },
    details: {
      productsPageSize: 4,
    },
    enabled: true,
  },
  excellences: {
    paths: {
      _root: 'excellences',
      details: 'details',
    },
    enabledSections: {
      filters: {
        byTypology: true,
        byRilevance: true,
        searchField: true,
      },
      socialShare: {
        facebook: true,
        twitter: true,
        mail: true,
      },
      cards: true,
    },
    enabled: true,
  },
  homepage: {
    paths: {
      _root: 'home',
    },
    enabledSections: {
      header: true,
      banner: true,
      map: true,
      otherEvents: true,
      partners: true,
      relativeProducts: true,
      experts: true,
      footer: true,
      headerBtn: false,
      headerTxtBtn: false,
      discoverAllEventsBtn: true,
      discoverAllProtagonistsBtn: true,
      eventsState: {
        poi: 1,
        event: 2,
        enabled: true,
      },
      widgetEntityType: {
        poiEventi: 0,
        poi: 1,
        eventi: 2,
      },
    },
    relatedEntities: {
      products: 'https://www.madeinpiceno.it/',
    },
  },
  itinerary: {
    paths: {
      _root: 'itineraries',
    },
    enabledSections: {
      filters: {
        searchField: true,
        byRilevance: true,
        byTypology: true,
      },
      cards: true,
    },
    details: {
      productsPageSize: 4,
    },
    enabled: true,
  },
  path: {
    enabled: false,
    enabledSections: {
      filters: {
        byTypology: true,
        byRilevance: true,
        searchField: true,
      },
    },
  },
  pills: {
    enabled: false,
    enabledSections: {
      filters: {
        byTypology: true,
        byRilevance: true,
        searchField: true,
      },
    },
  },
  favorites: {
    enabled: false,
    enabledSections: {
      filters: {
        byTypology: true,
        byRilevance: true,
        searchField: true,
      },
    },
  },
  place: {
    paths: {
      _root: 'places',
    },
    enabledSections: {
      filters: {
        searchField: true,
        byTypology: true,
        byRilevance: true,
      },
      socialShare: {
        facebook: true,
        twitter: true,
        mail: true,
      },
      cards: true,
    },
    enabled: true,
  },
  exhibitor: {
    paths: {
      _root: 'exhibitors',
    },
    detail: {
      productsPageSize: 9,
    },
    list: {
      isFavouriteEnabled: false,
    },
    enabledSections: {
      filters: {
        searchField: true,
        byTypology: true,
        byRilevance: false,
      },
      socialShare: {
        facebook: true,
        twitter: true,
        mail: true,
      },
      relatedFairs: false,
      cards: true,
    },
    enabled: true,
  },
  specialities: {
    paths: {
      _root: 'specialities',
    },
    enabledSections: {
      filters: {
        searchField: true,
        byCategory: true,
        byTypology: true,
        byAreas: true,
        enabled: true,
      },
      cards: true,
    },
    enabled: true,
  },
  poi: {
    paths: {
      _root: 'pois',
    },
    enabledSections: {
      filters: {
        searchField: true,
        byTypology: true,
        byRilevance: true,
      },
      socialShare: {
        facebook: true,
        twitter: true,
        mail: true,
      },
      cards: true,
    },
    enabled: true,
  },
  product: {
    paths: {
      _root: 'product',
    },
    detail: {
      productsPageSize: 3,
    },
    enabledSections: {
      filters: {
        searchField: true,
        byTypology: true,
        byRilevance: true,
      },
      cards: true,
    },
    enabled: true,
  },
  guest: {
    paths: {
      _root: 'guests',
    },
    enabledSections: {
      slogan: false,
      events: false,
      suggestions: false,
      videos: false,
      advices: false,
      header: true,
      filters: {
        searchField: true,
        byTypology: true,
        byRilevance: true,
      },
      cards: true,
    },
    enabled: true,
  },
  events: {
    paths: {
      _root: 'events',
    },
    enabledSections: {
      filters: {
        searchField: true,
        byTypology: true,
        byRelevance: true,
        enabled: true,
      },
      cards: true,
      header: true,
    },
    enabled: true,
  },
  language: {
    defaultLanguage: 'it',
    availableLanguages: ['it', 'en'], //later to add 'de', 'fr'
  },
  socialConfig: {
    facebook: {
      appId: '1600510073639724',
    },
  },
  analytics: {
    google: {
      anonymizeIp: true,
      trackingList: [],
    },
  },
}
