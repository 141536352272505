import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeUrl } from '@angular/platform-browser'

@Pipe({
  name: 'sanitizeContent',
})
export class SanitizeContentPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}

  //TODO: provide other sanitization types
  transform(
    value: string | undefined,
    sourceType: 'url' | 'resource' | 'html' = 'html',
  ): SafeResourceUrl | SafeHtml | SafeUrl | string {
    if (!value) {
      return ''
    }

    switch (sourceType) {
      case 'html':
        return this.domSanitizer.bypassSecurityTrustHtml(value)
      case 'url':
        return this.domSanitizer.bypassSecurityTrustUrl(value)
      case 'resource':
        return this.domSanitizer.bypassSecurityTrustResourceUrl(value)
    }
  }
}
