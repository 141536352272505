import { Control } from '@ng-stack/forms'

export const ORDER_BY_NAME = $localize`:used on the fai location page|Explains the order@@order_by_name:nome`
export const ORDER_BY_RELEVANCE = $localize`rilevanza`
export const ORDER_BY_DISTANCE = $localize`distanza`
export const ORDER_BY_START_DATE = $localize`startDate`

export type OrderBy =
  | typeof ORDER_BY_NAME
  | typeof ORDER_BY_RELEVANCE
  | typeof ORDER_BY_DISTANCE
  | typeof ORDER_BY_RELEVANCE
  | typeof ORDER_BY_START_DATE

export interface Coordinates {
  lat: number
  lng: number
}

export interface ActiveFilters {
  orderBy: OrderBy
  coordinates: Coordinates | null
  distance?: string
}

export interface PlaceFilterForm extends ActiveFilters {
  coordinates: Control<Coordinates> | null
  query: string
}
