import { Component, Input, OnInit } from '@angular/core'
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper/core'

// install Swiper modules
SwiperCore.use([Pagination, Navigation, Autoplay])

@Component({
  selector: 'turismo-single-photo-gallery',
  templateUrl: './single-photo-gallery.component.html',
  styleUrls: ['./single-photo-gallery.component.scss'],
})
export class SinglePhotoGalleryComponent implements OnInit {
  sliderOptions = 'finite: true; draggable: false; finite: true;'

  @Input()
  images: []

  constructor() {}

  ngOnInit(): void {}
}
