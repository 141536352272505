<ng-container *ngIf="!isMobileView; else mobileViev">
  <button (click)="toggleDropdown()" class="place-filters__dropdown-button" type="button">
    <div class="place-filters__dropdown-button--container">
      <div class="place-filters__dropdown-button-label" [class.float-top-label]="showStartingPoint && startingPoint">
        <span class="wrap-text">
          <ng-container i18n>Ordina per</ng-container>
          {{ orderBy === 'startDate' ? 'data' : orderBy }}
        </span>
        <!-- <span class="wrap-text">
      </span> -->
        <span *ngIf="showStartingPoint && startingPoint" class="place-filters__starting-point">
          <nobr><span i18n>da</span>: {{ startingPoint }}</nobr>
        </span>
      </div>
      <div style="width: 24px; height: auto; box-sizing: border-box; margin-left: 0.75rem">
        <img
          *ngIf="!isDropdownVisible; else closedDropdownArrowTemplateRef"
          src="assets/images/icons/chevron-down.svg"
          aria-hidden="true"
          class="place-filters-order-by__dropdown-icon"
        />
        <ng-template #closedDropdownArrowTemplateRef>
          <img
            src="assets/images/icons/chevron-up.svg"
            aria-hidden="true"
            class="place-filters-order-by__dropdown-icon"
          />
        </ng-template>
      </div>
      <span class="divider" *ngIf="!isDropdownVisible"></span>
    </div>
  </button>
</ng-container>

<ng-template #mobileViev>
  <div class="place-filters__dropdown-button" type="text">
    <div class="place-filters__dropdown-button--container">
      <div class="place-filters__dropdown-button-label" [class.float-top-label]="showStartingPoint && startingPoint">
        <span class="wrap-text">
          <ng-container i18n>Ordina per:</ng-container>
          <!-- {{ orderBy === 'startDate' ? 'data' : orderBy }} -->
        </span>
        <!-- <span class="wrap-text">
        </span> -->
        <span *ngIf="showStartingPoint && startingPoint" class="place-filters__starting-point">
          <nobr><span i18n>da</span>: {{ startingPoint }}</nobr>
        </span>
      </div>
      <span class="divider" *ngIf="!isDropdownVisible"></span>
    </div>
  </div>
</ng-template>

<div *ngIf="isDropdownVisible" class="place-filters__dropdown sm-right-pos">
  <form [formGroup]="form">
    <ul class="place-filters__list">
      <li *ngIf="OrderByStartDate === true">
        <label class="input-wrapper">
          <input [value]="orderByStartDate" formControlName="orderBy" type="radio" />
          <span i18n>Per data</span>
        </label>
      </li>

      <li>
        <!--        <turismo-radio-button value="relevance" formControlName="orderBy" i18n-label label="Per rilevanza">-->
        <!--        </turismo-radio-button>-->

        <label class="input-wrapper">
          <input [value]="orderByRelevance" formControlName="orderBy" type="radio" />
          <span i18n>Rilevanza</span>
        </label>
      </li>

      <li>
        <!-- <turismo-radio-button value="name" formControlName="orderBy" i18n-label label="Per nome">-->
        <!--        </turismo-radio-button> -->

        <label class="input-wrapper">
          <input [value]="orderByName" formControlName="orderBy" type="radio" />
          <span i18n>Nome (A-Z)</span>
        </label>
      </li>

      <li>
        <!--        <turismo-radio-button value="distance" formControlName="orderBy" i18n-label label="Per distanza">-->
        <!--        </turismo-radio-button>-->
      </li>

      <li>
        <label class="input-wrapper">
          <input [value]="orderByDistance" formControlName="orderBy" type="radio" />
          <span i18n>Distanza</span>
        </label>
      </li>
    </ul>

    <div
      *ngIf="displayFooter || activeOrder == 'distance' || activeOrder == 'distanza'"
      class="place-filters-order-by__footer"
    >
      <div style="position: relative">
        <div class="place-filters__text-input-container">
          <input type="text" formControlName="query" i18n-placeholder placeholder="Inserisci indirizzo" />

          <!-- TBD added logic of buttons -->
          <span
            *ngIf="!text?.length; else filledField"
            class="uk-form-icon uk-form-icon-flip uk-text-primary search-icon"
            uk-icon="icon: search"
          ></span>

          <ng-template #filledField>
            <a
              class="uk-form-icon uk-form-icon-flip uk-text-primary clear-icon"
              (click)="onCancelSearch()"
              uk-icon="icon: close"
            ></a>
          </ng-template>
        </div>

        <div class="place-filters-order-by__suggestions uk-animation-fade" *ngIf="suggestions$ | async as suggestions">
          <div
            *ngIf="suggestionsAreLoading$ | async; else suggestionsListTemplateRef"
            class="uk-flex uk-flex-center uk-padding"
            uk-spinner="ratio: 3"
          ></div>

          <ng-template #suggestionsListTemplateRef>
            <ng-container *ngIf="showSuggestionsList$ | async">
              <span *ngIf="suggestions.length === 0" class="uk-text-center uk-padding" i18n
                >Nessun suggerimento trovato</span
              >

              <ul class="place-filters-order-by__suggestions-list">
                <li
                  tabindex="0"
                  *ngFor="let suggestion of suggestions"
                  (click)="onAddressClick(suggestion.magicKey, suggestion.text)"
                  (keyup.enter)="onAddressClick(suggestion.magicKey, suggestion.text)"
                >
                  {{ suggestion.text | orderByPlace }}
                </li>
              </ul>
            </ng-container>
          </ng-template>
        </div>
      </div>

      <button
        *ngIf="!isMobileView"
        (click)="applyFiltersClick()"
        class="place-filters__button place-filters__apply-button"
        type="button"
        i18n
      >
        Applica
      </button>
    </div>
  </form>
</div>
