<ng-container *ngIf="exhibitor$ | async as exhibitor">
  <div class="uk-section-default">
    <div class="uk-container uk-container-large uk-padding">
      <div class="uk-flex uk-flex-between">
        <div class="uk-flex-1" [style.min-width.%]="50">
          <div class="exhibitor-detail-page__header-container">
            <header class="exhibitor-detail-page__header">
              <h1 class="exhibitor-detail-page__header-title">{{ exhibitor.name }}</h1>
              <p class="exhibitor-detail-page__header-subtitle">{{ exhibitor.shortDescription }}</p>
            </header>

            <div class="sub-header-section">
              <a
                [href]="exhibitor.travelPlannerUrl"
                *ngIf="exhibitor.travelPlannerUrl"
                target="_blank"
                class="uk-button uk-box-shadow-medium exhibitor-detail-page__take-me-here"
                i18n="Link al travel planner"
                >Portami qui</a
              >

              <div class="condividi-section d-sm-none" [class.no-flex]="!exhibitor.travelPlannerUrl">
                <a target="_blank" class="uk-button uk-box-shadow-medium condividi-section__button" i18n>
                  <span>Condividi<img src="assets/images/icons/share.svg" /></span>
                </a>
                <div uk-dropdown="pos: right-center" class="social-media-share__pop-up">
                  <h4 class="social-media-share__pop-up--title"></h4>
                  <div class="social-media-share__pop-up--item">
                    <a [href]="shareWithFacebook" target="_blank">
                      <img [src]="'assets/images/icons/Facebook.svg'" />
                      <span>Facebook</span>
                    </a>
                  </div>
                  <div class="social-media-share__pop-up--item">
                    <a [href]="shareWithTweeter" target="_blank">
                      <img [src]="'assets/images/icons/Twitter.svg'" />
                      <span>Twitter</span>
                    </a>
                  </div>
                  <div class="social-media-share__pop-up--item">
                    <a [href]="shareWithEmail" target="_blank">
                      <i class="uk-icon-button fair-page__social-share-icon" aria-hidden="true" uk-icon="mail"></i>
                      <span>Mail</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="exhibitor.company?.name && enabledSections.relatedFairs"
            class="exhibitor-detail-page__company sm-show"
          >
            <span class="exhibitor-detail-page__company--title">Azienda</span>
            <span
              class="exhibitor-detail-page__company--name"
              routerLink="../../../pois/{{ exhibitor.company.id }}/{{ exhibitor.company.name }}"
            >
              {{ exhibitor.company.name }}
            </span>
          </div>

          <ng-container *ngIf="exhibitor.gallery.images.length > 0">
            <turismo-exhibitor-gallery
              class="exhibitor-detail-page__gallery-element"
              [items]="exhibitor.gallery.images"
            ></turismo-exhibitor-gallery>
          </ng-container>

          <ng-container *ngIf="exhibitor.fair?.id">
            <div class="d-flex translate-revert-horizontal mt-2 mb-2 exhibitor-detail-page__fair-info">
              <img class="exhibitor-detail-page__fair-place-icon" src="assets/images/icons/place.svg" />
              <div class="exhibitor-detail-page__fair">
                <p>
                  <ng-container *ngIf="exhibitor.fair?.name as name">
                    <a class="exhibitor-detail-page__fair-name">{{ name }}</a>
                    <!-- <a [routerLink]="fairPath$ | async" class="exhibitor-detail-page__fair-name">{{ name }}</a> -->
                  </ng-container>
                  <ng-container *ngIf="!!exhibitor.fair?.zone && !!exhibitor.mapUrl">
                    <a target="_blank"> <ng-container i18n>Zona</ng-container>: {{ exhibitor.fair?.zone }} </a>
                    <!-- <a [href]="exhibitor.mapUrl" target="_blank">
                      <ng-container i18n>Zona</ng-container>: {{ exhibitor.fair?.zone }}
                    </a> -->
                  </ng-container>
                </p>

                <ng-container *ngIf="exhibitor.fair?.zone && exhibitor.mapUrl">
                  <!-- <a target="_blank" class="exhibitor-detail-page__fair-map-icon-container">
                    <img class="exhibitor-detail-page__fair-map-icon" src="assets/images/icons/map.svg" />
                  </a> -->
                  <!-- <a [href]="exhibitor.mapUrl" target="_blank" class="exhibitor-detail-page__fair-map-icon-container">
                    <img class="exhibitor-detail-page__fair-map-icon" src="assets/images/icons/map.svg" />
                  </a> -->
                </ng-container>
              </div>
            </div>
          </ng-container>

          <ng-container
            *ngIf="
              !exhibitor.fair &&
              !!exhibitor.placeInfo?.address &&
              !!exhibitor.placeInfo?.address.city &&
              !!exhibitor.placeInfo.address
            "
          >
            <div class="exhibitor-detail-page__poi">
              <img class="exhibitor-detail-page__poi-place-icon" src="assets/images/icons/place.svg" />
              <p>
                <span class="poi-city">{{ exhibitor.placeInfo.address.city }}</span>
                <span>{{
                  exhibitor.placeInfo.address.street +
                    (exhibitor.placeInfo.address.streetNumber ? ', ' + exhibitor.placeInfo.address.streetNumber : '')
                }}</span>
              </p>
            </div>
          </ng-container>

          <!-- <div style="margin-top: 0.75rem; display: block">
          <ul class="contact-info-details">
            <li *ngIf="!!company || isLoading" class="contact-info-details__item">
              <ng-container *ngIf="!isLoading; else loadingTemplateRef">
              <a [href]="'name:' + company"> <img src="assets/images/icons/building.svg" />{{ company }}</a>
              </ng-container>
            </li>
          </ul>
        </div> -->

          <turismo-contact-info-details
            style="margin-top: 0.75rem; display: block"
            [company]="exhibitor.company?.name"
            [phoneNumber]="exhibitor.contactInfo?.telephone"
            [email]="exhibitor.contactInfo?.mail"
            [websiteUrl]="exhibitor.contactInfo?.site"
            [facebookUrl]="exhibitor.contactInfo?.facebook"
          ></turismo-contact-info-details>

          <div class="condividi-section d-sm">
            <div class="condividi-section--text">
              <span i18n class="uk-text-bolder uk-text-uppercase">Condividi:</span>
            </div>
            <ul class="uk-subnav">
              <li>
                <a [href]="shareWithFacebook" target="_blank">
                  <i class="uk-icon-button" uk-icon="icon: facebook"></i>
                </a>
              </li>
              <li>
                <a [href]="shareWithTweeter" target="_blank"><i class="uk-icon-button" uk-icon="icon: twitter"></i></a>
              </li>
              <li>
                <a [href]="shareWithEmail" target="_blank"><i class="uk-icon-button" uk-icon="icon: mail"></i></a>
              </li>
            </ul>
          </div>
        </div>

        <div class="exhibitor-detail-page__gallery-container">
          <div *ngIf="exhibitor.company?.name && enabledSections.relatedFairs" class="exhibitor-detail-page__company">
            <span class="exhibitor-detail-page__company--title">Azienda</span>
            <span
              class="exhibitor-detail-page__company--name"
              routerLink="../../../pois/{{ exhibitor.company.id }}/{{ exhibitor.company.name }}"
            >
              {{ exhibitor.company.name }}
            </span>
          </div>
          <ng-container *ngIf="exhibitor.images.length > 0">
            <slider-carousel [type]="'photo'" [data]="exhibitor.gallery.images"></slider-carousel>
          </ng-container>
        </div>
      </div>
    </div>

    <div *ngIf="!!exhibitor.abstract" class="uk-container uk-container-large uk-padding uk-text-center">
      <div
        class="testimonial-section testimonial-section--text uk-container-large"
        [innerHTML]="exhibitor.abstract | sanitizeContent"
      ></div>
    </div>
  </div>

  <div class="uk-section-default" *ngIf="exhibitor.description" #desc>
    <div class="uk-container uk-container-large uk-padding">
      <h3 class="uk-text-bold uk-text-center uk-margin-medium-bottom exhibitor-detail-page__section-title" i18n>
        {{ brandName === 'BIM' ? bimLabel : tipicitaLabel }}
      </h3>
      <div class="uk-margin-small-bottom" [class.uk-height-max-small]="!displayOverflowText">
        <p class="uk-p" #paragraphContentRef [innerHTML]="exhibitor.description | sanitizeContent"></p>
      </div>
      <button
        *ngIf="displayReadMoreButton"
        (click)="onReadMore()"
        class="uk-button uk-button-link exhibitor-detail-page__read-more"
        aria-hidden="true"
      >
        <span *ngIf="displayOverflowText" i18n>RIDUCI</span>
        <span *ngIf="!displayOverflowText" i18n>LEGGI TUTTO</span>
      </button>
    </div>
  </div>

  <div class="uk-container uk-container-large uk-padding">
    <turismo-related-fairs-slider
      *ngIf="eventType !== 'exhibitor' && exhibitor.fairs?.length > 0 && enabledSections.relatedFairs"
      [fairs]="exhibitor.fairs"
    ></turismo-related-fairs-slider>
  </div>

  <div class="uk-section-default" *ngIf="exhibitor.shopProducts.length > 0">
    <div
      class="uk-container uk-container-large uk-padding uk-flex uk-flex-column uk-flex-middle exhibitor-detail-page__promotions"
    >
      <h3 class="uk-text-bold exhibitor-detail-page__section-title exhibitor-detail-page__promotions-title" i18n>
        Le nostre promozioni
      </h3>

      <div class="uk-grid-small uk-flex-wrap-around exhibitor-detail-page__shop-grid" uk-grid>
        <div
          *ngFor="let product of exhibitor.shopProducts"
          class="exhibitor-detail-page__shop-item"
          (click)="setBreadcrumbs(product.name)"
        >
          <a (click)="showProduct(product)" class="exhibitor-detail-page__product-link">
            <turismo-exhibitor-shop-product [product]="product"></turismo-exhibitor-shop-product>
          </a>
        </div>
      </div>

      <ng-container *ngIf="!!exhibitor.shopUrl">
        <a [href]="exhibitor.shopUrl" target="_blank" class="load-more-btn">
          <ng-container i18n>Vai al negozio</ng-container>
        </a>
      </ng-container>
    </div>
  </div>

  <div *ngIf="exhibitor.services.length > 0" class="uk-section-default exhibitor-detail-page__services-container">
    <div class="uk-container uk-container-large uk-padding exhibitor-detail-page__services">
      <h3 class="uk-text-bold uk-text-center uk-margin-medium-bottom exhibitor-detail-page__section-title" i18n>
        Servizi offerti
      </h3>
      <ul
        class="uk-list uk-list-collapse uk-text-center uk-flex-center uk-flex-middle uk-grid-large uk-child-width-1-2 uk-child-width-auto@s"
        uk-grid
      >
        <li *ngFor="let service of exhibitor.services">
          <figure>
            <img
              [src]="service.icon"
              [alt]="service.description"
              width="60"
              height="auto"
              class="uk-text-emphasis uk-margin-small-bottom service"
              uk-img="data-src:"
              uk-svg
            />
            <figcaption class="uk-text-small" style="font-size: 14px">{{ service.description }}</figcaption>
          </figure>
        </li>
      </ul>
    </div>
  </div>

  <div class="uk-section-default colored-bg" *ngIf="exhibitor.videoGallery?.videos.length > 0">
    <div class="uk-container uk-container-large uk-padding">
      <div class="video-slideshow">
        <slider-carousel [type]="'video'" [data]="exhibitor.videoGallery.videos" [multipleImg]="true"></slider-carousel>
      </div>
    </div>
  </div>

  <div class="uk-section-default colored-bg" *ngIf="exhibitor.products?.length">
    <div class="uk-container uk-container-large uk-padding" #descProduct>
      <h3 class="uk-text-bold uk-text-center uk-margin-medium-bottom exhibitor-detail-page__section-title" i18n>
        I nostri prodotti
      </h3>
      <div class="d-grid grid-4-lg grid-2-md grid-1-sm">
        <a
          *ngFor="let product of products$ | async"
          routerLink="../../../products/{{ product.id }}/{{ product.name }}"
          class="exhibitor-detail-page__product-link"
          (click)="setBreadcrumbs(product.name)"
        >
          <turismo-small-card
            [title]="product.name"
            [subtitle]="product.description"
            [backgroundImage]="product.image?.url"
            [favourite]="product.favourite"
          ></turismo-small-card>
        </a>
      </div>
      <button
        *ngIf="totalProducts > (products$ | async).length"
        (click)="onClickLoadMoreProducts()"
        class="load-more-btn"
        i18n
      >
        Mostra altri
      </button>

      <button
        *ngIf="totalProducts == (products$ | async).length && currentProductsPage != 1"
        (click)="onClickReduceProducts()"
        class="load-more-btn"
        i18n
      >
        Riduci
      </button>
    </div>
  </div>

  <!-- ancora tanto altro section -->
  <ng-container *ngIf="relatedPois | async as relatedEv">
    <div
      style="background-color: #f3f3f3"
      class="uk-section-default ancora-altro-section"
      *ngIf="relatedEv?.totalCount > 0"
    >
      <div class="uk-container uk-container-large uk-padding" #descWrapper>
        <div class="header-section">
          <h1 class="exhibitor-detail-page__section-title text-center w-800" i18n>Ancora tanto altro</h1>
          <p class="lead">
            <span i18n> Esplora altri contenuti correlati a </span>
            {{ exhibitor.name }}
          </p>
        </div>

        <div class="row">
          <div class="d-grid grid-4-lg grid-2-md grid-1-sm">
            <a
              *ngFor="let related of relatedEv.events | slice: 0:relatedPageSize"
              [routerLink]="['/events', related.id, related.name]"
              class="exhibitor-detail-page__product-link"
              (click)="setBreadcrumbs(related.name)"
            >
              <turismo-small-card [backgroundImage]="related.image?.url" [favourite]="related.isFavourite">
                <h4 class="small-card-title">{{ related.name }}</h4>
                <h5 class="small-card-sub-title">{{ related.city }}</h5>
                <p
                  class="date-time-label"
                  *turismoEventDates="{ startDate: related.startDate, endDate: related.endDate }; label as date"
                  [innerHTML]="date"
                ></p>
              </turismo-small-card>
            </a>
          </div>

          <ng-container *ngIf="relatedEv.totalCount >= 6">
            <button
              *ngIf="relatedEv.totalCount >= relatedPageSize * currentRelatedPage"
              (click)="onClickLoadMoreRelated()"
              class="uk-button uk-button-tertiary uk-margin-medium-top uk-align-center"
              i18n
            >
              Mostra altri
            </button>

            <button
              *ngIf="relatedEv.totalCount <= relatedPageSize * currentRelatedPage"
              (click)="onClickReduceRelated()"
              class="uk-button uk-button-tertiary uk-margin-medium-top uk-align-center"
              i18n
            >
              Riduci
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!!exhibitor.archiveDescription || exhibitor.downloads?.length > 0">
    <div class="uk-section uk-section-default exhibitor-detail-page__downloads-container">
      <div class="uk-container uk-container-large uk-padding uk-text-center">
        <h3 class="uk-text-bold uk-text-center uk-margin-small-bottom exhibitor-detail-page__section-title" i18n>
          Download
        </h3>
        <turismo-downloads-list [downloads]="exhibitor.downloads" [description]="exhibitor.archiveDescription">
        </turismo-downloads-list>
      </div>
    </div>
  </ng-container>
</ng-container>

<div *ngIf="exhibitor$ | async as exhibitor">
  <div *ngIf="exhibitor?.exhibitorInfo?.url != null; else footerExhibitors">
    <img [src]="exhibitor.exhibitorInfo.url" class="uk-align-center uk-animation-fade" />
  </div>
</div>
<ng-template #footerExhibitors>
  <div class="uk-section-default" *ngIf="partnersImageUrl$ | async">
    <img [src]="partnersImageUrl$ | async" class="uk-align-center" />
  </div>
</ng-template>
