import { Injectable } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { appConfig } from '../../../brands/_current/config/app-config'

@Injectable({
  providedIn: 'root',
})
export class SocialService {
  constructor(private titleService: Title, private metaService: Meta) {}

  addSocialSeo(
    title: string,
    description: string,
    image?: string,
    type: string = 'website',
    url: string = window.location.href,
  ): void {
    if (title) {
      this.metaService.updateTag({ property: 'og:title', content: title })
    }

    if (description) {
      this.metaService.updateTag({ property: 'og:description', content: description })
    }

    if (image) {
      this.metaService.updateTag({ property: 'og:image', content: image })
      this.metaService.updateTag({ property: 'og:image:width', content: '1024' })
      this.metaService.updateTag({ property: 'og:image:height', content: '720' })
    }

    this.metaService.updateTag({ property: 'og:url', content: url })
    this.metaService.updateTag({ property: 'og:type', content: type })
  }

  static getTwitterShare(tweetContent: string, url: string = window.location.href): string {
    return `https://twitter.com/intent/tweet?text=${encodeURI(tweetContent)}&url=${encodeURI(url)}`
  }

  /**
   * Share with Facebook
   * @see https://developers.facebook.com/docs/sharing/reference/share-dialog#advancedtopics
   *
   * For debugging
   * @see https://developers.facebook.com/tools/debug
   *
   * @param {string} href
   * @param {string} redirectUrl
   * @param {"page" | "popup"} display
   * @returns {string}
   */
  static getFacebookShare(
    href: string = window.location.href,
    redirectUrl: string = window.location.href,
    display: 'page' | 'popup' = 'page',
  ): string {
    return `https://www.facebook.com/dialog/share?app_id=${
      appConfig.socialConfig?.facebook?.appId
    }&display=${display}&href=${encodeURI(href)}&redirect_uri=${encodeURI(redirectUrl)}`
  }

  static getEmailShare(subject: string, content?: string, href: string = window.location.href): string {
    return `mailto:?subject=${encodeURI(subject)}&body=${encodeURI(href)}\n ${content ? encodeURI(content) : ''}`
  }
}
