import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

const GEOCODE_API_URL = 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer'
const GEOCODE_START_LOCATION = '13.25,43.5'
const GEOCODE_COUNTRY_CODE = 'IT'

export interface SuggestionsResponse {
  suggestions: Suggestion[]
}

export interface Suggestion {
  text: string
  magicKey: string
  isCollection: boolean
}

export interface CandidatesResponse {
  candidates: Candidate[]
}

export interface Candidate {
  address: string
  location: CandidateLocation
  score: number
}

export interface CandidateLocation {
  x: number
  y: number
}

@Injectable()
export class GeocodeService {
  constructor(private httpClient: HttpClient) {}

  geocode(address: string): Observable<Suggestion[]> {
    const params: HttpParams = new HttpParams()
      .set('f', 'json')
      .set('countryCode', GEOCODE_COUNTRY_CODE)
      .set('text', address)
      .set('maxSuggestions', '5')
      .set('location', GEOCODE_START_LOCATION) // Used to boost the search

    return this.httpClient
      .get<SuggestionsResponse>(`${GEOCODE_API_URL}/suggest`, { params })
      .pipe(map(({ suggestions }) => suggestions))
  }

  getAddressCoordinates(addressId: string, address: string): Observable<Candidate[]> {
    const params: HttpParams = new HttpParams()
      .set('f', 'json')
      .set('category', 'Address,Postal')
      .set('countryCode', GEOCODE_COUNTRY_CODE)
      .set('magicKey', addressId)
      .set('SingleLine', address)
      .set('location', GEOCODE_START_LOCATION) // Used to boost the search

    return this.httpClient
      .get<CandidatesResponse>(`${GEOCODE_API_URL}/findAddressCandidates`, { params })
      .pipe(map(({ candidates }) => candidates))
  }
}
