import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import {
  FilterDropdownSelectionItem,
  FilterDropdownSelectionItems,
} from '@shared/components/filter-dropdown-selection/filter-dropdown-selection.models'
import UIkit from 'uikit'

@Component({
  selector: 'turismo-filter-dropdown-selection',
  templateUrl: './filter-dropdown-selection.component.html',
  styleUrls: ['./filter-dropdown-selection.component.scss'],
})
export class FilterDropdownSelectionComponent {
  @Input()
  items: FilterDropdownSelectionItems = []

  @Input()
  selectedItem: FilterDropdownSelectionItem | null = null

  @Output()
  selectedItemChange: EventEmitter<FilterDropdownSelectionItem> = new EventEmitter<FilterDropdownSelectionItem>()

  @Input()
  width: 'uk-width-small' | 'uk-width-medium' | 'uk-width-large' | 'uk-width-xlarge' = 'uk-width-medium'

  @Input()
  dropdownOptions: string = 'delay-hide: 0; pos: bottom-center; mode: click'

  @Input()
  selectAllLabel: string = 'Tutte'

  targetId: string
  isShowDiv: boolean

  @Input() isMobile: boolean
  @Input() toggleOption: string

  @ViewChild('dropdownListRef')
  private readonly dropdownListEl!: ElementRef

  dropdownIcon = {
    arrowDown: 'assets/images/icons/bold-chevron-down.svg',
    arrowUp: 'assets/images/icons/chevron-up.svg',
  }

  constructor() {}

  onItemSelected(selectedItem: FilterDropdownSelectionItem = null): void {
    // @ts-ignore
    UIkit.dropdown(this.dropdownListEl.nativeElement).hide(false)

    // Prevent same item update
    if (this.selectedItem && this.selectedItem.id === selectedItem?.id) {
      return
    }

    this.selectedItem = selectedItem
    this.selectedItemChange.emit(selectedItem)
  }

  onMobileItemSelected(selectedItem: FilterDropdownSelectionItem = null) {
    if (this.selectedItem && this.selectedItem.id === selectedItem?.id) {
      return
    }

    this.selectedItem = selectedItem
    this.selectedItemChange.emit(selectedItem)
  }

  toggleElement(el) {
    console.log(el)
    this.targetId = el
    console.log(this.targetId)
    this.isShowDiv = !this.isShowDiv
  }
}
