import { EventEmitter, Inject, Injectable, LOCALE_ID, OnDestroy } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Exhibitor } from './exhibitor.model'
import { catchError, map } from 'rxjs/operators'
import { appConfig } from '../../../../brands/_current/config/app-config'
import { TurismoWebApiService } from '../../../shared/web-api/turismo-web-api.service'
import { Lang } from '../../../shared/web-api/models/lang.type'
import { Store } from '@ngrx/store'
import { AppState } from '@app/core/store/models/app-state.model'
import { getSelectedLanguage } from '@app/core/store/selectors/app-remote-config.selectors'

@Injectable({
  providedIn: 'root',
})
export class ExhibitorService implements OnDestroy {
  private lang: Lang
  private readonly onDestroy$: EventEmitter<void> = new EventEmitter<void>()

  constructor(
    private webApi: TurismoWebApiService,
    @Inject(LOCALE_ID) public locale: string,
    private store$: Store<AppState>,
  ) {
    // this.lang = this.locale.split('-')[0].toLowerCase() as Lang
    this.store$
      .select(getSelectedLanguage)
      .pipe()
      .subscribe((val) => {
        this.lang = val
      })
  }

  //Exhibitor mapping
  public getExhibitorDetails(id: string): Observable<Exhibitor> {
    return this.webApi.getExhibitor(+id, this.lang).pipe(
      map((exhibitor) => ({
        id: `${exhibitor.id}`,
        contactInfo: {
          facebook: exhibitor.contacts?.socialSiteUrl,
          mail: exhibitor.contacts?.email,
          site: exhibitor.contacts?.siteUrl,
          telephone: exhibitor.contacts?.phone,
        },
        description: exhibitor.descriptionHtml ? exhibitor.descriptionHtml : exhibitor.description,
        name: exhibitor.name,
        fair: {
          id: exhibitor.fairInfo?.id,
          name: exhibitor.fairInfo?.name,
          zone: !!exhibitor.fairInfo?.zone
            ? `${exhibitor.fairInfo?.zone?.area} (${exhibitor.fairInfo?.zone?.pavilion})`
            : undefined,
        },

        exhibitorInfo: {
          title: exhibitor.fairInfo?.footerImage?.title,
          url: exhibitor.fairInfo?.footerImage?.url,
        },
        travelPlannerUrl: !exhibitor.fairInfo?.address
          ? null
          : appConfig.core.travelPlannerUrl
              .replace(
                '{{desc}}',
                `${exhibitor.fairInfo?.address?.street}${
                  exhibitor.fairInfo?.address?.streetNumber ? `, ${exhibitor.fairInfo?.address?.streetNumber}` : ''
                } ${exhibitor.fairInfo?.address?.city}`,
              )
              .replace('{{lat}}', `${exhibitor.fairInfo?.address?.lat}`)
              .replace('{{lng}}', `${exhibitor.fairInfo?.address?.lon}`),
        mapUrl: !exhibitor.fairInfo?.mapLink
          ? null
          : `${exhibitor.fairInfo?.mapLink}?marker=${exhibitor.fairInfo?.zone?.marker}&desc=${exhibitor.fairInfo?.zone?.area}`,
        shortDescription: exhibitor.subCategory?.subCategoryName,
        images: [
          {
            url: exhibitor.gallery?.foreground?.url,
            description: exhibitor.gallery?.foreground?.description,
          },
          ...exhibitor.gallery?.images?.map((image) => ({
            url: image.url,
            description: image.description,
          })),
        ],
        reviews: [],
        products: [],
        partners: [],
        gallery: exhibitor.gallery,
        videoGallery: exhibitor.videoGallery,
        services: exhibitor.additionalInfos
          // Exclude icons that dont have the url
          .filter((addInfos) => !!addInfos.iconUrl)
          .map((addInfos) => ({
            icon: addInfos.iconUrl,
            description: addInfos.name,
            id: addInfos.fieldId,
          })),
        abstract: exhibitor.abstract,
        abstractHtml: exhibitor.abstractHtml,
        shopUrl: exhibitor.onlineShop ?? undefined,
        shopProducts:
          exhibitor.commercialProducts?.totalCount > 0
            ? exhibitor.commercialProducts.products.map(({ price, description, image, name, id }) => ({
                exhibitorName: exhibitor.name,
                id,
                price,
                description,
                name,
                imageUrl: image,
              }))
            : [],
        archiveDescription: exhibitor.archiveDescriptionHtml
          ? exhibitor.archiveDescriptionHtml
          : exhibitor.archiveDescription,
        downloads: exhibitor.downloads,
        company: exhibitor.company
          ? {
              id: exhibitor.company.id,
              name: exhibitor.company.name,
            }
          : null,
      })),
      catchError((error) => {
        // TODO: implementare gestione errori
        if (error instanceof Error) {
          console.error(error.message)
        }
        return of(null)
      }),
    )
  }

  ngOnDestroy() {
    this.onDestroy$.emit()
  }
}
