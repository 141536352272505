<div class="uk-inline input-search" [class.input-search--active]="(active || focused) && text?.length">
  <span
    *ngIf="!text?.length; else filledTemplate"
    class="uk-form-icon uk-form-icon-flip uk-text-primary search-icon"
    uk-icon="icon: search"
  ></span>

  <ng-template #filledTemplate>
    <a
      class="uk-form-icon uk-form-icon-flip uk-text-primary clear-icon"
      (click)="onCancelSearch()"
      uk-icon="icon: close"
    ></a>
  </ng-template>

  <input
    [(ngModel)]="text"
    (ngModelChange)="onTextChange()"
    class="uk-input"
    type="text"
    [placeholder]="placeholder"
    (focusin)="focused = true"
    (focusout)="focused = false"
    [disabled]="disabled"
  />
</div>
