import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { DownloadDto } from '@app/shared/web-api/models/common.models'

@Component({
  selector: 'turismo-downloads-list',
  templateUrl: './downloads-list.component.html',
  styleUrls: ['./downloads-list.component.scss'],
})
export class DownloadsListComponent implements OnInit {
  @Input()
  downloads: DownloadDto[]

  @Input()
  description: string

  @Input()
  categories: Array<string>

  @Input()
  activeFilters: { query: string; category: string }

  @Output()
  filtersChange: EventEmitter<{ query: string; category: string }> = new EventEmitter<{
    query: string
    category: string
  }>()

  constructor() {}

  ngOnInit(): void {}

  filtersChanged(e) {
    this.filtersChange.emit(e)
  }
}
