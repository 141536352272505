import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'turismo-collaborators-image',
  template: `
    <div>
      <img [style.width.%]="100" [src]="partnersImageUrl" [alt]="" />
    </div>
  `,
  styles: [],
})
export class CollaboratorsImageComponent implements OnInit {
  @Input()
  partnersImageUrl: string

  constructor() {}

  ngOnInit(): void {}
}
