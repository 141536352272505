import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators'
import UIkit from 'uikit'
import { GuestCategory } from '../../shared/guest.model'
import { GuestActiveFilters } from './fair-guest-filters.model'

@Component({
  selector: 'turismo-fair-guests-filters',
  templateUrl: './fair-guests-filters.component.html',
  styleUrls: ['./fair-guests-filters.component.scss'],
})
export class FairGuestsFiltersComponent implements OnInit, OnDestroy {
  @Input()
  guestCategories: GuestCategory[] = []

  @Input()
  currentLang?: string

  @Input()
  isLoading = false

  @Input()
  filters: GuestActiveFilters

  @Input() enabledSections: any

  @Output()
  filtersChange = new EventEmitter<GuestActiveFilters>()

  readonly dropdownOptions = 'delay-hide: 0; pos: bottom-center; mode: click'

  private readonly userQuery$ = new Subject<string>()
  private readonly onDestroy$ = new EventEmitter<void>()

  @ViewChild('categoriesDropdownRef')
  private readonly categoriesDropdown!: ElementRef

  constructor() {}

  ngOnInit(): void {
    // Not initialized from outside
    if (!this.filters) {
      this.filters = {}
    }

    this.userQuery$.pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.onDestroy$)).subscribe((query) => {
      this.filters = {
        category: this.filters.category,
        query: !!query ? query : undefined,
      }
      this.filtersChange.emit(this.filters)
    })
  }

  ngOnDestroy(): void {
    this.onDestroy$.emit()
  }

  onNgModelChange(event: string): void {
    this.userQuery$.next(event)
    // DO NOT EVENT EMMIT HERE!!!
  }

  onCategoryClick(guestCategory: GuestCategory): void {
    // @ts-ignore
    UIkit.dropdown(this.categoriesDropdown.nativeElement).hide(false)

    if (this.filters.category?.id !== guestCategory.id) {
      this.filters = {
        query: this.filters.query,
        category: guestCategory,
      }

      this.filtersChange.emit(this.filters)
    }
  }

  onAllCategoriesClick(): void {
    // @ts-ignore
    UIkit.dropdown(this.categoriesDropdown.nativeElement).hide(false)

    if (this.filters.category) {
      this.filters.category = undefined
      this.filtersChange.emit(this.filters)
    }
  }
}
