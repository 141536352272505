<div class="filters-container">
  <turismo-input-search
    [text]="activeFilters.query"
    (textChange)="onNgModelChange($event)"
    i18n-placeholder
    [placeholder]="'Cerca tra i documenti'"
  ></turismo-input-search>

  <div [style.position]="'relative'">
    <button class="filters-section__btn" tabindex="0" type="button">
      <span class="filters-section__btn--label" *ngIf="!!activeCat">{{ activeCat }}</span>
      <span class="filters-section__btn--label" *ngIf="!activeCat" i18n>Tutte le tipologie</span>
      <div class="dropdown-btn--icon">
        <img [src]="'assets/images/icons/bold-chevron-down.svg'" alt="" uk-icon="ratio: 2" />
      </div>
    </button>
    <div #categoriesDropdownRef class="dropdown-menu" [attr.uk-dropdown]="dropdownOptions" [style.margin-top.px]="0">
      <ul class="uk-nav uk-dropdown-nav dropdown-menu__list">
        <li (click)="onAllCategoriesClick()" class="dropdown-menu__list--item-header" i18n>Tutte le tipologie</li>
        <ng-container *ngIf="isLoading; else guestCategoriesTemplateRef">
          <li>
            <ngx-skeleton-loader></ngx-skeleton-loader>
          </li>
        </ng-container>

        <ng-template #guestCategoriesTemplateRef>
          <ng-container *ngIf="categories?.length > 0">
            <li
              (click)="onCategoryClick(category)"
              *ngFor="let category of categories"
              class="dropdown-menu__list--item"
              tabindex="0"
            >
              {{ category }}
            </li>
          </ng-container>
        </ng-template>
      </ul>
    </div>
  </div>
</div>
