<ul class="uk-pagination uk-flex uk-flex-middle">
  <li [class.uk-disabled]="isFirstPage">
    <a
      routerLink="./"
      [queryParams]="{ page: 1 }"
      queryParamsHandling="merge"
      [replaceUrl]="true"
      class="uk-icon-link"
      aria-label="Prima pagina"
      i18n-aria-label
    >
      <i aria-hidden="true" uk-icon="icon: chevron-double-left; ratio: 1.5"></i>
    </a>
  </li>
  <li [class.uk-disabled]="isFirstPage">
    <a
      routerLink="./"
      [queryParams]="{ page: currentPage - 1 }"
      queryParamsHandling="merge"
      [replaceUrl]="true"
      class="uk-icon-link"
      aria-label="Prossima pagina"
      i18n-aria-label
    >
      <i aria-hidden="true" uk-icon="icon: chevron-left; ratio: 1.5"></i>
    </a>
  </li>

  <ng-container *ngFor="let page of currentPageGroup">
    <li class="pagination__item" routerLinkActive="pagination__item-active">
      <a
        routerLink="./"
        [queryParams]="{ page: page }"
        queryParamsHandling="merge"
        [replaceUrl]="true"
        class="uk-link-reset"
        >{{ page }}</a
      >
    </li>
  </ng-container>

  <ng-container *ngIf="hasMoreThenOneGroup">
    <li [class.uk-disabled]="!nextPageGroup">
      <a
        (click)="onLoadNextGroupPage()"
        routerLink="./"
        [queryParams]="{ page: nextPageGroup ? nextPageGroup[0] : null }"
        queryParamsHandling="merge"
        [replaceUrl]="true"
        class="uk-link-reset"
        >...</a
      >
    </li>
  </ng-container>

  <li [class.uk-disabled]="isLastPage">
    <a
      routerLink="./"
      [queryParams]="{ page: currentPage + 1 }"
      queryParamsHandling="merge"
      [replaceUrl]="true"
      class="uk-icon-link"
    >
      <i uk-icon="icon: chevron-right; ratio: 1.5"></i>
    </a>
  </li>
  <li [class.uk-disabled]="isLastPage">
    <a
      routerLink="./"
      [queryParams]="{ page: lastPage }"
      queryParamsHandling="merge"
      [replaceUrl]="true"
      class="uk-icon-link"
    >
      <i uk-icon="icon: chevron-double-right; ratio: 1.5"></i>
    </a>
  </li>
</ul>
