<div class="header__container uk-box-shadow-medium" [ngClass]="activeMenu ? 'fixed-top' : ''">
    <div class="uk-container uk-container-large d-flex w-100">
        <div class="hamburger" [ngClass]="activeMenu ? 'active' : ''" (click)="onToggleMenu()">
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
        </div>
        <div class="navbar-spacer"></div>
        <div class="logo">
            <ng-container *ngIf="!!logoUrl">
                <!-- TODO: Add href when home page will be available -->
                <a class="uk-navbar-item header__logo" [routerLink]="'home'">
                    <ngx-skeleton-loader
                                         *ngIf="isLoading && isLogoLoading"
                                         [theme]="skeletonImageTheme"
                                         class="uk-animation-fade uk-animation-reverse"></ngx-skeleton-loader>
                    <img
                         [hidden]="isLoading && isLogoLoading"
                         (load)="onLogoLoaded()"
                         (error)="onLogoLoadError()"
                         loading="lazy"
                         aria-hidden="true"
                         [src]="logoUrl"
                         alt="Logo"
                         class="uk-animation-fade" />
                </a>
            </ng-container>
        </div>

        <div class="header-nav" [ngClass]="activeMenu ? 'active' : ''">
            <navigation></navigation>
            <ng-content></ng-content>
        </div>
        <div class="d-sm-none ml-auto">
            <ng-content></ng-content>
        </div>
    </div>
</div>



<!-- <nav class="header__container uk-box-shadow-medium">
    <div class="uk-container uk-container-large">
        <div uk-navbar>
            <div class="uk-navbar-left">
                <ng-container *ngIf="!!logoUrl">
                    !-- TODO: Add href when home page will be available --
                    <a class="uk-navbar-item header__logo" [routerLink]="'home'">
                        <ngx-skeleton-loader
                                             *ngIf="isLoading && isLogoLoading"
                                             [theme]="skeletonImageTheme"
                                             class="uk-animation-fade uk-animation-reverse"></ngx-skeleton-loader>
                        <img
                             [hidden]="isLoading && isLogoLoading"
                             (load)="onLogoLoaded()"
                             (error)="onLogoLoadError()"
                             loading="lazy"
                             aria-hidden="true"
                             [src]="logoUrl"
                             alt="Logo"
                             class="uk-animation-fade" />
                    </a>
                </ng-container>

                !--        <a class="uk-navbar-item" href="#">Percorsi</a>--
            !--        <a class="uk-navbar-item" href="#">Pillole di Tipicità</a>--
                !--        <a class="uk-navbar-item" href="#">Mondo Tipicità</a>--
                !--        <a class="uk-navbar-item" href="#">Collaborazioni</a>--
                !--        <a class="uk-navbar-item" href="#">Contatti</a>--
            </div>

            <div class="uk-navbar-right">
                <div class="d-flex align-items-center">
                    <navigation></navigation>
                    <ng-content></ng-content>
                </div>

            </div>
        </div>
    </div>
</nav> -->