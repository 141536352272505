import { EventEmitter, Inject, Injectable, LOCALE_ID, OnDestroy } from '@angular/core'
import { Lang } from '../../../shared/web-api/models/lang.type'
import { TurismoWebApiService } from '../../../shared/web-api/turismo-web-api.service'
import { Observable, of } from 'rxjs'
import { Product } from './product.model'
import { catchError, map, takeUntil } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { AppState } from '@app/core/store/models/app-state.model'
import { getSelectedLanguage } from '@app/core/store/selectors/app-remote-config.selectors'

@Injectable({
  providedIn: 'root',
})
export class ProductService implements OnDestroy {
  private lang: Lang
  private readonly onDestroy$: EventEmitter<void> = new EventEmitter<void>()

  constructor(
    private webApi: TurismoWebApiService,
    @Inject(LOCALE_ID) public locale: string,
    private store$: Store<AppState>,
  ) {
    // this.lang = this.locale.split('-')[0].toLowerCase() as Lang
    this.store$
      .select(getSelectedLanguage)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((val) => {
        this.lang = val
      })
  }

  public getExhibitorProducts(
    id: string,
    pageNumber: number,
    pageSize: number,
  ): Observable<{
    products: Product[]
    count: number
  }> {
    return this.webApi
      .getProducts('poi', {
        masterId: +id,
        pageNumber,
        pageSize,
        language: this.lang,
      })
      .pipe(
        map(({ products, totalCount }) => {
          return {
            count: totalCount,
            products: products.map((product) => ({
              id: `${product.id}`,
              name: product.name,
              image: {
                description: product.image?.description,
                url: product.image?.url,
              },
              favourite: product.isFavourite,
            })),
          }
        }),
        catchError((error) => {
          // TODO: implementare gestione errori
          if (error instanceof Error) {
            console.error(error.message)
          }
          return of(null)
        }),
      )
  }

  ngOnDestroy() {
    this.onDestroy$.emit()
  }
}
