import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, Resolve } from '@angular/router'
import { forkJoin, Observable } from 'rxjs'
import { appConfig } from '../../../../brands/_current/config/app-config'
import { Exhibitor } from './exhibitor.model'
import { filter, map, take, tap } from 'rxjs/operators'
import { ProductService } from '../../product/shared/product.service'
import { ExhibitorService } from './exhibitor.service'
import { EXCELLENCE, EXCELLENCE_ID, EXHIBITOR, EXHIBITOR_ID, POI, POI_ID } from '../../../shared/route-params-and-data'
import { PoiService } from './poi.service'
import { ExcellenceService } from './excellence.service'
import { FairPoi } from '@app/features/fair/models/fair-poi.models'

@Injectable({
  providedIn: 'root',
})
export class RequireExhibitorGuard implements Resolve<Exhibitor | FairPoi> {
  constructor(
    private router: Router,
    private exhibitorService: ExhibitorService,
    private poiService: PoiService,
    private productService: ProductService,
    private excellenceService: ExcellenceService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<Exhibitor | FairPoi> | Promise<Exhibitor | FairPoi> | Exhibitor | FairPoi {
    if (route.params[EXHIBITOR_ID]) {
      return forkJoin([
        this.exhibitorService.getExhibitorDetails(route.params[EXHIBITOR_ID]),
        this.productService.getExhibitorProducts(
          route.params[EXHIBITOR_ID],
          1,
          appConfig.exhibitor.detail.productsPageSize,
        ),
      ]).pipe(
        tap(([exhibitor]) => {
          if (!exhibitor) {
            this.redirectToHomepage()
          } else {
            let url = state.url
            let id = route.params[EXHIBITOR_ID]
            let newUrl = url.substring(0, url.indexOf(id) + id.length) + `/${encodeURI(exhibitor.name)}`
            if (url != newUrl) {
              this.router.navigate([state.url.substring(0, state.url.indexOf(id) + id.length), exhibitor.name])
            }
            console.log('url..', url, 'NewUrl..1', newUrl)
          }
        }),
        filter(([exhibitor]) => !!exhibitor),
        map(([exhibitor, { products, count }]) => ({
          ...exhibitor,
          products: products,
          totalProducts: count,
          entityType: EXHIBITOR,
        })),
        take(1),
      )
    } else if (route.params[POI_ID]) {
      return forkJoin([
        this.poiService.getPoiDetails(route.params[POI_ID]),
        this.productService.getExhibitorProducts(route.params[POI_ID], 1, appConfig.exhibitor.detail.productsPageSize),
      ]).pipe(
        tap(([poi]) => {
          if (!poi) {
            this.redirectToHomepage()
          } else {
            let url = state.url
            let id = route.params[POI_ID]
            let newUrl = url.substring(0, url.indexOf(id) + id.length) + `/${encodeURI(poi.name)}`
            if (url != newUrl) {
              this.router.navigate([state.url.substring(0, state.url.indexOf(id) + id.length), poi.name])
            }
            console.log('url..', url, 'NewUrl..2', newUrl)
          }
        }),
        filter(([poi]) => !!poi),
        map(([poi, { products, count }]) => ({
          ...poi,
          products: products,
          totalProducts: count,
          entityType: POI,
        })),
        take(1),
      )
    } else if (route.params[EXCELLENCE_ID]) {
      return forkJoin([
        this.excellenceService.getExcellenceDetails(route.params[EXCELLENCE_ID]),
        this.productService.getExhibitorProducts(
          route.params[EXCELLENCE_ID],
          1,
          appConfig.exhibitor.detail.productsPageSize,
        ),
      ]).pipe(
        tap(([excellence]) => {
          if (!excellence) {
            this.redirectToHomepage()
          } else {
            let url = state.url
            let id = route.params[EXCELLENCE_ID]
            let newUrl = url.substring(0, url.indexOf(id) + id.length) + `/${encodeURI(excellence.name)}`
            if (url != newUrl) {
              this.router.navigate([state.url.substring(0, state.url.indexOf(id) + id.length), excellence.name])
            }
            console.log('url..', url, 'NewUrl..3', newUrl)
          }
        }),
        filter(([excellence]) => !!excellence),
        map(([excellence, { products, count }]) => ({
          ...excellence,
          products: products,
          totalProducts: count,
          entityType: EXCELLENCE,
        })),
        take(1),
      )
    }

    // this.redirectToFair()
  }

  private redirectToHomepage() {
    this.router.navigate(['/', appConfig.homepage.paths._root])
  }
}
