import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'turismo-input-search',
  templateUrl: 'input-search.component.html',
  styleUrls: ['input-search.component.scss'],
})
export class InputSearchComponent implements OnInit {
  @Input()
  placeholder: string = 'Cerca luogo'

  @Input()
  text: string

  @Input()
  active?: boolean

  @Input()
  disabled: boolean = false

  @Output()
  textChange = new EventEmitter<string>()

  public focused: boolean

  constructor() {}

  ngOnInit(): void {}

  onCancelSearch() {
    this.text = null
    this.textChange.emit(this.text)
  }

  onTextChange() {
    this.textChange.emit(this.text)
  }
}
