import { Component, Input, OnInit } from '@angular/core'
import { ShopProduct } from '@features/exhibitor/models/shop-product.model'

@Component({
  selector: 'turismo-exhibitor-shop-product',
  templateUrl: './exhibitor-shop-product.component.html',
  styleUrls: [
    './exhibitor-shop-product.component.scss',
    './exhibitor-shop-product-medium.component.scss',
    './exhibitor-shop-product-small.component.scss',
  ],
})
export class ExhibitorShopProductComponent implements OnInit {
  @Input()
  product: ShopProduct

  constructor() {}

  ngOnInit(): void {}
}
