<div class="uk-card uk-card-default uk-card-body uk-padding-remove" [class.non-clicable-card]="nonClickable">
  <div
    [ngStyle]="{
      'background-image':
        'linear-gradient(' +
        (isDisabled ? 'black, black' : '180deg, rgba(17, 17, 17, 0) 28.54%, rgba(17, 17, 17, 0.66) 66.67%') +
        '), url(' +
        backgroundImage +
        ')',
      'background-blend-mode': isDisabled ? 'saturation' : 'normal'
    }"
    class="small-card__background uk-background-cover uk-background-center-center uk-background-norepeat"
  >
    <div class="small-card__header">
      <span *ngIf="!!distance && distance != null">{{ (distance / 1000).toFixed(1) }}Km</span>
    </div>
    <button
      (click)="onFavouriteClick()"
      *ngIf="isFavouriteEnabled"
      [class.small-card__favourite-active]="favourite"
      class="uk-button uk-padding-small uk-padding-remove-top uk-padding-remove-bottom uk-card-badge small-card__favourite"
      type="button"
    >
      <span uk-icon="icon: heart"></span>
    </button>
    <div class="small-card__text-container">
      <ng-content select="turismo-fair-exhibitor-digital-shop"></ng-content>
      <div *ngIf="type" class="small-card__type">{{ type }}</div>
      <div *ngIf="startDate && endDate" class="small-card__date">
        <p *turismoEventDates="{ startDate: startDate, endDate: endDate }; label as date" [innerHTML]="date"></p>
      </div>
      <h3 class="uk-card-title">
        {{ title }}
      </h3>
      <!-- Subtitle -->
      <!-- <span *ngIf="subtitle" class="small-card__subtitle" [innerHtml]="subtitle | sanitizeContent"></span> -->
      <ng-content></ng-content>
    </div>
  </div>
</div>
