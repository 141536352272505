<div class="shop-product">
  <img class="shop-product__image" [src]="product.imageUrl" />

  <div class="shop-product__details">
    <div>
      <span class="shop-product__exhibitor-name">{{ product.exhibitorName }}</span>
      <h6 class="shop-product__name">{{ product.name }}</h6>
      <p class="shop-product__description">{{ product.description }}</p>
    </div>

    <span class="shop-product__price">{{ product.price | currency: 'EUR' }}</span>
  </div>
</div>
