import { ViewportScroller } from '@angular/common'
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
} from '@angular/core'
import { Store } from '@ngrx/store'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import * as _ from 'lodash'
import { AppState } from '../../../../core/store/models/app-state.model'
import { Exhibitor } from '../../shared/exhibitor.model'
import { ActivatedRoute } from '@angular/router'
import { map, shareReplay, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators'
import { appConfig } from '../../../../../brands/_current/config/app-config'
import { ProductService } from '../../../product/shared/product.service'
import { Product } from '../../../product/shared/product.model'
import { EXCELLENCE, EXHIBITOR, POI, POI_ID } from '../../../../shared/route-params-and-data'
import { SocialService } from '@shared/social-share/social.service'
import { AppRemoteConfigSelectors } from '@app/core/store/selectors'
import { TurismoWebApiService } from '@app/shared/web-api/turismo-web-api.service'
import { getSelectedLanguage, selectBreadcrumbs } from '@app/core/store/selectors/app-remote-config.selectors'
import { Breadcrumb } from '@app/core/components/breadcrumb/breadcrumb.model'
import { setBreadcrumbs } from '@app/core/store/actions/app-remote-config-effect.actions'
import { Breakpoints } from '@app/core/models/breakpoints.model'
import { formatHtml } from '@app/shared/utils'

@Component({
  selector: 'turismo-exhibitor-detail-page',
  templateUrl: './exhibitor-detail-page.component.html',
  styleUrls: [
    './exhibitor-detail-page.component.scss',
    './exhibitor-details-page-medium.component.scss',
    './exhibitor-details-page-small.component.scss',
  ],
})
export class ExhibitorDetailPageComponent implements OnInit, AfterViewChecked, OnDestroy, AfterViewInit {
  @ViewChild('paragraphContentRef')
  private readonly paragraphContentRef!: ElementRef

  @ViewChild('descProduct')
  private readonly descProduct!: ElementRef

  @ViewChild('descWrapper')
  private readonly descWrapper!: ElementRef

  @ViewChild('desc')
  private readonly descRef!: ElementRef

  entityType: string
  enabledSections = appConfig.exhibitor.enabledSections

  public currentProductsPage = 1
  public currentRelatedPage = 1
  relatedPageSize: number = 6
  public readonly productsPageSize = appConfig.exhibitor.detail.productsPageSize
  public exhibitor$: Observable<Exhibitor>
  public partnersImageUrl$: Observable<string>
  public products$: BehaviorSubject<Product[]> = new BehaviorSubject<Product[]>([])
  public fairPath$: Observable<string[]>
  public shareWithTweeter: string
  public shareWithFacebook: string
  public shareWithEmail: string

  public totalProducts = 0

  displayOverflowText: boolean = false
  displayReadMoreButton: boolean = false
  descCoords: { x: number; y: number }
  coords: { x: number; y: number }
  private oldContentTextHeight: number = null
  private readonly onDestroy$ = new EventEmitter<void>()

  poiId = this.route.snapshot.paramMap.get('poiId')
  poiName: string
  relatedPois = new Subject<any>()
  lang: any = ''
  totalRelatedResult: Observable<number>
  currentBreadcrumbs: Breadcrumb[]
  brandName: string
  bimLabel: string = $localize`In dettaglio`
  tipicitaLabel: string = $localize`Scopri chi siamo`

  constructor(
    private route: ActivatedRoute,
    private productService: ProductService,
    private viewportScroller: ViewportScroller,
    private store$: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private socialService: SocialService,
    private api: TurismoWebApiService,
    private _vps: ViewportScroller,
  ) {
    this.store$
      .select(getSelectedLanguage)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((val) => {
        this.lang = val
      })
    this.store$
      .select(selectBreadcrumbs)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((val) => {
        this.currentBreadcrumbs = _.cloneDeep(val)
      })

    this.requestTantoAltroList()
  }

  ngOnInit(): void {
    this.brandName = appConfig.core.brandName
    this.viewportScroller.scrollToPosition([0, 0])

    this.exhibitor$ = this.route.data.pipe(
      map((data) => (data[EXHIBITOR] ? data[EXHIBITOR] : data[POI] ? data[POI] : data[EXCELLENCE])),
      // SEO
      tap<Exhibitor>(({ name, abstract, description, gallery }) => {
        this.poiName = name
        let des = abstract ? abstract : formatHtml(description)
        this.setBreadcrumbs(this.poiName)
        this.socialService.addSocialSeo(name, des, gallery?.foreground.url)
        this.shareWithTweeter = SocialService.getTwitterShare(`${name} - ${abstract ?? ''}`)
        this.shareWithFacebook = SocialService.getFacebookShare()
        this.shareWithEmail = SocialService.getEmailShare(`${name} - ${abstract}`)
      }),
      tap((exhibitor: Exhibitor) => {
        console.log('Exhibitor data:', exhibitor)
        this.entityType = exhibitor.entityType
        this.totalProducts = exhibitor?.totalProducts
        this.products$.next(exhibitor?.products || [])
      }),
      shareReplay(),
    )

    this.fairPath$ = this.exhibitor$.pipe(
      takeUntil(this.onDestroy$),
      map((exhibitor) => ['/', appConfig.fair.paths._root, exhibitor.fair.id]),
    )

    this.partnersImageUrl$ = this.store$
      .select(AppRemoteConfigSelectors.selectAppRemoteConfigPartnersImageUrl)
      .pipe(takeUntil(this.onDestroy$))
  }

  requestTantoAltroList() {
    this.api
      .getMoreEvents({
        masterId: Number(this.poiId),
        language: this.lang,
      })
      .subscribe((data) => {
        this.relatedPois.next(data)
      })
  }

  ngAfterViewChecked() {
    const pContentEl = this.paragraphContentRef?.nativeElement as HTMLElement

    if (pContentEl && pContentEl.scrollHeight > 0 && this.oldContentTextHeight !== pContentEl.scrollHeight) {
      this.oldContentTextHeight = pContentEl.scrollHeight
      this.displayReadMoreButton = pContentEl.scrollHeight > 120
      this.cdr.detectChanges()
    }
  }

  ngAfterViewInit() {
    const { x, y } = this.paragraphContentRef?.nativeElement.getBoundingClientRect()
    this.descCoords = { x, y }
  }

  public onClickLoadMoreProducts(): void {
    this.currentProductsPage++
    this.exhibitor$
      .pipe(
        take(1),
        takeUntil(this.onDestroy$),
        switchMap((exhibitor) =>
          this.productService.getExhibitorProducts(exhibitor?.id, this.currentProductsPage, this.productsPageSize),
        ),
        withLatestFrom(this.products$),
        tap(([{ products, count }, prevProducts]) => {
          this.totalProducts = count
          this.products$.next([...prevProducts, ...products])
        }),
      )
      .subscribe()
  }

  public onClickReduceProducts() {
    this.currentProductsPage--
    this.exhibitor$
      .pipe(
        take(1),
        takeUntil(this.onDestroy$),
        switchMap((exhibitor) =>
          this.productService.getExhibitorProducts(exhibitor?.id, this.currentProductsPage, this.productsPageSize),
        ),
        withLatestFrom(this.products$),
        tap(([{ products, count }, prevProducts]) => {
          this.products$.next([...products])
        }),
      )
      .subscribe()
    this.descProduct.nativeElement.scrollIntoView()
  }

  public onClickLoadMoreRelated() {
    this.relatedPageSize += this.relatedPageSize
  }

  public onClickReduceRelated() {
    this.relatedPageSize = 6
    this.descWrapper.nativeElement.scrollIntoView()
  }

  onReadMore(): void {
    this.displayOverflowText = !this.displayOverflowText
    if (!this.displayOverflowText && window.innerWidth <= Breakpoints.BREAKPOINT_SM) {
      window.scroll(this.descCoords.x, this.descCoords.y - 120)
    }
    if (!this.displayOverflowText && window.innerWidth >= Breakpoints.BREAKPOINT_SM) {
      window.scroll(this.descCoords.x, this.descCoords.y - 120)
    }

    if (!this.displayOverflowText && window.innerWidth >= Breakpoints.BREAKPOINT_MD) {
      window.scroll(this.descCoords.x, this.descCoords.y - 120)
    }

    if (!this.displayOverflowText && window.innerWidth >= Breakpoints.BREAKPOINT_LG) {
      window.scroll(this.descCoords.x, this.descCoords.y - 120)
    }
  }

  ngOnDestroy() {
    this.onDestroy$.emit()
  }

  setBreadcrumbs(entityName: string) {
    if (this.currentBreadcrumbs && this.currentBreadcrumbs.length > 0) {
      this.currentBreadcrumbs[3] = {
        label: entityName,
        url: '/home',
        isClickable: false,
      }
      this.store$.dispatch(setBreadcrumbs({ breadcrumbs: this.currentBreadcrumbs }))
    }
  }

  showProduct(e) {
    console.log('Product:', e)
  }
}
