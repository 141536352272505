import { Component, Input } from '@angular/core'

@Component({
  selector: 'turismo-contact-info-details',
  template: `
    <ul class="contact-info-details">
      <!-- <li *ngIf="!!company || isLoading" class="contact-info-details__item">
        <ng-container *ngIf="!isLoading; else loadingTemplateRef">
          <a [href]="'name:' + company"> <img src="assets/images/icons/building.svg" />{{ company }}</a>
        </ng-container>
      </li> -->

      <li *ngIf="!!phoneNumber || isLoading" class="contact-info-details__item">
        <ng-container *ngIf="!isLoading; else loadingTemplateRef">
          <a [href]="'tel:' + phoneNumber"><img src="assets/images/icons/phone.svg" />{{ phoneNumber }}</a>
        </ng-container>
      </li>
      <li *ngIf="!!email || isLoading" class="contact-info-details__item">
        <ng-container *ngIf="!isLoading; else loadingTemplateRef">
          <a [href]="'mailto:' + email"><img src="assets/images/icons/email.svg" />{{ email }}</a>
        </ng-container>
      </li>
      <li *ngIf="!!websiteUrl || isLoading" class="contact-info-details__item">
        <ng-container *ngIf="!isLoading; else loadingTemplateRef">
          <a [href]="websiteUrl" target="_blank"><img src="assets/images/icons/website.svg" />{{ websiteUrl }}</a>
        </ng-container>
      </li>
      <li *ngIf="!!facebookUrl || isLoading" class="contact-info-details__item">
        <ng-container *ngIf="!isLoading; else loadingTemplateRef">
          <a [href]="facebookUrl" target="_blank"><img class="revert-fill" [src]="facebookIcon" />{{ facebookUrl }}</a>
        </ng-container>
      </li>
    </ul>

    <ng-template #loadingTemplateRef>
      <div style="display: flex; align-items: center">
        <ngx-skeleton-loader appearance="circle" style="margin-right: 14px"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{ 'margin-bottom': 'unset' }" style="width: 100%"></ngx-skeleton-loader>
      </div>
    </ng-template>
  `,
  styleUrls: [
    './contact-info-details.component.scss',
    './contact-info-details-medium.component.scss',
    './contact-info-details-small.component.scss',
  ],
})
export class ContactInfoDetailsComponent {
  facebookIcon = 'assets/images/icons/facebook-icon.svg'

  @Input()
  company: string

  @Input()
  phoneNumber?: string

  @Input()
  email?: string

  @Input()
  websiteUrl?: string

  @Input()
  facebookUrl?: string

  @Input()
  isLoading: boolean = false
}
