import { DatePipe } from '@angular/common'
import { Directive, Input, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core'
import { AppState } from '@app/core/store/models/app-state.model'
import { getSelectedLanguage } from '@app/core/store/selectors/app-remote-config.selectors'
import { Store } from '@ngrx/store'
import * as moment from 'moment'
import { Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'

export interface TurismoEventDatesContext {
  label: string
}

@Directive({
  selector: '[turismoEventDates]',
})
export class EventDatesDirective {
  private context: TurismoEventDatesContext | null = null
  language: any
  currentLang$: Observable<string>

  @Input()
  set turismoEventDates(value: { startDate: string; endDate: string } | undefined) {
    //moment.locale('it')

    this.language === 'it' ? moment.locale('it') : moment.locale('en')

    if (!!value) {
      if (!!value.startDate && !!value.endDate) {
        this.viewContainer.clear()

        let startDate = moment(value.startDate).format('YYYY-MM-DD') || null
        let endDate = moment(value.endDate).format('YYYY-MM-DD') || null

        let startDay = moment(startDate).format('DD')
        let startMonth = moment(startDate).format('MM')
        let longStartMonth = moment(startDate).format('MMMM')
        let endDay = moment(endDate).format('DD')
        let endMonth = moment(endDate).format('MM')
        let longEndMonth = moment(endDate).format('MMMM')

        if (startMonth == endMonth) {
          if (startDay == endDay) {
            this.context = {
              label: `<span>${startDay + ' ' + longStartMonth}</span>`,
            }
          } else {
            this.context = {
              label: `<span>${startDay + ' - ' + endDay + ' ' + longStartMonth}</span>`,
            }
          }
        } else {
          this.context = {
            label: `<span>${startDay + ' ' + longStartMonth + ' - ' + endDay + ' ' + longEndMonth}</span>`,
          }
        }

        this.viewContainer.createEmbeddedView(this.templateRef, this.context)
      }
    }
  }

  constructor(
    private templateRef: TemplateRef<TurismoEventDatesContext>,
    private viewContainer: ViewContainerRef,
    private store$: Store<AppState>,
    private renderer: Renderer2,
    private datePipe: DatePipe,
  ) {
    this.store$
      .select(getSelectedLanguage)
      .pipe(
        take(1),
        map((lang) => {
          this.language = lang
        }),
      )
      .subscribe()
  }
}
