import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators'

@Component({
  selector: 'turismo-downloads-filters',
  templateUrl: './downloads-filters.component.html',
  styleUrls: ['./downloads-filters.component.scss'],
})
export class DownloadsFiltersComponent implements OnInit {
  @Input()
  query: string

  @Input()
  categories: Array<string>

  @Input()
  activeFilters: { query: string; category: string }

  @Output()
  filtersChange: EventEmitter<{ query: string; category: string }> = new EventEmitter<{
    query: string
    category: string
  }>()

  private readonly onDestroy$: EventEmitter<void> = new EventEmitter<void>()
  private readonly userQuery$ = new Subject<string>()
  readonly dropdownOptions = 'delay-hide: 0; pos: bottom-center; mode: click'

  @ViewChild('categoriesDropdownRef')
  private readonly categoriesDropdown!: ElementRef

  activeCat: string
  isLoading = false

  constructor() {}

  ngOnInit(): void {
    if (this.activeFilters && this.activeFilters.category) {
      this.activeCat = this.activeFilters.category
    }

    this.userQuery$.pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.onDestroy$)).subscribe((query) => {
      this.searchInputChanged(query)
    })
  }

  onNgModelChange(e) {
    this.userQuery$.next(e)
  }

  searchInputChanged(query: string) {
    this.query = query
    this.filtersChange.emit({ query, category: this.activeCat })
  }

  onCategoryClick(category: string) {
    // @ts-ignore
    UIkit.dropdown(this.categoriesDropdown.nativeElement).hide(false)

    if (this.activeCat !== category) {
      this.activeCat = category
      console.log('Active cat:', this.activeCat)
      this.filtersChange.emit({ query: this.query, category: this.activeCat })
    }
  }

  onAllCategoriesClick() {
    // @ts-ignore
    UIkit.dropdown(this.categoriesDropdown.nativeElement).hide(false)

    if (this.activeCat) {
      this.activeCat = undefined
      this.filtersChange.emit({ query: this.query, category: undefined })
    }
  }
}
