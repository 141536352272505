import { EventEmitter, Inject, Injectable, LOCALE_ID, OnDestroy } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Exhibitor } from './exhibitor.model'
import { catchError, map } from 'rxjs/operators'
import { appConfig } from '../../../../brands/_current/config/app-config'
import { TurismoWebApiService } from '../../../shared/web-api/turismo-web-api.service'
import { Lang } from '../../../shared/web-api/models/lang.type'
import { Store } from '@ngrx/store'
import { AppState } from '@app/core/store/models/app-state.model'
import { getSelectedLanguage } from '@app/core/store/selectors/app-remote-config.selectors'

@Injectable({
  providedIn: 'root',
})
export class ExcellenceService implements OnDestroy {
  private lang: Lang
  private readonly onDestroy$: EventEmitter<void> = new EventEmitter<void>()

  constructor(
    private webApi: TurismoWebApiService,
    @Inject(LOCALE_ID) public locale: string,
    private store$: Store<AppState>,
  ) {
    // this.lang = this.locale.split('-')[0].toLowerCase() as Lang
    this.store$
      .select(getSelectedLanguage)
      .pipe()
      .subscribe((val) => {
        this.lang = val
      })
  }

  //Exhibitor mapping
  public getExcellenceDetails(id: string): Observable<Exhibitor> {
    return this.webApi.getExcellencesDetails(+id, this.lang).pipe(
      map((excellence) => ({
        id: `${excellence.id}`,
        contactInfo: {
          facebook: excellence.contacts?.socialSiteUrl,
          mail: excellence.contacts?.email,
          site: excellence.contacts?.siteUrl,
          telephone: excellence.contacts?.phone,
        },
        description: excellence.descriptionHtml,
        name: excellence.name,
        fair: {
          id: excellence.fairInfo?.id,
          name: excellence.fairInfo?.name,
          zone: !!excellence.fairInfo?.zone
            ? `${excellence.fairInfo?.zone?.area} (${excellence.fairInfo?.zone?.pavilion})`
            : undefined,
        },
        travelPlannerUrl: !excellence.fairInfo?.address
          ? null
          : appConfig.core.travelPlannerUrl
              .replace(
                '{{desc}}',
                `${excellence.fairInfo?.address?.street}${
                  excellence.fairInfo?.address?.streetNumber ? `, ${excellence.fairInfo?.address?.streetNumber}` : ''
                } ${excellence.fairInfo?.address?.city}`,
              )
              .replace('{{lat}}', `${excellence.fairInfo?.address?.lat}`)
              .replace('{{lng}}', `${excellence.fairInfo?.address?.lon}`),
        mapUrl: !excellence.fairInfo?.mapLink
          ? null
          : `${excellence.fairInfo?.mapLink}?marker=${excellence.fairInfo?.zone?.marker}&desc=${excellence.fairInfo?.zone?.area}`,
        shortDescription: excellence.subCategory?.subCategoryName,
        images: [
          {
            url: excellence.gallery?.foreground?.url,
            description: excellence.gallery?.foreground?.description,
          },
          ...excellence.gallery?.images?.map((image) => ({
            url: image.url,
            description: image.description,
          })),
        ],
        reviews: [],
        products: [],
        partners: [],
        gallery: excellence.gallery,
        videoGallery: excellence.videoGallery,
        services: excellence.additionalInfos
          // Exclude icons that dont have the url
          .filter((addInfos) => !!addInfos.iconUrl)
          .map((addInfos) => ({
            icon: addInfos.iconUrl,
            description: addInfos.name,
            id: addInfos.fieldId,
          })),
        abstract: excellence.abstractHtml,
        shopUrl: excellence.onlineShop ?? undefined,
        shopProducts:
          excellence.commercialProducts?.totalCount > 0
            ? excellence.commercialProducts.products.map(({ price, description, image, name, id }) => ({
                excellenceName: excellence.name,
                id,
                price,
                description,
                name,
                imageUrl: image,
              }))
            : [],
        archiveDescription: excellence.archiveDescriptionHtml
          ? excellence.archiveDescriptionHtml
          : excellence.archiveDescription,
        downloads: excellence.downloads,
        fairs: excellence.fairs,
      })),
      catchError((error) => {
        // TODO: implementare gestione errori
        if (error instanceof Error) {
          console.error(error.message)
        }
        return of(null)
      }),
    )
  }

  ngOnDestroy() {
    this.onDestroy$.emit()
  }
}
