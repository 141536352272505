import { TurismoWebApiService } from '@shared/web-api/turismo-web-api.service'
import { defaultAppConfig } from './app-default-config'

export function customAppInit(): () => Promise<void> {
  return () =>
    new Promise<void>((resolve, reject) => {
      if (defaultAppConfig.socialConfig.facebook?.appId) {
        window['fbAsyncInit'] = function () {
          FB.init({
            appId: defaultAppConfig.socialConfig.facebook?.appId,
            cookie: true,
            xfbml: false,
            version: 'v10.0',
          })

          resolve()
        }
      }

      // load facebook sdk script
      ;(function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) {
          return
        }
        js = d.createElement(s)
        js.id = id
        js.src = 'https://connect.facebook.net/it_IT/sdk.js' // TODO: Load in language sdk
        fjs.parentNode.insertBefore(js, fjs)
      })(document, 'script', 'facebook-jssdk')

      resolve()
    })
}
