import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core'
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router'
import { Breadcrumb } from './breadcrumb.model'
import { distinctUntilChanged, filter, tap } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { AppState } from '@app/core/store/models/app-state.model'
import { getSelectedLanguage, selectBreadcrumbs } from '@app/core/store/selectors/app-remote-config.selectors'

@Component({
  selector: 'turismo-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs: Breadcrumb[]
  dynamicBreadcrumbs: Breadcrumb[]
  lang: string

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.breadcrumbs.pop()
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store$: Store<AppState>,
    private cdf: ChangeDetectorRef,
  ) {
    this.breadcrumbs = [...this.buildBreadCrumb(this.route.root)]
    this.store$.select(getSelectedLanguage).subscribe((val) => {
      this.lang = val
    })

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged(),
      )
      .subscribe(() => {
        this.store$.select(selectBreadcrumbs).subscribe((val) => {
          this.breadcrumbs = val ? [...val] : [...this.buildBreadCrumb(this.route.root)]
        })
      })
  }

  ngOnInit(): void {}

  buildBreadCrumb(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: Breadcrumb[] = [
      {
        url: '/home',
        label: 'Home',
        isClickable: true,
      },
    ],
  ): Breadcrumb[] {
    let label =
      route.routeConfig && route.routeConfig.data
        ? this.lang == 'it'
          ? route.routeConfig.data.breadcrumb
          : route.routeConfig.data.breadcrumbEn
        : ''
    let path = route.snapshot && route.snapshot.routeConfig ? route.snapshot.routeConfig.path : ''
    let canClickBreadcrumb =
      route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumbIsClickable ?? true : true

    const isDynamicRoute = path.startsWith(':')
    if (isDynamicRoute) {
      const splitPath = path.split('/')
      const paramName = splitPath[0].split(':')[1]
      path = path.replace(splitPath[0], route.snapshot.params[paramName])
      if (paramName.toLocaleLowerCase().includes('name')) {
        label = route.snapshot.params[paramName]
      }
    }

    // In the routeConfig the complete path is not available,
    // so we rebuild it each time
    const nextUrl = path ? `${url}/${path}` : url

    const breadcrumb: Breadcrumb = {
      label: label,
      url: nextUrl,
      isClickable: canClickBreadcrumb && !!route.firstChild,
    }
    // Only adding route with non-empty label
    const newBreadcrumbs = breadcrumb.label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs]

    if (route.firstChild) {
      //If we are not on our current path yet,
      //there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs)
    }
    if (newBreadcrumbs.length == 1) {
      newBreadcrumbs[0].isClickable = false
    }
    return newBreadcrumbs
  }

  breadcrumbClicked() {
    //this.store$.dispatch(removeBreadcrumbs())
  }
}
