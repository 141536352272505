import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core'
import { RelatedFair } from '../../models/related-fairs.model'
// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper/core'

// install Swiper modules
SwiperCore.use([Pagination, Navigation, Autoplay])

@Component({
  selector: 'turismo-related-fairs-slider',
  templateUrl: './related-fairs-slider.component.html',
  styleUrls: ['./related-fairs-slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RelatedFairsSliderComponent implements OnInit {
  @Input()
  fairs: Array<RelatedFair>

  constructor() {}

  ngOnInit(): void {}
}
