<div #galleryElRef class="exhibitor-gallery text-center" [ngClass]="responsive ? 'responsive-gallery' : ''">
  <ng-container *ngFor="let image of items; index as i">
    <img [hidden]="currentItemIndex !== i" class="uk-animation-fade" [src]="image.url" />
  </ng-container>
  <div class="d-flex">
    <ng-container *ngFor="let image of items; index as i">
      <img *ngIf="currentItemIndex !== i" [src]="image.url" />
    </ng-container>
  </div>
</div>

<ol class="exhibitor-gallery__dot-nav" [class.d-none]="items.length == 1">
  <li
    *ngFor="let image of items; index as i"
    [class.exhibitor-gallery__dot-nav-item-active]="this.currentItemIndex === i"
    class="exhibitor-gallery__dot-nav-item"
    (click)="onDotNavClick(i)"
  ></li>
</ol>
